.loader-rev {
  position: relative;
  width: 910px;
  height: 435px;
  margin: 36px auto 0;
  padding: 55px 41px 0 41px;
  background: @white;
  border: 1px solid #d2d2d2;
  .box-sizing(border-box);

  .loader-content {
    position: relative;
    margin: 0 auto;
  }

  .spinner-wrapper {
    margin: 0 auto;
  }

  .loader-title {
    font-size: 31px;
    .mac-smooth();
    text-align: center;
    margin: 30px 0 35px 0;
  }

  .loader-gallery{
    margin: 0 0 30px 0;

    img{
      margin-left: 5px;

      &:first-child{
        margin-left: 0;
      }
    }
  }

  .loader-promo{
    color: @purple;
    text-align: center;
    font-size: 16px;
  }
}

.sidebar-map-wrapper {
  position: relative;
  width: 210px;
  height: 73px;
  margin: 8px 0;
  border: 1px solid #cdcdcd;

  a {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px 5px;
    .bold();
    color: @blue;
    background: @white;
    background: rgba(255,255,255,0.92);
  }
}

.modal-destinations{
  &.hotels{
    .content{
      .destinations{
        margin-bottom: 9px;
        overflow: visible;
        border-top: none;
        background: none;
      }

      .destination-list-item{
        margin-bottom: 9px;
        box-shadow: 0 0 4px rgba(0,0,0,0.2);
        -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);
        border: none;

        .destination-image{
          width: 179px;

          .photos-icon{
            left: 50%;
            top: 57px;
            margin-left: -50px;
            width: 102px;

            &:before{
              left: -39px;
              top: -57px;
              width: 179px;
              height: 144px;
            }

            .btn{
              position: relative;
              white-space: nowrap;
              font-size: 12px;
              .bold();
              color: @gray;
              padding: 5px 0;
              width: 100px;
              text-shadow: 0 1px rgba(255,255,255,0.8);
              .buttonBackground(lighten(@grayLighter, 15%), darken(@grayLighter, 5%));
              border: 1px solid darken(@grayLighter, 70%);

              i{
                position: relative;
                top: 3px;
              }

              &:hover,
              &:active{
                border: 1px solid darken(@grayLighter, 70%);
              }
            }
          }

          .thumb-wrapper{
            display: block;
            overflow: hidden;
            width: 179px;
            height: 144px;
          }

          .thumb-square{
            img{
              margin-top: -23px;
            }
          }
        }

        .destination-info{
          margin-top: 14px;
          width: 378px;
          height: auto;

          .title{
            margin: 0 0 4px 0;
            line-height: 19px;
            max-width: 378px;
            .text-overflow();

            a{
              font-size: 16px;
              .bold();
            }
          }

          .rating-wrapper {
            .clearfix();
            padding-top: 7px;

          .rating-common,
          .advisor-rating-wrap {
            float: left;
          }

          .rating-common {
            + .advisor-rating-wrap {
              border-left: 1px solid #ccc;
              margin-left: 5px;
              padding-left: 10px;
            }
          }
        }
        }
      }
    }
  }
}

.modal-destinations{
  &.hotels{
    .content{
      .pagination{
        margin: 0;
        padding: 0 7px 0 0;
        line-height: 45px;
        border-top: 1px solid @grayBorder;
        box-shadow: none;
      }
    }
  }
}

.modal-destinations{
  &.hotels-details{
    .modal-subheader{
      .clearfix();
      margin-bottom: 23px;
      padding: 16px 15px 18px 15px;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid #e4e4e4;

      .modal-subheader-rightbox{
        float: right;
        text-align: right;
        width: 277px;

        .price{
          display: block;
          line-height: 38px;
          font-size: 24px;
          margin-bottom: -4px;

          strong{
            font-size: 29px;
            margin-left: 4px;
          }
        }

        .descr{
          display: block;
          color: @grayLight;
          font-size: 11px;
          margin-bottom: 7px;
        }

        .btn{
          min-width: 108px;
        }
      }

      .modal-subheader-leftbox{
        h1{
          font-size: 29px;
          .bold();
          margin-bottom: -4px;
        }

        .hotel-descr{
          display: block;
          margin-bottom: 18px;
        }

        .rating-common{
          float: left;
        }

        .advisor-rating-wrap {
          float: left;
          border-left: 1px solid #CCCCCC;
          margin-left: 5px;
          padding-left: 10px;
        }
      }

      .info-block {
        float: left;
      }
    }
  }
}

.modal-destinations{
  &.hotels-details{
    .modal-content{
      margin: 0;
      padding: 0 15px;

      .content{
        float: none;
        width: 100%;
        margin: 0;
        box-shadow: none;
        background: none;

        &:before {
          display: none;
        }
      }
    }
  }
}

.modal-destinations{
  &.hotels{
    .sidebar{
      .hotels-list-banner{
        margin: 35px 0 5px 0;
      }
    }
  }
}

.modal-destinations{
  &.hotels{
    &.on-page{
      .content {
        padding: 0;
        background: none;
        box-shadow: none;
      }
    }

    .content{
      .head-block{
        margin: 0 0 8px 0;
        padding: 3px 8px 3px 14px;
        line-height: 33px;
        background: @white;
        border: 1px solid #d2d2d2;

        .showall-link{
          position: relative;
          top: 1px;
          .bold();
        }

        .add-link-secondary{
          position: relative;
          top: 1px;
        }

        .sort-selector{
          .title{
            .bold();
          }
        }
      }

      .map-head-block {
        position: relative;
        margin: 0 0 8px 0;
        padding: 3px 14px 3px 12px;
        line-height: 33px;
        background: @white;
        border: 1px solid #d2d2d2;

        .map-hotels-count {
          float: right;
          .bold();
          font-size: 15px;
        }

        .btn {
          margin: -2px 8px 0 0;
          padding: 3px 12px 4px;
        }
      }
    }
  }
}

.hotels-counter {
  font-size: 15px;
  margin-right: 10px;

  em {
    font-size: 14px;
  }

  .lnk-show {
    margin-left: 5px;
    font-size: 12px;
  }
}

.hotel-list {
  .hotel-list-item {
    position: relative;
    .clearfix();
    margin: 0 0 9px 0;
    padding: 12px;
    background: @white;
    border: 1px solid @grayBorder;

    &:hover {
      box-shadow: 0 0 4px rgba(0,0,0,0.2);
    }

    .highlight-red {
      color: #a10005;
    }

    .hotel-photo {
      display: block;
      position: relative;
      width: 210px;
      height: 144px;
      border: 1px solid #e6e6e6;
      border-bottom: none;
      background-size: cover !important;

      &:hover {
        .control-left,
        .control-right {
          display: block;
        }
      }
    }

    .control-left,
    .control-right {
      display: none;
      position: absolute;
      top: 61px;
      left: 8px;
      width: 20px;
      height: 20px;
      background: @grayDark;
      background: rgba(0,0,0,0.5);
      border: 2px solid @white;
      border-radius: 10em;
      .opacity(80);
      cursor: pointer;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 6px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid @white;
      }

      &:hover {
        .opacity(100);
      }
    }

    .control-right {
      left: auto;
      right: 8px;

      &:after {
        left: 8px;
        border-top: 5px solid transparent;
        border-left: 5px solid @white;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }

    .left-col {
      float: left;
      width: 213px;
    }

    .right-col {
      float: right;
      width: 495px;
      margin: 1px 0 0 0;
    }

    .title {
      font-size: 16px;
      .bold();
    }

    .hotel-address {
      display: block;
      margin-top: 4px;
      font-size: 11px;
      line-height: 1.4;
      color: @gray;
      font-style: italic;
    }

    .rating-wrapper {
      margin-top: 3px;

      .item {
        float: left;
        padding-left: 6px;
        border-left: 1px solid #E6E6E6;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }
      }

      .rating-advisor {
        position: relative;
        top: 2px;
      }
    }

    .hotel-info {
      float: left;
      width: 356px;
      margin: 0 0 6px 0;
    }

    .btn-primary {
      min-width: 85px;
      .box-sizing(border-box);
      margin: 0 0 6px 0;
      padding: 4px 5px;
    }

    .controls {
      float: right;
      width: 138px;
      margin: -4px 0 34px 0;
      text-align: right;
    }

    .control-badge {
      margin: 1px -13px 0 0;
    }

    .badges {
      margin: 2px 0 0 10px;

      span {
        margin-bottom: 5px;
      }
    }

    .table-room-wrapper {
      position: relative;
      float: left;
      width: 100%;

      .hotel-description {
        margin-top: 5px;
        font-size: 12px;
        line-height: 1.5;
        color: @gray;

        .lnk-more {
          font-size: 11px;
        }
      }

      .lnk-more {
        float: left;
        cursor: pointer;
        font-size: 11px;

        i {
          margin-left: 2px;
        }
      }
    }

    .badge-nightcount {
      position: absolute;
      top: -24px;
      right: 0;
      height: 24px;
      padding: 0 4px 0;
      font-size: 12px;
      line-height: 24px;
      background: #ACFFA1;
    }

    .table-room-types {
      width: 100%;

      td {
        padding: 4px 0;
        font-size: 12px;
        border: 1px solid #e6e6e6;
        border-left: none;
        border-right: none;

        &:last-child {
          width: 90px;
          text-align: right;
          padding-left: 5px;

          s,
          strong{
            white-space: nowrap;
          }
        }
      }

      s {
        color: @grayLight;
        margin-right: 3px;
      }

      .type-wrapper {
        width: 298px;
        .text-overflow();
      }

      .rooms-left{
        display: inline-block;
        font-size: 11px;
        line-height: 13px;
        text-align: right;
        min-width: 102px;
      }

      .elastic-wrapper {
        float: left;
        max-width: 357px;
        margin-right: 9px;
        .text-overflow();
      }

      .icon-free-upgrade-option {
        float: left;
        position: relative;
        top: 2px;
        margin-right: 2px;
      }
    }
  }
}

.amenities {
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;

  td {
    vertical-align: middle;
    width: 105px;
    height: 26px;
    padding: 2px 0 2px 4px;
    border: 1px solid #e6e6e6;
    border-top: none;
    border-left: none;
    .box-sizing(border-box);
  }

  span {
    display: block;
    position: relative;
    padding-left: 21px;
    line-height: 14px;
    font-size: 10px;
    color: #42aa21;
  }

  .muted {
    color: #ccc;
  }

  i {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -6px;
  }

  .icon-hs-wifi-internet {
    margin-top: -7px;
  }

  .icon-hs-free-parking {
    margin-top: -9px;
  }

  .icon-hs-free-breakfast {
    margin-top: -9px;
  }

  .icon-hs-gym {
    margin-top: -9px;
  }
}

.hotel-overview-gallery{
  .clearfix();
  width: 998px;
  margin: 0 0 8px;

  .large-img-wrapper{
    float: left;
    position: relative;
    width: 741px;
    height: 461px;
    line-height: 459px;
    text-align: center;
    overflow: hidden;
    background: @white;
    border: 1px solid #ddd;

    img{
      max-width:741px;
      max-height:461px;
    }

    .carousel-control {
      top: 50%;
      margin-top: -23px;
    }
  }

  .hotel-gallery-aside{
    float: right;
    width: 242px;
    margin: 1px 1px 0 0;

    h3{
      font-size: 19px;
      margin: 0 0 -10px;
    }

    h4{
      .bold();
      font-size: 13px;
      margin: 25px 0 10px 0;
    }

    p{
      margin: 23px 0 8px 0;
    }
  }

  .preview-wrapper{
    .clearfix();
    position: relative;
    margin: 0 0 0 -6px;
  }

  .thumb-wrapper {
    float: left;
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0 0 6px 6px;
    background-color: #ddd;

    img{
      cursor: pointer;
      outline: 1px solid #dddddd;
    }

    &:hover,
    &.selected {
      img{
        outline: 1px solid @purple;
      }
    }

    &.broken{
      outline: 1px solid #dddddd;
      background: url(../img/hotels-room-placeholder-small.png) no-repeat 0 0;
    }
  }

  .gallery-control{
    position: absolute;
    right: 0;
    bottom: 6px;
    text-align: right;
    display: block;
    width: 118px;
    height: 56px;
    background: @blue;
    outline: 1px solid #dddddd;

    .see-more-link{
      position: relative;
      color: @white;
      .bold();
      line-height: 40px;
      text-transform: uppercase;
      margin-right: 18px;

      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -10px;
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 5px solid @white;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      }
    }

    .pages{
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: right;
      line-height: 13px;
      width: 111px;
      height: 14px;
      padding: 0 7px 0 0;
      background: rgba(255,255,255,0.7);

      i{
        display: inline-block;
        height: 7px;
        width: 7px;
        margin-left: 3px;
        background: @blue;
        border-radius: 7px;

        &.current{
          background: @white;
        }
      }
    }

    &.inactive{
      cursor: default;
      background: #CCCCCC;

      .pages{
        i{
          display: none;
        }
      }
    }
  }
}

.js-expand-rates {
	.js-closed{}
	.icon-darr-down.js-icon{}
	.icon-darr-up.js-icon {display: none;}
	.js-open {display: none;}

	&.expanded {
		.js-closed {display: none;}
		.icon-darr-down.js-icon {display: none;}
		.icon-darr-up.js-icon {display: inline-block;}
		.js-open {display: inline;}
	}
}
