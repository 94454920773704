// TOOLTIP
// ------=

.tooltip {
  position: absolute;
  z-index: @zindexTooltip;
  display: block;
  visibility: visible;
  font-size: 12px;
  .opacity(0);
  &.in     { .opacity(100); }
  &.top    { margin-top:  -4px; }
  &.right  { margin-left:  4px; }
  &.bottom { margin-top:   4px; }
  &.left   { margin-left: -4px; }
  &.top .tooltip-arrow    { #popoverArrow > .top(@color: @grayDark); }
  &.left .tooltip-arrow   { #popoverArrow > .left(@color: @grayDark); }
  &.bottom .tooltip-arrow { #popoverArrow > .bottom(@color: @grayDark); }
  &.right .tooltip-arrow  { #popoverArrow > .right(@color: @grayDark); }
}
.tooltip-inner {
  max-width: 255px;
  padding: 3px 8px;
  color: @white;
  text-decoration: none;
  background-color: @grayDark;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip-pre-wrap + .tooltip {
  white-space: pre-wrap !important;
}