.bottom-info-block {
  position: relative;
  .clearfix();
  .border-radius(5px);
  background: @grayBg;
  padding: 20px 40px;
  width: 500px;
  margin: 0 auto;

  .spinner-block,
  .spinner {
    position: absolute !important;
    left: 60px !important;
    top: 50% !important;
  }

  p {
    padding-top: 7px;
    padding-left: 65px;
    text-align: left;
  }

  &.error {
    font-size: 14px;
    background: #ffefef !important;
    border: 1px solid @red;

    p {
      color: @red;
      line-height: 1.5;
    }
  }
}

.inner-page-block--condensed {
  padding-bottom: 0 !important;
}

.inner-page-block {
  .clearfix();
  margin: 40px 0 0 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ddd;

  .block-header,
  h3{
    .muted{
      font-style: italic;
      font-size: 16px;
      font-weight: normal;
    }
  }
}

.block-header__logo {
  float: right;
  margin-top: 3px;
}

.inner-page-head {
  .clearfix();
  margin: 10px 0 5px;

  .left-col {
    float: left;
  }

  .right-col {
    float: right;

    .item {
      float: left;
      padding: 0 0 0 30px;

      h5 {
        font-size: 13px;
        line-height: 1.4;
        .bold();
      }

      .btn-common{
        margin-top: 3px;
      }
    }
  }

  .btn {
    .buttonBackground(@white, darken(@white, 6%));
    color: @grayDark;

    i {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }
  }

  .lnk-back {
    position: relative;
    top: 18px;
    .bold();
  }
}

.alert-box{
  padding: 10px 42px;
  margin: 15px 0 10px 0;
  background: #fef6f5;
  border: 1px solid #fec8bf;

  .alert-sign{
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: 0 7px 0 -30px;
    color: @white;
    text-align: center;
    .bold();
    font-size: 15px;
    vertical-align: top;
    background: #e62117;
    border-radius: 20px;
  }

  a {
    color: @grayDark;
    text-decoration: underline;
  }
}

.alert-box--warning {
  background: lighten(@orange, 50%);
  border: 1px solid lighten(@orange, 13%);

  .alert-sign {
    background: lighten(@orange, 13%);
  }
}

.modal-cancellation,
.inner-page-block {
  .alert-box{
    margin-bottom: 25px;
  }
}

.alert-box__content {
  display: inline-block;
}

.checkout-control {
  .clearfix();
  margin: 30px 0 0 0;

  .tagline {
    margin-left: 205px;
  }

  .tc {
    font-size: 11px;
    text-align: center;
  }

  .error-bottom-message{
    margin: 15px 0 10px;
  }

  .btn-block {
    margin-top: 10px;
    padding: 0 0 20px 0;
    text-align: center;

    .btn {
      margin-bottom: 10px;
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  .control-footer {
    margin: 0 -30px -15px;
    padding: 22px 30px 5px;
    border-top: 1px solid @grayBorder;

    .item {
      display: inline-block;
      margin: 0 18px 0 0;
      text-align: left;
      color: @grayLight;

      span {
        display: inline-block;
        font-size: 10px;
        line-height: 13px;
        vertical-align: top;
      }
    }
  }
}


//Checkout forms

.inner-page-block {
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    margin-right: 6px;
  }

  fieldset {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid @grayBorder;

    + .last,
    &:last-child,
    &.border-free {
      margin-bottom: 0;
      border-bottom: none;
    }

    &.with-background {
      border: none;
    }
  }

  .border-top {
    padding-top: 10px;
    padding-bottom: 0;
    border-top: 1px solid @grayBorder;
  }

  .controls {
    .selectBox {
      margin-right: 5px;
    }

    .selectBox-label {
      &.hide {
        overflow: visible;
        color: lighten(@grayDark, 25%);
      }
    }

    .btn-secondary{
      width:55px;
    }

    .loader{
      position: relative;
      top: 6px;
      display: inline-block;
      width: 20px;
      height: 19px;
    }

    .hide{
      display: none;
    }
  }
}

.checkout-alt {
  &.content-box {
    float: left;
    width: 656px;
    padding: 0;
    margin-top: 0px;
    background: none;
    box-shadow: none;
    border: none;
    .box-sizing(border-box);

    .block-header {
      font-size: 18px;
      margin: 0 -30px 1.2em;
      padding: 8px 30px 10px;
      font-weight: normal;
      border-bottom: 1px solid @grayBorder;

      .thin {
        font-weight: normal;
      }

      .note {
        display: inline-block;
        color: @grayLight;
        font-size: 11px;
        font-weight: normal;
        vertical-align: middle;
        white-space: nowrap;
      }

      .round-num {
        display: inline-block;
        width: 23px;
        height: 23px;
        margin: -3px 4px 0 0;
        line-height: 23px;
        text-align: center;
        .bold();
        color: @white;
        font-size: 13px;
        vertical-align: middle;
        background: @blue;
        border-radius: 10em;
      }
    }
  }

  .control-label {
    width: 148px;
    color: @grayDark;
  }

  .controls {
    margin-left: 168px;
  }

  .control-group {
    margin-bottom: 10px;
  }

  .validation-indicator {
    left: 3px;
  }

  .inner-page-block {
    padding: 5px 30px 20px;
    margin-top: 8px;
    background: @white;
    border: 1px solid @grayBorder;

    .input-size-xsmall {
      width: 30px;
    }

    .input-size-small {
      width: 105px;
    }

    .input-size-medium {
      width: 172px;
    }

    .input-size-normal {
      width: 238px;
    }
     .input-size-full {
      width: 350px;
     }

    .input-size-block {
      width: 100%;
      .box-sizing(border-box);
    }

    .selectbox-size-xsmall {
      width: 172px;
    }

    .selectbox-size-xtra {
      width: 256px !important
    }

    .selectbox-size-full {
      width: 368px !important
    }

    .review-list {
      margin-left: 0
    }
  }

  .inner-block-content {
    margin: 15px 0 0;
  }
}

fieldset.section {
  border: none;
}

.control-help-top {
  padding: 5px 0 10px;

  &.small {
    padding-top: 7px;
  }
}

.control-help {
  padding: 5px 0;
}

.request__textfield {
  width: 368px;
  height: 80px;
}

.section-control {
  margin: 30px -30px 0;
  padding: 20px 30px 20px;
  border-top: 1px solid @grayBorder;
  border-bottom: 1px solid @grayBorder;
  background: @grayBg;

  .btn {
    min-width: 60px;

    &:focus{
      .box-shadow(0 0 1px 1px @grayLight);
    }
  }
}

.section-control__tag {
  display: inline-block;
  max-width: 272px;
  margin: 0 15px 0 4px;
  font-size: 11px;
  color: @gray;
  vertical-align: middle;
  text-align: right;
}

.block-step:last-of-type.block-step--hidden {
  .block-header {
    border-bottom: none;
  }
}

.block-step--hidden {
  .opacity(50);

  .block-header {
    margin-bottom: 5px !important;

    .note {
      display: none !important;
    }
  }

  .block-step__content {
    display: none;
  }
}

.block-step__edit-btn {
  display: none;
}

.block-step--complete {
  .opacity(100);

  .block-step__edit-btn {
    display: inline-block;
  }
}

.validation-message {
  display: none;
  margin: 3px 0;
  color: @red;
}

.fixed-sidebar {
  margin-left: 666px;
  border: 1px solid @grayBorder;
  background: @white;

  &.top {
    position: absolute;
    top: 8px;
  }

  &.floating {
    position: fixed;
    top: 8px;
  }

  &.bottom {
    position: absolute;
    bottom: 0;
  }

  &.top.bottom { // GG-2512
    bottom: auto;
  }

  .content-header {
    font-size: 18px;
    margin: -9px 0 3px 0;
    padding: 0;

  }

  .content {
    margin: 15px 0 0 0;
  }

  .note {
    margin: 10px 0;
    font-size: 11px;
  }

  .adv-header {
    font-size: 12px;
    text-transform: uppercase;
  }

  .adv {
    position: relative;
    margin: 20px -15px 0;
    padding: 0 20px;
    border-top: 1px dashed @grayBorder;

    a {
      color: @grayDark;
    }

    li {
      margin: 4px 0;

      i {
        margin-right: 5px;
      }
    }
  }

  .note-block {
    .clearfix();
    margin: 20px -15px -15px;
    padding: 5px 15px;
    text-align: right;
    font-size: 11px;
    font-style: italic;
    color: @gray;
    border-top: 1px solid @grayBorder;

    .dotted-lnk {
      display: inline-block;
      margin-top: 2px;
      .bold();
      font-style: normal;
      font-size: 13px;
      border-bottom: 1px dashed @blue;

      &:hover {
        span{
          border-color: @linkColorHover;
        }
      }

      i {
        position: relative;
        top: 2px;
      }
    }

    .dotted-lnk-common{
      float: left;
      font-style: normal;
      font-size: 11px;
    }
  }
}

.sidebar-hotel-info{
  position: relative;
  font-size: 12px;
  width: 330px;
  .box-sizing(border-box);

  .content-header-underline {
    font-size: 18px;
    padding: 10px 16px 10px 16px;
    margin: 0;
    border-bottom: 1px solid #d2d2d2;
  }

  .destination {
    .clearfix();
    margin-top: 7px;
    margin-bottom: 12px;
  }

  .sum-section-item {
    width: 158px !important;
    padding: 16px 2px !important;
  }

  .alert-box {
    margin: 0;
    padding: 5px 11px;
    line-height: 1.4;
    font-size: 11px;
    border-radius: 2px;
    background: #fffbdc;
    border-color: #ffe4b4;
  }
}

.sidebar-hotel-info__logo {
  position: absolute;
  right: 14px;
  top: 17px;
}

.summary-section {
  .clearfix();
  padding: 8px 17px;
  border-bottom: 1px solid @grayBorder;

  .thumb {
    float: left;
    margin-right: 10px;
  }

  .info {
    float: left;
    width: 220px;
  }

  .address {
    font-size: 11px;
    color: @grayLight;
  }

  .preference-tag{
    float: left;
    margin-right: 3px;
  }
}

.summary-section__icons {
  position: relative;
  top: 2px;
  margin-top: -2px;
}

.summary-section__inline-item {
  & + .summary-section__inline-item {
    margin-left: 5px;
  }
}

.summary-section-total{
  .clearfix();
  float: right;
  text-align: center;
  padding: 4px 0;

  .sum-section-item{
    display: inline-block;
    width: 163px;
    padding: 8px 3px;
    margin-left: -1px;
    border-left: 1px solid @grayBorder;

    &:first-child{
      border-left: 0;
    }
  }

  .final-sum{
    font-size: 22px;
    line-height: 33px;
  }
}

.checkout-summary {
  width: 100%;
  margin-top: -10px;

  tbody{
    &:first-child{
      tr:first-child{
        td{
          border-top: none;
        }
      }
    }

    tr:first-child{
      td{
        border-top: 1px solid @grayBorder;
        padding-top: 7px;
      }
    }
    tr:last-child{
      td{
        padding-bottom: 7px;
      }
    }
  }

  td {
    vertical-align: top;

    &.value {
      width: 65px;
      text-align: right;
      white-space: nowrap;
    }
  }

  .details {
    font-size: 10px;
    margin: 2px 0 0 53px;
  }

  .description {
    text-align: right;
    color: @grayLight;
    font-size: 11px;
    margin: 0;

    & + .description {
      margin-top: 14px;
    }

    .tooltip {
      text-align: center;
    }

    &.left {
      text-align: left;
    }
  }
}

.details-toggle-wrapper {
  .text-overflow();
  height: 18px;
  margin: 0 0 5px;

  &.show-description{
    height: auto;
    white-space: normal;
  }

  ul{
    margin: 0;
  }
}

.hotel-info-wrap{
  .thumb{
    float: left;
    margin-right: 10px;
  }

  h4.title{
    font-size: 13px;
    margin: 0;
  }

  .rating-wrapper{
    float: left;
  }

  .address{
    font-size: 11px;
    color: @grayLight;
  }

  .map-view-wrapper{
    margin: 17px 0px 0px;
  }
}

.map-view-wrapper{
  position: relative;
  height: 162px;
  width: 267px;
  border: 1px solid #D2D2D2;

  .control{
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3px 10px;
    .bold();
    background: white;
    background: rgba(255,255,255,0.7);
  }
}

.confirm-info{
  .info-sidebar{
    float: left;
    width: 335px;
  }

  .address{
    color: @grayDark;
  }
}

.modal-confirmation {
  .confirm-info{
    .info-sidebar{
      float: left;
      width: 240px;
    }
  }
}

.info-sidebar{
  overflow: hidden;

  .preference-tag{
    display: inline-block;
  }
}

.pay-wrapper {
  .left-col {
    float: left;
    width: 575px;
  }

  .note {
    font-size: 11px;
  }

  .right-col {
    float: right;
    width: 242px;
    margin: 17px 0 0;

    .col-item {
      margin: 0 0 50px;

      h5 {
        font-size: 11px;
        font-weight: bold;
      }
    }
  }
}

.billing-form__section {
  padding: 15px 0;
}

.billing-form__control {
  position: relative;
  margin: -3px 0 17px 168px;
  padding: 17px 20px 5px 20px;
  border: 1px solid @grayBorder;

  .btn {
    padding: 4px 10px;
    font-size: 12px;
  }
}

.billing-form__title {
  position: absolute;
  top: -9px;
  left: 12px;
  margin: 0 !important;
  padding: 2px 9px;
  line-height: 1;
  font-size: 12px !important;
  background: @white;
}

.billing-form__text {
  margin: 11px 0 3px;
  line-height: 1.4;
  font-size: 11px;
  color: @gray;
}

.control-group {
  .error-message {
    margin: 10px 0 0 0;
    padding-left: 210px;
  }
}

.pm-row {
  margin: 5px 0 8px;

  .checkbox {
    margin-right: 10px;
  }
}

.pm-row__cards {
  display: inline-block;
  clear: right;
}

.pm-row__list {
  margin-top: 6px;

  li {
    margin-bottom: 2px;
  }
}

.pm-row__icon {
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.special-requests{
  height: 0;
  overflow: hidden;
}

.requests__expand{
  height: auto;
}

.controls__help-text {
  margin-top: 4px;
  color: @gray;
  font-size: 11px;
  line-height: 14px;
}

.cc-table {
  width: 100%;

  th {
    padding: 0 0 12px 0;
    font-weight: normal;
    text-align: left;
  }

  td {
    cursor: pointer;
    padding: 10px 0;
    .transition(all 0.3s);

    &:first-child {
      padding-left: 15px;
    }
  }

  tr:hover:not(.cc-table--selected):not(.cc-table__form) {
    td {
      background: lighten(@blue, 47%);
    }
  }

  [class^="icon-cc"],
  [class*=" icon-cc"] {
    margin: -1px 3px 0;
    vertical-align: text-top;
  }
}

.cc-table--selected {
  background: lighten(@blue, 47%);
  border: 1px solid lighten(@blue, 39%);
}

.cc-table__form {
  .cc-table--selected();

  td {
    padding: 13px 0 13px 36px !important;
    cursor: inherit;
  }

  .mini-header {
    font-size: 14px;
  }

  .input-size-small {
    width: 90px !important;
  }
}

.inline-switcher {
  margin-bottom: 24px;
  padding-bottom: 13px;
  border-bottom: 1px solid @grayLighter;
}

.inline-switcher__item {
  display: inline-block;
  margin: 0 4px 5px 0;
  padding: 10px 13px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: @grayBg;
  }

  input {
    margin-right: 5px;
  }

  .glyphicon {
    margin-left: 3px;
  }
}

.inline-switcher__item--selected {
  font-weight: bold;
  background: @grayBg;
}

.hide-payment-block {
  display: none;
}

.cc-list__container {
  margin-top: -14px;
}

.cc-list {
  margin-bottom: 23px;
}

.cc-list__item {
  position: relative;
  margin-bottom: 7px;

  &:hover {
    .cc-list__type {
      background: lighten(@grayBorder, 6%);
    }
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background: @grayBg;
    }
  }
}

.cc-list__details {
  display: none;
  padding: 10px 0 3px 35px;
  background: #f6fafe;
  border: 1px solid #bad6f0;
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  h4 {
    margin: 1px 0 12px;
    font-size: 12px;
  }

  .control-label {
    width: 115px;
  }

  .controls {
    margin-left: 120px;
  }

  .control-group + .pay-wrapper {
    margin: 12px 0 0 -35px;
    padding-top: 12px;
    padding-left: 35px;
    border-top: 1px solid #bad6f0;
  }

  .billing-form__control {
    margin-top: 4px;
    margin-right: 15px;
    margin-left: 120px;
    padding-bottom: 8px;
    border-color: #d7e0e9;
  }

  .billing-form__title {
    background: #f6fafe;
  }

  hr {
    margin: 16px 15px 0 0;
  }
}

.cc-list__wrapper {
  display: table;
  width: 100%;
  border: 1px solid lighten(@grayBorder, 5%);
  border-radius: 2px;
  box-sizing: border-box;
}

.cc-list__control {
  position: absolute;
  left: 9px;
  top: 13px;

  &:checked {
    & ~ .cc-list__wrapper {
      background: #e6f2fb !important;
      border-color: #bad6f0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    & ~ .cc-list__type {
      background: #c0dcf6;
    }

    & ~ .cc-list__details {
      display: block;
    }
  }
}

.cc-list__type {
  position: absolute;
  right: -6px;
  top: -9px;
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
  background: lighten(@grayBorder, 10%);
  border-radius: 2px;

  & ~ .cc-list__wrapper {
    margin-top: 13px;
  }
}

.cc-list__col {
  display: table-cell;
  padding: 10px 13px 9px;
  font-size: 12px;

  [class^="icon-cc"] {
    margin: -1px 6px 0 0;
    vertical-align: top;
  }
}

.cc-list__col--cc {
  width: 47.8%;
  padding-left: 35px;
  padding-right: 2px;
}

.cc-list__col--name {
  width: 32%;
}

.cc-list__link {
  display: block;
  padding: 9px 0;
  text-align: right;
  border-top: 1px solid @grayBorder;
  border-bottom: 1px solid @grayBorder;
}


.payment-footer {
  margin: 20px -30px -30px;
  padding: 21px 30px 15px;
  background: #fefefe;
  border-top: 1px dashed @grayBorder;

  .controls {
    margin-left: 0;
  }
}

.payment-footer__title {
  font-size: 14px !important;
  margin: 0 0 14px !important;
}

.confirmation-alt .block-header {
  font-size: 17px !important;
  padding: 3px 30px 7px !important;
}

.target-selector {
  .clearfix();
  margin: 24px 0 7px;
}

.target-selector__item {
  position: relative;
  display: inline-block;
  width: 49%;
  padding: 9px 80px 9px 20px;
  border-left: 3px solid @blue;
  border-radius: 2px;
  box-shadow: 0 0 15px rgba(0,0,0,0.13);
  .box-sizing(border-box);

  &:hover {
    border-left-color: @orange;
    box-shadow: 0 0 15px rgba(150,0,0,0.15);

    .target-selector__title,
    .glyphicons {
      color: @orange;
    }
  }

  &:last-child {
    float: right;
  }

  .glyphicons {
    position: absolute;
    right: 26px;
    top: 17px;
    font-size: 24px;
  }
}

.target-selector__item--ghost {
  pointer-events: none;
  height: 58px;
  border: 1px dashed @grayBorder;
  box-shadow: none;
}

.target-selector__title {
  display: block;
  margin-bottom: 2px;
  font-size: 15px;
}

.target-selector__text {
  display: block;
  font-size: 12px;
  color: @grayDark;
}

.control-group__trigger {
  .clearfix();
  width: 535px;
  margin: -2px 0 1px;
  font-size: 12px;
}

.section--footer {
  margin: 0 -30px -20px !important;
  padding: 17px 30px 0 !important;
  border-top: 1px solid @grayBorder !important;
}

.section-note {
  margin: 10px 20px 5px 168px;
  font-size: 11px;
}
