.agents {
  &.full-bg {
    background: #FAD797 url(../img/bg_agents.jpg) no-repeat 0 0;
    background-size: cover;
  }

  .autocomplete-rev .select2-results {
    max-height: 280px;
  }
}

.partner {
  > .select2-hidden-accessible {
    display: none;
  }

  .rooms-list-subheader {
    .column-type {
      width: 471px;
    }

    .column-price {
      margin-right: 20px;

      &.highlight-green {
        color: @grayDark;
      }
    }
  }
}

.ag-agent-wrapper {
  position: relative;
  margin: 40px 0 0 0;
  background: @white;
  box-shadow: 0 0 14px rgba(0,0,0,0.25);
  .box-sizing(border-box);

  .block-title {
    padding-bottom: 11px;
    font-size: 18px;
  }
}

.block-title__muted {
  color: @gray;
}

.ag-left-block {
  position: relative;
  float: left;
  width: 360px;
  padding: 25px 10px 10px 30px;

  .preloader {
    position: absolute;
    top: 0;
    left: 7px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 5;

    .spinner-wrapper {
      position: relative;
      top: 115px;
      left: 50%;
      width: 20px;
      height: 20px;
      margin: 5px 0 0 -4px;
    }

    .message {
      position: absolute;
      left: 2px;
      top: 150px;
      width: 100%;
      text-align: center;
    }
  }

  .pnr-search-error {
    color: @red;
  }
}

.ag-right-block-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 590px;
  background: @grayBg;
  border-left: 1px solid @grayBorder;
}

.ag-right-block {
  position: relative;
  float: right;
  width: 590px;
  padding: 25px 47px 10px 40px;
  z-index: 3;
  .box-sizing(border-box);
}

.inactive-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.6);
  z-index: 15;
}

.alert-message {
  margin: 0 0 12px 0;
  padding: 5px 15px;
  font-size: 14px;
  background: #FFFFC6;
  border: 1px solid #ddd;
}

.ag-form {
  .clearfix();
  margin: 10px 0 6px;

  .row-label {
    float: left;
    width: 80px;
    margin: 0 0 0 10px;
    padding: 6px 5px 0 0;
    color: @gray;
    .bold();
    .box-sizing(border-box);
    text-shadow: 1px 1px 0 rgba(255,255,255,0.8);
  }

  input {
    font-size: 12px;
    margin: 0;
  }

  .lnk-control {
    float: right;
    font-size: 12px;
  }

  .custom-select {
    .input-selector {
      position: relative;
    }

    select {
      width: 100%;
    }
  }

  .lnk-distance{
    .selector-value {
      height: 29px;
      line-height: 29px;
    }
  }

  .selector-value {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 31px;
    line-height: 29px;
    padding: 0 20px 0 8px;
    color: @grayDark;
    outline: none;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #bbbbbb;
    z-index: 1;
    #gradient > .vertical(#fafafa, #f1f1f1);
    .text-overflow();
    .box-sizing(border-box);

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 8px;
      top: 13px;
      width: 0;
      height: 0;
      border-top: 4px solid @grayDark;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &.focus {
      outline: none;
    }
  }

  .input-selector-disabled {
    .selector-value {
      color: @grayLight;
      background: none;
      background-color: @grayLighter;
      border-color: #ddd;
      cursor: default;

      &:after {
        border-top-color: @grayLight;
      }
    }
  }

  .custom-select {
    &.custom-select-focus {
      .selector-value {
        border: 1px solid @blue;
      }
    }
  }

  .ag-row {
    .clearfix();
    margin: 0 0 13px -10px;
  }


  .ag-row--empty {
    margin-bottom: 0;
  }

  .pnr-search-error {
    margin-bottom: 8px;
  }

  .item {
    float: left;
    margin: 0 0 0 10px;
  }

  .item--text-align-right {
    float: none;
    text-align: right;
    padding-right: 24px;
  }

  .addon {
    margin-left: -7px;
    border-radius: 0 3px 3px 0;
  }

  .embedded-icon {
    position: relative;

    i {
      position: absolute;
      right: 8px;
      bottom: 7px;
    }
  }

  .custom-select {
    margin-right: 0;

    .value {
      font-size: 12px;
    }

    &.dropdown {
      .select2-choice {
        position: relative;
        border: 1px solid #bbbbbb;
        cursor: pointer;
        #gradient > .vertical(#fafafa, #f1f1f1);

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 8px;
          top: 13px;
          height: 0;
          width: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid @grayDark;
        }

        .select2-chosen {
          color: @grayDark;
        }
      }
    }
  }

  .line {
    height: 1px;
    margin: 5px 0 10px 10px;
    background: @grayBorder;
  }

  .control-btn {
    margin-top: 19px;
    width: 71px;
  }

  .input-small {
    width: 182px;
  }

  .input-ex {
    width: 230px;
  }

  .input-normal {
    width: 272px;
  }

  .input-normal-ex {
    width: 229px;
  }

  .input-normal-ex + .btn {
    width: 90px;
    padding-left: 0;
    padding-right: 0;
  }

  .input-long {
    width: 392px;
  }

  .input-xlong {
    width: 491px;
  }

  .user-selector {
    width: 337px;
  }

  .selectbox-size-xsmall {
    .selector-value {
      width: 163px;
    }
  }

  .errors-block {
    color: @red;
    left: 10px;

    li {
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

.row-label--full {
  width: auto !important;
  padding-top: 0 !important;
  padding-bottom: 4px !important;
}

.autocomplete-vertical {
  width: 100%;
  max-height: 318px;
  padding-left: 0;
  margin-left: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.ag-form {
  .select-list-dropdown {
    .row {
      white-space: nowrap;
    }

    .column {
      float: none;
      display: inline-block;
    }
  }

  .select2-container,
  .input-selector {
    &.input-long {
      width: 509px;

      input {
        .box-sizing(border-box);
      }
    }
  }

  .select2-default {
    color: @grayLight;
  }

  .select2-input {
    width: 500px !important;
  }

  .select2-container-multi .select2-choices {
    .box-sizing(border-box);
    padding: 0 0 1px 3px;
    min-height: 33px;
    border: 1px solid #bbb;

    .select2-search-field input {
      height: 29px;
    }

    .select2-search-choice {
      margin: 3px 2px 0 0;
      line-height: 25px;
      font-size: 12px;

      div:after {
        content: "" !important;
      }
    }
  }

  .select2-container-active {
    .select2-choice,
    .select2-choices {
      border-color: @blue !important;
      box-shadow: none;
    }
  }
}

.ag-info-table {
  width: 345px;
  margin: 5px 0 5px 0;

  th {
    width: 160px;
    padding: 0 5px;
    color: darken(@gray, 2%);
    text-align: left;
    font-weight: normal;
    white-space: nowrap;
    background: darken(@grayBg, 2%);

    .tooltip {
      white-space: normal;
    }
  }

  td {
    padding: 4px 5px 4px 10px;
  }

  tr {
    border-bottom: 1px solid @grayBorder;

    &:first-child {
      border-top: 1px solid @grayBorder;
    }
  }
}

.ag-info-table__icon {
  margin: 0 0 -2px 2px;
  .opacity(85);
  cursor: pointer;
}

.ag-right-normalized {
  padding-top: 2px;
  padding-right: 25px;
}

.aft-preloader {
  position: relative;
  height: 130px;
  color: @gray;
  font-size: 18px;
  text-align: center;
  background: @white;

  .loading-wrapper {
    line-height: 52px;
  }

  .spinner-wrapper{
    display: inline-block;
    position: relative;
    bottom: -12px;
    margin-right: 4px;
    width: 30px;
    height: 33px;
  }
}

.pnr-wrapper {
  .header {
    position: relative;
    height: 41px;
    padding: 10px 45px 9px 15px;
    font-size: 18px;
    background: @grayBg;
    border-bottom: 1px solid @grayBorder;
    .box-sizing(border-box);

    em {
      font-size: 13px;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 41px;
      height: 41px;
      border-left: 1px solid @grayBorder;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -7px 0 0 -7px;
      }
    }
  }

  .tab-control {
    padding: 12px 15px 0;
    border-bottom: 1px solid @grayBorder;

    a {
      display: inline-block;
      margin: 0 5px -1px 0;
      padding: 6px 12px;
      .bold();

      &.active {
        padding: 6px 11px;
        color: @grayDark;
        background: #F3F8FD;
        border: 1px solid @grayBorder;
        border-bottom: none;
        border-radius: 3px 3px 0 0;
        cursor: default;
      }
    }
  }

  .item-list {
    .clearfix();
    background: #F3F8FD;
  }

  .item {
    margin: 10px;
    border: 1px solid @grayBorder;

    &.item-empty {
      border: none;
    }
  }

  .head-block {
    .clearfix();
    padding: 8px 10px;
    background: @white;
    border-bottom: 1px solid @grayBorder;

    .icon {
      float: left;
      width: 40px;
    }

    .att {
      font-size: 16px;
    }

    .details {
      float: left;
      width: 205px;

      small {
        font-size: 11px;
      }

      .primary {
        margin: 3px 0 2px;
        font-size: 16px;
      }
    }

    .control {
      float: right;
      margin-top: 2px;
    }
  }

  .item-content {
    padding: 10px 15px;
    font-size: 12px;
    background: @white;

    .details-table {
      width: 100%;
      margin: 5px 0;

      tr:last-child {
       td {
        border-bottom: none;
       }
      }

      td {
        padding: 5px 0 5px 35px;
        border-bottom: 1px solid @grayBorder;
      }
    }
  }
}

.content-wrapper {
  .clearfix();
  padding: 25px 40px 60px;
  border: 1px solid @grayBorder;
  background: @white;

  .content-sidebar {
    float: left;
    width: 200px;
  }

  .content {
    float: left;
    width: 665px;
    margin: 0 0 0 50px;
  }

  h2.section-title {
    font-size: 22px;
    line-height: 40px;
  }

  h3.section-title {
    line-height: 40px;
  }

  h3.content-header {
    padding: 0 15px;
    font-size: 14px;
    line-height: 26px;
    border-bottom: 1px solid @grayBorder;
    background: @grayLighter;
    .bold();
  }

  .content-inner {
    margin: 14px 0 0 0;
    border: 1px solid @grayBorder;
  }
}

.content-nav {
  margin: 15px 0;
  padding: 0;
  list-style: none;

  a {
    display: block;
    padding: 0 5px 0 10px;
    line-height: 33px;
    color: @gray;
    border-bottom: 1px solid @grayBorder;

    &:hover {
      color: @grayDark;
    }

    &.active {
      color: @white;
      .bold();
      background: @purple;
      border-bottom: 1px solid @purple;
    }
  }

  li:first-child {
    a {
      border-top: 1px solid @grayBorder;

      &.active {
        border-top: 1px solid @purple;
      }
    }
  }
}

.rev-form {
  margin: 0;

  fieldset {
    margin: 15px 40px;
    padding: 20px 0 10px;
    border-bottom: 1px solid @grayBorder;
  }

  legend {
    width: 100%;
    .bold();

    aside {
      font-weight: normal;
      font-size: 11px;
      color: @gray;
    }
  }

  .info {
    color: @gray;
    margin: -5px 0 10px;
    font-style: italic;

    i {
      position: relative;
      top: 3px;
      .opacity(65);
    }
  }

  .sub-label {
    .clearfix();
    margin-left: 130px;

    label {
      float: left;
      margin-right: 6px;
      padding: 0 21px 0 1px;
      font-size: 11px;
      color: @gray;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .control-group {
    margin-bottom: 12px;

    &.multi-row-wrapper {
      .control-label {
        padding-right: 5px;
      }
    }
  }

  .control-label {
    width: 125px;
    text-align: left;
    color: @gray;
  }

  .controls {
    margin-left: 130px;

    .remove-lnk {
      position: relative;
      top: 3px;
      margin-left: -2px;
      padding: 1px;
      .opacity(70);

      &:hover {
        .opacity(95);
      }
    }
  }

  input {
    margin-right: 6px;

    &[readonly] {
      color: @grayDark;
    }
  }

  .input-mini {
    width: 36px;
  }

  .input-small {
    width: 124px;
  }

  .input-default {
    width: 168px;
  }

  .input-default-plus {
    width: 232px;
  }

  .input-large-extra {
    width: 428px;
  }

  .selectbox-size-small {
    width: 186px;
  }

  label.selectbox-size-small {
    width: 167px;
  }

  .selectbox-size-normal-plus {
    width: 232px;
  }

  .control-form {
    margin-top: -16px;
    padding: 20px 40px;
    border-top: 1px solid @grayBorder;
    background: #F5F9FE;

    .btn {
      min-width: 80px;
      margin-right: 6px;
    }
  }
}

.item-order {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    .clearfix();
    margin-bottom: 4px;
    padding: 5px 10px;
    border: 1px solid @grayBorder;
    background: #F5F9FE;
    border-radius: 3px;

    &:hover {
      border-color: @blue;

      .title {
        color: @blue;
      }
    }

    &.inactive {
      background: @grayBg;
      border: 1px solid @grayBorder;

      .title {
        color: @gray;
      }
    }
  }

  .custom-checkbox {
    position: relative;
    top: 6px;
  }

  .input-pos {
    width: 15px;
    margin-left: 3px;
    text-align: center;
  }

  .title {
    color: @gray;
    .bold();
    cursor: default;
  }

  .trigger-drag {
    float: right;
    margin: 5px 6px 0 0;
    padding: 3px;
    cursor: move;

    &:hover {
      .icon-custom-sort-dimmed {
        background-position: -780px -240px;
      }
    }
  }
}

.modal-rate {
  position: relative;
  left: auto;
  top: auto;
  margin: 100px auto 40px;
  padding-bottom: 30px;

  .table-rate-wrapper {
    margin: 0 -30px 14px -30px;
    padding: 8px 20px 8px 30px;
    background: @grayBg;
    border-bottom: 1px solid @grayBorder;
  }

  .price-table {
    width: 100%;
    margin-top: -3px;

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    td {
      padding: 3px 0 5px;
      border-bottom: 1px solid @grayBorder;
    }

    .info {
      width: 80%;
    }

    .value {
      vertical-align: top;
      .bold();
      text-align: right;
    }

    .note {
      padding: 0;
      text-align: left;
    }
  }

  .book-info {
    .left-col {
      float: left;
      width: 600px;
    }

    .right-col {
      float: right;
      text-align: right;
    }

    .badge {
      display: inline-block;
      padding: 0 5px;
      color: @white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;

      &.out-of-policy {
        background: #A1140D;
      }
    }
  }

  .book-info {
    margin: 14px 0 0;
    padding: 14px 0 0;
    border-bottom: none;
    border-top: 1px solid @grayBorder;
  }

  .top-level {
    .list-header {
      font-weight: normal;
      width: 125px;
    }
  }

  .raw-description {
    display: block;
    padding-top: 14px;
  }

  .desc {
    margin-left: 125px !important;
  }

  .note {
    padding: 5px 0;
    text-align: right;
    font-size: 11px;
  }
}

.table-rate-details {
  width: 100%;
  font-size: 12px;

  th {
    font-size: 12px;
    text-align: left;
    color: @gray;
    font-weight: normal;
  }

  .am-list {
    i {
      position: relative;
    }

    .icon-hs-wifi-internet {
      top: -1px;
    }
  }
}

.confirmation-alt {
  &.content-box {
    width: 100%;
  }

  .room-description {
    &.collapsed {
      max-height: 60px;
    }
  }
}

.grid-details {
  .left-col {
    float: left;
    width: 615px;
  }

  .right-col {
    float: right;
    width: 255px;
  }

  .list-header {
    margin: 0;
  }

  .list-header__rate-label {
    float: left;

    & + .list-header__rate-lnk {
      float: right;
    }
  }

  .confirm-status {
    line-height: 23px;
    padding: 6px 13px 9px 13px;
    margin: -5px -30px 0;
    font-size: 19px;
    color: @white;
    background: @greenDark;
    .box-sizing(border-box);

    &.canceled{
      background: #E13625;
    }
    &.pending{
      background: #2790f1;
    }

    [class*="icon-"]{
      position: relative;
      top: 4px;
    }
  }

  .inline-details {
    margin: 18px 0 0 0;

    .item {
      float: left;
      margin-right: 25px;
      white-space: nowrap;
    }
  }

  .status-footer {
    margin-top: 10px;
    padding-top: 18px;
    font-size: 18px;
    border-top: 1px solid @grayBorder;
  }

  article.top-level {
    .list-header {
      position: relative;
      font-weight: normal;
      width: 170px;
      margin: -8px 0 -7px 0;
      padding: 8px 10px 7px 10px;
      background: #fafafa;
      z-index: 1;
    }

    .desc {
      position: relative;
      margin: -8px 0 -7px 170px;
      padding: 8px 0 7px 10px;
      background: @white;

      &:after{
        content: "";
        display: block;
        position: absolute;
        left: -170px;
        top: 0;
        width: 170px;
        height: 100%;
        background: #fafafa;
        z-index: 0;
      }
    }

    &.top-level:nth-of-type(1){
      padding-top: 8px;
      border-top: 1px solid #d2d2d2;
    }
  }

  .confirm-info {
    margin: 0 0 20px 0;

    .reservation-control {
      float: right;
      width: 190px;
      text-align: right;
    }
  }

  .sub-head {
    margin: 7px 0 0;
    font-size: 13px;
  }
}

.item--last {
  float: right !important;
  margin: 0 !important;
}

.item__confirm-actions {
  a {
  float: left;
  display: block;
  margin: 3px 0 0 3px;
  padding: 1px 12px;
  color: @white;
  font-size: 11px;
  white-space: nowrap;
  background: rgba(0,0,0,0.3);
  border-radius: 2px;

    &:hover {
      background: rgba(0,0,0,0.4);
    }
  }

  i {
    top: -1px !important;
    margin-right: 4px;
  }
}

.secondary-list {
  margin: 10px 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 3px;
  }

  .title {
    color: @gray;
  }
}

// TODO: Change badges to ".inline-badge"
// for the "Rates Modal"

.inline-badge {
  display: inline-block;
  margin: 0 2px 0 0;
  padding: 0 7px;
  text-transform: uppercase;
  font-size: 11px;
  color: @white;
  .bold();
  background: #A1140D;
}

.lnk-distance {
  position: absolute;
  right: 0;
  bottom: 0;

  .input-selector:after{
    display: none;
  }

  .selector-value{
    .reset-filter();
    position: relative;
    top: -1px;
    right: 2px;
    color: @linkColor;
    text-align: right;
    height: 34px;
    width: 80px;
    padding: 0 20px 0 0;
    background: @white;
    border: none !important;
    box-shadow: none !important;

    &:after{
      content: "";
      display: block;
      position: absolute;
      right: 8px;
      top: 12px;
      width: 0;
      height: 0;
      border-top: 6px solid @linkColor;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

    }
  }
}

.main-home-form{
  .lnk-distance{
    .selector-value{
      &:after{
        top: 15px;
      }
    }
  }
}

.search-results-container {
  .input-item {
    .selector-value {
      &:after{
        top: 11px;
      }
    }
  }
}

.sidebar-chain-filter {
  .select2-input {
    margin-bottom: 0;
    font-size: 12px;
  }

  .select2-default {
    color: @grayLight;
  }

  .select2-input {
    width: 203px !important;
  }

  .select2-choices {
    min-height: 34px;
    font-size: 12px;
    border: 1px solid #bbb;
    box-shadow: none !important;
  }

  .select2-search-choice {
    width: 100% !important;
    margin-left: 0 !important;
    line-height: 1.8 !important;
    .box-sizing(border-box);

    div:after {
      content: "" !important;
    }
  }

  .select2-container-active .select2-choice,
  .select2-container-active .select2-choices {
    border-color: @blue !important;
  }

  .select2-container-multi .select2-choices .select2-search-field input,
  .select2-choice .select2-search-field input {
    height: 30px;
  }

  .select2-search-choice-close {
    top: 50%;
    margin-top: -5px;
  }

  .btn {
    margin-bottom: 6px;
    width: 100%;
    .box-sizing(border-box);
  }
}

.sidebar-name-filter {
  .select2-input {
    margin-bottom: 0;
    font-size: 12px;
  }

  .select2-default {
    color: @grayLight !important;
  }

  .select2-choice, .select2-choices {
    min-height: 34px;
    font-size: 12px;
    border: 1px solid #bbb !important;
    box-shadow: none !important;
    width: 211px !important;
  }

  .select2-chosen {
    display: inline-block;
    width: 175px;
    .text-overflow();
  }

  .select2-search-choice, .select2-search-choices {
    width: 100% !important;
    margin-left: 0 !important;
    line-height: 1.8 !important;
    .box-sizing(border-box);

    div:after {
      content: "" !important;
    }
  }

  .select2-container-disabled .select2-choice,
  .select2-container-disabled .select2-choices {
    color: @grayLight !important;
  }

  .select2-container {
    padding: 0 0 0 7px !important;
    height: 30px;
  }

  .select2-search-choice-close {
    top: 50%;
    margin-top: -7px;
  }

  .btn-trigger {
    top: 6px;
    right: 6px;
    z-index: 10;
  }
}

.name-filter-autocomplete,
.hotel-name-modal-autocomplete {
  margin-top: 7px;

  .select2-input {
    position: absolute;
    top: -37px;
    left: -1px;
    height: 32px;
    width: 193px;
  }
}

.hotel-name-modal-autocomplete {
  .select2-input {
    width: 181px;
  }
}

.requests {
  .clearfix();
  padding-bottom: 0 !important;

  .mini-header {
    font-size: 13px;
    margin: 0;
  }

  .block-selectbox {
    margin: 0 0 10px 0;
  }

  .block-label {
    color: @grayDark;
  }

  .selectbox-size-small {
    width: 134px;
  }

  .col {
    float: left;
    width: 150px;
    margin: 5px 10px 0 0;

    &.extended {
      width: 339px;
    }
  }

  .note {
    clear: both;
    margin-left: 135px;
    padding: 0 0 5px;
    color: #333;
    font-size: 11px;
  }

  .request__textfield{
    .box-sizing(border-box);
    width: 100%;
    resize: none;
  }
}

.checkbox-table {
  width: 100%;
  margin-bottom: 5px;

  th {
    padding-bottom: 4px;
    text-align: left;
    color: @grayDark;
    font-weight: normal;
  }

  td {
    padding: 3px 8px 0 0;
  }

  .disabled {
    color: @grayLight;
  }
}

.sidebar-nav {
  float: left;
  width: 240px;
  margin-top: 36px;

  .details {
    margin: 40px 0 0 0;
    color: #666;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.7);

    h4 {
      margin: 0 0 12px 0;
      font-size: 18px;
      .bold();
    }

    .details-list {
      li {
        margin-bottom: 3px;
      }
    }
  }

  .nav {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      padding: 8px 7px;
      color: @gray;
      border-bottom: 1px solid @grayBorder;

      &:hover {
        background: #e6e6e6;
        border-bottom: 1px solid transparent;
      }
    }

    .active {
      a {
        .bold();
        color: @white;
        background: #1E74CC;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.content-narrow {
  float: right;
  width: 700px;
  margin-top: 30px;
  padding: 0 30px;
  border: 1px solid @grayBorder;
  background: @white;
  .box-sizing(border-box);

  h2.header {
    margin: 0 -30px 25px;
    padding: 4px 30px 3px;
    font-size: 18px;
    .bold();
    border-bottom: 1px solid @grayBorder;

    .note {
      float: right;
      font-weight: normal;
      font-size: 11px;
    }
  }

  .preloader {
    padding: 20px 0 0;
    color: @gray;
    font-size: 18px;
  }

  .spinner-wrapper {
    display: inline-block;
    width: 25px;
    height: 18px;
    margin-right: 5px;
  }
}

.form-heading {
  margin: 0 0 20px;

  h4 {
    margin: 0 0 25px;
    font-size: 16px;
    .bold();
  }

  .form-note {
    .icon-info-sign {
      position: relative;
      top: 2px;
    }
  }
}
