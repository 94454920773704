.bcd,
.login-page-bg {
  .page-footer {
    padding: 0;
    color: #c8cdcf;
    font-size: 11px;
    border: none;
    background: #596771;
    box-shadow: none;

    .footer-top-wrap {
      margin: 0;
    }
  }

  .footer-placeholder {
    height: 118px;
    margin-top: 11px;
  }

  .footer-light {
    .box-sizing(border-box);
    .clearfix();

    padding: 17px 0;

    .line-item {
      float: left;
      width: 67.2%;
      margin-right: 5px;
      line-height: 16px;
    }

    .light-logo {
      display: inline-block;
      position: relative;
      top: 2px;
      width: 95px;
      height: 14px;
      margin: 0 0 0 3px;
      background: url(../img/logo_footer_light.png) 0 0 no-repeat;
    }

    .sub-nav {
      float: right;
      text-align: right;

      a {
        padding-left: 12px;
        font-size: 12px;
        color: @white;
        .bold();

        &:hover {
          color: darken(@white, 5%);
        }

        i {
          position: absolute;
          left: 0;
          top: 3px;
        }
      }
    }
  }
}

.partner {
  &.bcd-home {
    background: #f8f8f8;
  }

  .main-header {
    border-top: 4px solid #596771;
  }

  .main-home-form {
    .autocomplete-huge {
      max-height: 450px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  .main-row-label {
    margin-top: 6px;
  }

  .inner-page-head {
    margin: 13px 0 5px;
  }
}

.booking-panel{
  position: absolute;
  top: 0;
  right: 0;
  width: 285px;
  overflow-x: hidden;

  &.floating {
    position: fixed;
    right: auto;
    top: 10px;
    margin-left: 1007px;
    max-height: 100%;
  }

  &.bottom {
    top: auto;
    bottom: 10px;
  }

  &.end {
    top: auto;
    bottom: 129px;
  }

  .panel-header{
    font-size: 16px;
    line-height: 44px;
    .bold();
    height: 44px;
    margin: 0 11px;
    border-bottom: 1px solid #ddd;
  }

  .panel-content{
    padding: 8px 11px;
  }

  .travel-info-item{
    padding: 12px 0 8px 0;
    border-top: 1px solid #ddd;

    &:first-child{
      border-top: 0;
    }

    .title{
      .bold();
    }

    .panel-table{
      width: 100%;

      th{
        color: @grayLight;
        font-weight: normal;
        vertical-align: top;
        text-align: left;
        width: 82px;
        padding: 0 8px 5px 0;
      }

      td{
        vertical-align: top;
        text-align: left;
        padding: 0 0 5px 0;
      }
    }
  }
}

.booking-panel-container{
  position: relative;
  z-index: 1;
}

.map-bg {
  border: 1px solid @grayBorder;
  background: @white;
}

.item-list-bg {
  padding: 7px 12px;
  border: 1px solid @grayBorder;
  background: @white;

  .sort-header {
    margin: 0 0 6px;
    padding: 3px 0;
    font-size: 12px;

    a {
      position: relative;
      display: inline-block;
      color: @gray;

      &:hover {
        color: @grayDark;

        &.asc:after {
          border-top-color: @grayDark !important;
        }

        &.desc:after {
          border-bottom-color: @grayDark !important;
        }
      }

      &.asc:after {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        right: -11px;
        border-left: 4px solid transparent;
        border-top: 4px solid @gray;
        border-right: 4px solid transparent;
      }

      &.desc:after {
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        right: -11px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid @gray;
      }
    }
  }

  .item {
    position: relative;
    padding: 7px 0 5px;
    border-top: 1px solid @grayBorder;
    .box-sizing(border-box);

    .note {
      line-height: 14px;
      font-size: 11px;
    }

    &.bottom{
      padding: 12px 0;
    }
  }

  .item:hover,
  .item.active {
    box-shadow: 0 12px 10px -10px rgba(0,0,0,0.2),
                         0 -10px 10px -12px rgba(0,0,0,0.2);

    .item-details {
      .btn-tabs {
        display: block;
      }
    }
  }

  .item.active {
    .btn-tab-content {
      display: block;
    }
  }

  .price {
    float: left;
    width: 95px;

    .value {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .unavailable {
      font-size: 12px;
      color: @red;
    }
  }

  .preference {
    float: left;
    width: 85px;
    height: 20px;
  }

  .info {
    float: left;
    width: 321px;
    padding-left: 15px;

    .title {
      position: relative;
      height: 20px;

      .inner {
        display: inline-block;
        max-width: 92%;
        color: @grayDark;
        .bold();
        .text-overflow();
      }
    }

    .item-list-bg__tag {
      position: absolute;
      top: 1px;
      left: -23px;
    }
  }

  .distance {
    float: left;
    font-size: 12px;
    width: 77px;
  }

  .rating {
    float: left;
    width: 50px;
  }

  .advisor-rating {
    float: left;
    width: 70px;
  }

  .amenities-list-simple {
    width: 92%;
    margin: 5px 0 6px 0;
    font-size: 11px;
    color: @gray;

    td {
      padding: 2px 3px;
      white-space: nowrap;
    }

    .muted {
      color: #a7a7a7;
    }
  }

  .quick-preview-placeholder {
    position: absolute;
    top: 10px;
    right: 0;
    width: 210px;
    height: 144px;
    background: #eee url(../img/hotel_unavailable.png) 50% 50% no-repeat;
    z-index: 0;
  }

  .quick-preview {
    position: absolute;
    top: 10px;
    right: 0;
    width: 210px;
    height: 144px;
    background-size: cover !important;
    background-position: 50% 50%;
    z-index: 5;

    .control {
      display: none;
      position: absolute;
      top: 50%;
      width: 24px;
      height: 24px;
      margin-top: -13px;
      border: 2px solid @white;
      background: #333;
      background: rgba(0,0,0,0.5);
      border-radius: 10em;

      &:hover {
        background: rgba(0,0,0,0.8);
      }

      &.right {
        right: 10px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -2px;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 5px solid @white;
        }
      }

      &.left {
        left: 10px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -4px;
          border-top: 6px solid transparent;
          border-right: 5px solid @white;
          border-bottom: 6px solid transparent;
        }
      }
    }

    &:hover {
      .control {
        display: block;
      }
    }
  }

  .advisor-wrapper {
    display: inline-block;
    position: relative;
    top: 1px;
    margin: 1px 5px 0;
  }

  footer.item-details {
    min-height: 36px;
    clear: left;

    .btn-tabs {
      display: none;
      margin-left: 195px;
    }
  }
}

.btn-tabs {
  a {
    display: inline-block;
    min-width: 55px;
    margin: 5px 1px 5px 0;
    padding: 0 6px;
    line-height: 1.8;
    color: @gray;
    text-align: center;
    .bold();
    border: 1px solid @grayBorder;
    border-radius: 3px;

    &:hover {
      color: @grayDark;
      background: @grayBg;
    }

    &.active {
      height: 29px;
      color: @grayDark;
      margin-bottom: -1px;
      background: inherit;
      border-radius: 3px 3px 0 0;
      border-bottom: 1px solid @white;

      &:hover {
        background: inherit;
      }
    }
  }

  .attn {
    color: @white;
    background: @blue;
    border-color: @blue;

    &:hover{
      color: @white;
      background: darken(@blue, 15%);
      border-color: darken(@blue, 15%);
    }

    &.active {
      color: @grayDark;
      border-color: @grayBorder;
      border-bottom: 1px solid @white;

      &:hover {
        background: inherit;
      }
    }
  }
}

.btn-tab-content {
  display: none;
  padding: 3px 0 3px 25px;
  border-top: 1px solid @grayBorder;

  .btn-control {
    min-width: 60px;
  }

  header {
    .clearfix();
    padding: 6px 0 8px;
    border-bottom: 1px solid @grayBorder;
  }

  .hotel-descr-aside {
    margin: 10px 0 5px;

    .hotel-description-wrap {
      overflow: hidden;

      &.collapsed {
        max-height: 200px;
      }
    }
  }

  footer {
    .clearfix();
    height: 24px;
    margin-top: 5px;
    padding: 10px 0 5px;
    border-top: 1px solid @grayBorder;
  }
}

.btn-tab-content__footer-loader {
  float: left;
  margin-top: -4px;
}

.header-filters{
  .clearfix();
  border-bottom: 1px solid @grayBorder;

  div{
    .bold();
    padding-bottom: 5px;
    padding-top: 5px;
  }

  span{
    position: relative;
    color: @gray;
    font-size: 12px;

    &.down{

      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -10px;
        border-top: 4px solid @gray;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
      }
    }

    &.up{

      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -10px;
        border-bottom: 4px solid @gray;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
      }
    }
  }
}

.hotel-table-wrap{
  .com{
    float: left;
    width: 45px;
  }

  .price{
    float: left;
    position: relative;
    width: 168px;
    font-size: 14px;
    white-space: nowrap;
  }

  .gd{
    float: left;
    height: 15px;
    width: 35px;
    padding-right: 5px;
  }

  .description{
    float: left;
    width: 265px;
    padding-right: 5px;

    .rate-details-spinner{
      display: block;
      position: absolute;
      top: 10px;
      left: -15px;
    }
  }

  .booking-info {
    float: left;
    width: 145px;
    font-size: 11px;

    .badge {
      margin: 1px 0 0;
      padding: 0;
      font-size: 10px;
      font-weight: normal;
      color: @red;
      background: none;
    }
  }

  .icons {
    float: left;
    width: 65px;

    [class^=icon-hs]{
      vertical-align: middle;
    }
  }

  .cancel {
    float: left;
    width: 136px;
    min-height: 15px;
    margin-top: 2px;
    line-height: 15px;

    .btn {
      margin-top: -2px;
    }
  }
}

.price__tag {
  color: @gray;

  & + .room-mute {
    margin-top: 5px;
  }
}

.price__rate-tag {
  clear: left;
  display: inline-block;
  margin-left: 2px;
  padding: 3px 3px 2px;
  font-size: 14px;
  line-height: 13px;
  color: @white;
  border-radius: 2px;
}

// GG-15127 on hold
/*
.value-increased,
.value-decreased {
  .fade-highlight();

  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -12px;
    margin-top: -2px;
    border-right: 4px solid transparent;
    border-bottom: 4px solid @red;
    border-left: 4px solid transparent;
  }
}

.value-decreased {
  &:before {
    border-top: 4px solid @greenDark;
    border-bottom: none;
  }
}
*/

.label-stick {
  margin: -10px 0 0 0;

  span {
    position: relative;
    top: -3px;
    display: inline-block;
    color: @white;
    font-size: 10px;
    line-height: 13px;
    padding: 1px 5px;
    background: #bbb;
  }
}

.label-stick--blue {
  span {
    background: @blue !important;
  }
}

.label-stick--purple {
  span {
    background: @purple !important;
  }
}

.room-details-box{
  .clearfix();
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid @grayBorder;

  &:hover{
    background: #f7fafe;

    .btn {
      .opacity(100);
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .btn {
    .opacity(70);
  }

  .room-mute{
    display: block;
    color: @grayLight;
    text-transform: uppercase;
  }

  .price{
    small{
      font-size: 11px;
    }
  }

  .description{
    h4{
      overflow: hidden;
      max-height: 38px;
      font-size: 13px;
    }
  }

  .item-data {
    display: inline-block;
    position: relative;

    .tooltip-inner {
      min-width: 95px;
    }
  }

  .room-data {
    padding-top: 1px;
    color: @grayLight;
    font-size: 11px;

    .item-data {
      float: left;
      margin-right: 8px;

      [class^=icon-hs] {
        position: relative;
        top: 1px;
      }

      .icon-hs-other {
        top: 3px;
        margin-left: 1px;
      }
    }

    .lnk {
      color: @gray;

      &:hover {
        color: darken(@gray, 15%);
      }
    }

    .rate-details-spinner {
      left: -53px;
    }
  }

  .cancel{
    .dotted-link{
      .bold();
    }

    .highlighted-label{
      display: inline-block;
      color: @white;
      .bold();
      text-transform: uppercase;
      padding: 0 3px;
      margin: 15px 0 0;
      background: #a1140d;
    }
  }

  .nightly-rates-spinner {
    position: absolute;
  }
}

.price__logo {
  margin-top: 9px;
}

.payment__icon {
  float: right;
  margin: -4px 10px 0 0;

  & + & {
    position: absolute;
    right: 0;
    top: 19px;
  }
}

.payment__icon--cbt {
  margin: -1px 10px 0 -2px;
}

.description--flex-rate {
  width: 300px !important;

  h4 {
    margin-bottom: 5px;
    max-height: none !important;
    overflow: visible !important;
    line-height: 1.4;
    font-size: 12px !important;
    color: darken(@gray, 9%);
  }

  .rate-description-heading {
    margin-bottom: 3px;
    font-size: 13px;
    color: @grayDark;
  }

  .room-data {
    padding-top: 4px;
    border-top: 1px solid lighten(@grayBorder, 5%);
  }
}

.item-data--spinner-right {
  .rate-details-spinner {
    float: right;
    position: relative !important;
    top: 11px;
    right: -15px;
    left: auto !important;
  }
}

.rate-description-heading {
  display: block;
  font-weight: bold;
}

.nightly-rates-table{
  width: 100%;

  th{
    .bold();
    text-transform: uppercase;
  }

  th,
  td{
    font-size: 11px;
    color: @white;
    text-align: left;
    font-style: normal;
  }
}

.tooltip-large-wrapper {
  position: relative;
}

.tooltip-large {
  position: absolute;
  bottom: 9px;
  left: -22px;
  width: auto !important;
  padding: 4px;
  color: @white;
  background: #424242;
  z-index: 10;
  .box-sizing(border-box);

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    left: 23px;
    border-top: 5px solid #424242;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.dotted-link{
  border-bottom: 1px dotted @linkColor;

  &:hover{
    border-bottom: 1px dotted @linkColorHover;
  }
}

.rooms-list-wrap{
  margin: 0 0 5px;
}

article.top-level {
  padding: 8px 0 7px;
  border-top: 1px solid @grayBorder;

  &:nth-of-type(1) {
    padding-top: 11px;
    border-top: none;
  }

  .list-header {
    float: left;
    width: 110px;
    padding-right: 4px;
    font-size: 13px;
    color: @gray;
    .bold();
    .box-sizing(border-box);
  }

  .columns-list {
    overflow: hidden;
    max-height: 1000px;
    .transition(max-height linear 0.5s);

    &.collapsed {
      max-height: 79px;
    }
  }

  .col-list {
    float: left;
    width: 260px;
    margin: 0 3px 0 15px;
  }

  .desc {
    margin: 0 3px 2px 135px;

    ul{
      margin: 0 3px 3px 13px;
    }

    p{
      margin: 0 3px 2px 0;
    }
  }

  &.aft .desc {
    margin-left: 110px;
  }

  .reveal-control {
    clear: both;
    margin-left: 100px;
    padding: 2px 0 1px;
    font-size: 12px;
  }
}

.gallery-embedded-thumbs {
  position: relative;
  margin: 10px 0 6px 0;
  overflow: hidden;

  ul {
    .clearfix();
    margin: 0;
    padding: 0;
    height: 72px;
    list-style: none;
    overflow: hidden;
  }

  .control {
    position: absolute;
    top: 14px;
    width: 30px;
    height: 40px;
    background: @white;
    background: rgba(255,255,255,0.7);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255,255,255,0.95);
      box-shadow: 3px 0 5px rgba(0,0,0,0.2);
    }

    &.left {
      left: 1px;
      border-radius: 0 3px 3px 0;
    }

    &.right {
      right: 5px;
      border-radius: 3px 0 0 3px;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
    }
  }

  .slide {
    float: left;
    margin-left: 5px;

    &:first-child {
      margin-left: 1px;
    }

    &.active {
      .thumb {
        border-color: @gray;
      }
    }
  }

  .thumb {
    float: left;
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border: 4px solid #fff;
  }
}

.gallery-embedded-viewport {
  position: relative;
  width: 689px;
  height: 414px;
  margin: 0 0 15px;
  border: 1px solid @grayBorder;
  .box-sizing(border-box);

  .photo {
    width: 689px;
    height: 414px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #222;
  }

  &.no-photo {
    background: @grayLighter url(../img/hotels-room-placeholder-large.png) 50% 50% no-repeat;

    .carousel-control {
      display: none;
    }
  }

  .carousel-control {
    margin-top: 20px;
  }
}

.gallery-embedded-viewport__disclaimer {
  margin: 2px 0 12px;
  font-size: 11px;
  color: @gray;
}

.map-embedded {
  width: 694px;
  height: 414px;
  margin: 15px 0 15px;
  border: 1px solid @grayBorder;
  .box-sizing(border-box);

  .map-container {
    width: 669px;
    height: 414px;
  }
}

/**
 *  CBT Stuff
 */

.item-list-bg {
  .item.rev {
    .price {
      width: 110px;
      margin-right: 3px;
      font-size: 13px;
      white-space: normal;

      .nightly-price{
        display: block;
      }

      .preference__out-of-policy{
        text-align: left;
        margin: 0 0 -5px 0;
      }

      .note {
        margin-top: 2px;
        line-height: 13px;
      }
    }

    .left-col-wrapper {
      float: left;
      width: 113px;

      .preference {
        margin-top: 6px;
        width: 100%;
      }
    }

    .room-details-box{
      .price{
        width: 102px;
      }
    }

    .min-total-price {
      visibility: hidden;
      color: @graySecondary;
      margin: 9px 0 0 0;
    }

    &:hover {
      .min-total-price {
        visibility: visible;
      }
    }

    .total-price {
      visibility: hidden;
      margin: 4px 0 0 0;

      .muted{
        font-size: 10px;
      }
    }

    .room-details-box:hover {
      .total-price {
        visibility: visible;
      }
    }

    .preference {
      width: 30px;
    }

    footer.item-details {
      .btn-tabs {
        margin-left: 122px;

        a {
          font-weight: normal;
        }
      }
    }
  }
}

.price__preference{
  position: absolute;
  top: 0;
  left: -30px;
}

.rate-note {
  line-height: 13px;
  margin-top: 1px;
}

.border-lnk {
  float: right;
  margin: 1px 0 0 0;
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1;
  color: @gray;
  text-align: center;
  border: 1px solid @grayBorder;
  border-radius: 10em;
  background: @white;
  cursor: pointer;
  .user-select(none);

  &:hover {
    color: @grayDark;
  }

  .glyphicon {
    margin-right: 2px;
  }
}

.border-lnk--selected {
  color: @white;
  background: @orange;
  border-color: @orange;

  &:hover {
    color: @white;
  }
}

.float-panel {
  position: fixed;
  left: 50%;
  bottom: 0;
  height: 390px;
  width: 380px;
  margin-left: 310px;
  background: @white;
  border-left: 4px solid @green;
  box-shadow: 0 0 7px rgba(0,0,0,0.15);
  z-index: 50;
  .transition(all 0.3s);
}

.float-panel--wide {
  right: 5%;
  left: auto;
  width: 440px;
}

.float-panel--minimized {
  bottom: -351px;

  .float-panel__header {
    &:after {
      border-top: none;
      border-right: 5px solid transparent;
      border-bottom: 5px solid @gray;
      border-left: 5px solid transparent;
    }
  }
}

.float-panel--maximized {
  width: auto;
  height: auto;
  margin: auto;
  top: 12%;
  right: 12%;
  bottom: 0;
  left: 12%;
  box-shadow: 0 0 40px rgba(0,0,0,0.5);

  .float-panel__body {
    pre {
      margin: 15px;
      padding: 20px;
      font-size: 12px;
    }
  }
}

.float-panel--maximized + .float-panel__bg {
  display: block;
}

.float-panel__bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  z-index: 4;
}

.float-panel__header {
  position: relative;
  height: 29px;
  padding: 10px 13px 0;
  border-bottom: 1px solid @grayBorder;
  cursor: pointer;
  .transition(background 0.3s);

  &:hover {
    background: darken(@white, 5%);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -2px;
    border-top: 5px solid @gray;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.float-panel__header__controls {
  float: right;
  position: relative;
  top: 2px;
  color: @gray;

  .glyphicon {
    margin-left: 3px;
    padding: 2px;
    cursor: pointer;

    &:hover {
      color: @grayDark;
    }
  }
}

.float-panel__header--control-panel {
  background: @white !important;
  cursor: default;

  &:after {
    display: none;
  }
}

.float-panel__title {
  float: left;
  width: 62%;
  margin: 0;
  font-weight: bold;
  font-size: 13px;
}

.float-panel__aside {
  float: left;
  width: 32%;
  margin-top: 1px;
  font-size: 11px;
  color: @gray;
}

.float-panel__body {
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  pre {
    margin: 5px;
    padding: 5px;
    font-size: 10px;
    color: @grayDark;
    border: 1px solid @grayBorder;
    background: @grayBg;
    border-radius: 3px;
  }
}

.float-panel__item {
  .clearfix();
  position: relative;
  margin: 0 13px 5px;
  padding: 7px 13px 8px 0;
  font-size: 11px;
  line-height: 1.35;
  border-bottom: 1px solid @grayBorder;

  &:hover {
    .float-panel__item__close {
      display: block;
    }
  }

  h5 {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.35;
  }
}

.float-panel__message {
  margin: 28% auto 0;
  padding: 0 20px;
  text-align: center;

  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}


/*
.float-panel {
  position: fixed;
  left: 50%;
  bottom: 0;
  height: 330px;
  width: 320px;
  margin-left: 210px;
  background: @white;
  border-left: 4px solid @green;
  box-shadow: 0 0 7px rgba(0,0,0,0.15);
  z-index: 5;
  .transition(bottom 0.3s);
}

.float-panel--closed {
  bottom: -290px;

  .float-panel__header {
    &:after {
      border-top: none;
      border-right: 5px solid transparent;
      border-bottom: 5px solid @gray;
      border-left: 5px solid transparent;
    }
  }
}

.float-panel__header {
  position: relative;
  height: 30px;
  padding: 10px 13px 0;
  border-bottom: 1px solid @grayBorder;
  cursor: pointer;
  .transition(background 0.3s);

  &:hover {
    background: darken(@white, 5%);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -2px;
    border-top: 5px solid @gray;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.float-panel__title {
  float: left;
  width: 62%;
  font-weight: bold;
  font-size: 13px;
}

.float-panel__aside {
  float: left;
  width: 32%;
  margin-top: 1px;
  font-size: 11px;
  color: @gray;
}

.float-panel__body {
  height: 240px;
  overflow-y: auto;
}

.float-panel__body__message {
  position: absolute;
  top: 41px;
  right: 0;
  bottom: 49px;
  left: 0;
  background: rgba(255,255,255,0.68);
  z-index: 5;

  .glyphicon {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    line-height: 40px;
    font-size: 20px;
    color: @white;
  }
}

.float-panel__body__message__wrapper {
  position: relative;
  margin: 58px 0 0;
  padding: 10px 10px 10px 65px;
  font-size: 16px;
  line-height: 1.4;
  color: @white;
  background: @greenDark;
}

.float-panel__item {
  .clearfix();
  position: relative;
  margin: 0 13px 5px;
  padding: 7px 13px 8px 0;
  font-size: 11px;
  line-height: 1.35;
  border-bottom: 1px solid @grayBorder;

  &:hover {
    .float-panel__item__close {
      display: block;
    }
  }

  h5 {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.35;
  }
}

.float-panel__item__price {
  float: left;
  width: 15%;
  font-size: 12px;
}

.float-panel__item__content {
  float: right;
  width: 80%;
}

.float-panel__item__row {
  margin-bottom: 2px;
}

.float-panel__item__small {
  color: darken(@gray, 10%);
  font-size: 11px;
}

.float-panel__item__close {
  display: none;
  position: absolute;
  right: -3px;
  top: 6px;
  padding: 2px;
  color: @red;
  font-size: 9px;
  cursor: pointer;

  &:hover {
    color: darken(@red, 15%);
  }
}

.float-panel__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  padding: 0 13px;
  text-align: right;
  background: @grayBg;
  border-top: 1px solid @grayBorder;

  .btn {
    margin-top: 10px;
    padding: 4px 13px;
    font-size: 12px;
  }
}
*/