@media only screen and (device-width: 768px) {
// MAIN PAGE

  .display-mobile {
    display: block !important;
  }

  .display-inline-mobile {
    display: inline-block !important;
  }

  .modal-window {
    box-shadow: none !important;
  }

  .modal-wrapper {
     -webkit-overflow-scrolling: touch;
  }

  .select-list-block {
    h3, a {
      -webkit-user-select: none;
    }

    li {
      -webkit-user-select: none;

      &:hover {
        background-color: @white !important;

        h3, a {
          color: #333;
        }
      }

      .lnk-edit {
        display: inline-block !important;
      }
    }

    .destination-region-img {
      &:hover {
        background-color: @white !important;
      }
    }
  }

  .destination-item {
    .images-lnk, .details-lnk, .hide-link  {
      display: none !important;
    }
    &:hover {
      .images-lnk, .details-lnk, .hide-link {
        display: none !important;
      }
    }

    &:hover, &.active, &.selected {
      background-color: transparent !important;
    }

    .lnk-details {
      display: block !important;
      float: left !important;
    }

    .checkbox-wrapper {
       margin-top: -2px;
    }
  }

  .destination-item   {
    div {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
  }

  .destination-list-item {
    .item-visibility, .photos-icons-wrap, .photos-icon {
      display: none !important;
    }
  }

  .search-bar {
  position: relative;
  z-index: 15;
    .options {
      .filter-item {
        .filter-slider {
          .ui-slider-handle {
            width: 25px;
            height: 25px;
            top: -11px;
          }
        }
      }
    }
  }

  // modal windows
  .modal-destinations {
    .sidebar {
      position: relative;
      z-index: 5;
      .filter-items {
        &:hover {
          .filter-label-value {
            width: 140px !important;
          }

          .lnk-this {
            display: none !important;
          }
        }
      }
    }
  }

  .modal-window {
    box-shadow: none !important;
  }

  .ui-slider-handle {
    box-shadow: none !important;
    width: 20px !important;
  }
}
