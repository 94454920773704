.container {
  .clearfix();
  margin: 0 auto;
  width: 998px;
}

.error-bg {
  background: #fff url(../img/bg_error.jpg) 50% 0 no-repeat;
  background-size: 1400px;
}

@media (max-width: 1440px) {
  .error-bg {
    background-size: 1300px;
  }
}

@media (max-width: 1280px) {
  .error-bg {
    background-size: 1190px;
  }
}

.gray-bg {
  background: #f5f5f5;
}

.blue-bg {
  background: @blue;
}

.green-bg {
  background: @greenDark;
}

.affix {
  position: fixed;
  z-index: 1;
}

.clear-both {
  clear: both;
}

.clickable {
  cursor: pointer !important;
}

.non-clickable {
  cursor: default !important;
}

.centered-content {
  text-align: center;
}

.align-right {
  text-align: right;
}

.no-wrap {
  white-space: nowrap;
}

.line-through {
  text-decoration: line-through;
}

.main{
  position: relative;
}

.small {
  font-size: 85%;
}

.large {
  font-size: 125%;
}

.relative {
  position: relative;
}

.dark-translucent-box{
  background: #333;
  background: rgba(51,51,51,0.3);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 5px;
}

.secondary,
.note  {
  color: @graySecondary;
}

.hr {
  border-bottom: 1px dotted @graySecondary;
  margin: 15px 0 10px 0;
}

.highlight {
  color: @purple;
}

.alt-highlight,
.highlight-blue {
  color: @blue;
}

.highlight-orange {
  color: @orange;
}

.highlight-green {
  color: @greenDark;
}

.highlight-dark-green {
  color: @greenBrunswick;
}

.highlight-red {
  color: @red;
}

.highlight-dark-red {
  color: #A10005;
}

.dyn-lnk {
  border-bottom: 1px dotted @blue;
}

.centered {
  margin: 0 auto;
}

.heading {
  margin-bottom: 0.14em;
  font-size: 19px;
  .bold();
}

.disabled-item {
  .opacity(50);
  color: @gray;
}

.dropdown--unstyled{
  .dropdown-toggle{
    color: @gray;
    background: none;
    border: none;
  }
}

.long-labels {
  .control-label {
    width: 194px !important;
  }

  .controls {
    margin-left: 205px !important;
  }
}

h3 {
  &.small-header {
    font-size: 16px;
    margin-bottom: 10px;

    span {
      font-weight: normal;
    }
  }

  &.mini-header {
    font-size: 15px;
    margin-bottom: 15px;

    span {
      font-weight: normal;
    }
  }
}

h4 {
  &.small-header {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 1.1;

    span {
      font-weight: normal;
    }
  }

  &.mini-header {
    font-size: 13px;
    margin: 0 0 14px;
  }
}

.error-bottom-message,
.error-message {
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  color: @red;
  text-align: left;

  &.error-message-modal{
    font-size: 13px;
    .bold();
    padding: 0;
    margin: 0 0 9px;
  }
}

.btn-block {
  .error-bottom-message,
  .error-message {
    text-align: center;
  }

  .error-message-expand {
    text-align: left;
  }
}

.highlight-purple {
  color: @purple;
  display: inline !important;
}

.highlight-error {
  color: @red;
  display: inline !important;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 5px;
  color: @white;
  vertical-align: baseline;
  line-height: 13px;
  font-size: 11px;
  .bold();
  white-space: nowrap;
  text-align: center;
  background-color: @grayLight;
  border-radius: 10px;

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }
}

a.badge {
  &:hover,
  &:focus {
    color: @white;
    text-decoration: none;
    cursor: pointer;
  }
}


// CUSTOM ELEMENTS STYLES
// -------------

// Buttons

.btn{
  &.inactive{
    background: #EAEAEA;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: default;
    text-shadow: none;

    &:hover{
      border-color: transparent;
    }

    &:active{
      border-color: transparent;
      box-shadow: none;
    }
  }

  &.huge {
    font-size: 16px;
    padding: 14px 35px;
  }

  &.input-extra-large-rev{
    font-size: 15px;
    padding: 11px 15px;
  }

  &.input-large-rev{
    font-size: 15px;
    padding: 8px 15px;
  }

  &.input-small-rev{
    padding: 3px 15px;
    font-size: 12px;
    line-height: 20px;
  }
}

button.btn{
  &.btn-block{
    width: 100%;
  }

  &.input-extra-large-rev{
    height: 43px;
  }

  &.input-large-rev{
    height: 37px;
  }

  &.input-small-rev{
    height: 28px;
  }
}

a.btn{
  &.btn-block{
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.btn-primary {
  .buttonStyle(@greenDark);
}

.btn-secondary {
  .buttonStyle(@blue);
}

.btn-common {
  .buttonStyle(@grayLight);
}

.btn-danger {
  .buttonStyle(@red);
}

.btn-share-fb,
.btn-share-tw {
  display: inline-block;
  width: 76px;
  height: 32px;
  background: url(../img/sprite_share_alt_2.png) 0 0 no-repeat;
}

.btn-share-tw {
  background-position: -82px 0;
}

// Popover

div.popup-block {
  position: fixed;
  top: -52px;
  width: 100%;
  height: 30px;
  padding: 15px 0 6px 0;
  font-size: 16px;
  color: @white;
  text-align: center;
  background: @black;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.6);

  > span {
    position: relative;
    display: inline-block;
  }
}

.popover-top {
  position: fixed;
  top: -100%;
  width: 100%;
  padding: 3px 0 6px;
  font-size: 16px;
  line-height: 1.55;
  color: @white;
  background: rgba(16,16,16,0.88);
  z-index: 99999;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.6);
  transition: all 0.7s ease;

  &.slide-in{
    top: 0;
  }
}

.popover-top--error {
  background: rgba(146,16,16,0.9);
}

.popover-top__title{
  position: relative;
  display: inline-block;
  font-size: 24px;
  .bold();
  line-height: 50px;
  margin: 0 0 1px;
  height: 50px;
}

.popover-top__icon{
  position: relative;
  top: -2px;
  margin-right: 10px;

  .icon-spinner {
    box-shadow: 0 0 12px rgba(0,0,0,0.5);
  }
}

.popover-top__left-wrapper{
  float: left;
  max-width: 700px;
}

.popover-top__btn{
  float: right;
  margin-top: 28px;
  max-width: 200px;
}

.pnrmod{
  .clearfix();
  float: right;
  height: 49px;
  margin-top: -4px;
  margin-right: 30px;
  padding: 5px 10px 0 18px;
  color: @white;
  white-space: nowrap;
  background: #CE1126;
  .box-sizing(border-box);

  .btn{
    color: @white;
    margin: 4px 0 0 30px;
    background: darken(#CE1126, 20%);
    border: 1px solid darken(#CE1126, 20%);
    .buttonBackground(darken(#CE1126, 14%), darken(#CE1126, 16%));
  }
}

.pnrmod__msg{
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.pnrmod__data{
  font-size: 12px;
}

.pnrmod__item {
  margin-right: 8px;
}

.tooltip.tooltip--sidebar {
  width: 211px;

  &.bottom {
    margin-top: -1px;
  }

  .tooltip-inner {
    padding: 4px 17px 4px 8px;
    line-height: 1.3;
    border-radius: 4px;
  }
}

.tooltip-control {
  position: absolute;
  right: 4px;
  top: 3px;
  cursor: pointer;
}

// Autocomplete dropdown

.ui-autocomplete {
  width: 400px !important;
  font-size: 14px !important;
  border: none !important;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2) !important;
  z-index: 10 !important;

  a {
    .transition(none) !important;
  }

  a span {
    display: block;
    padding: 0 0 0 10px;
    font-size: 12px !important;
    color: lighten(@black, 45%);
  }

  .ui-state-hover {
    .transition(none) !important;

    color: @white !important;
    background: @blue !important;
    border: 1px solid @blue !important;

    span{
      color: @white !important;
    }
  }
}

.page-login {
  .title {
    margin: 0.3em 0 1em 0;
    font-size: 20px;
    line-height: 1.2em;
    .bold();
  }

  .form-container {
    .clearfix();
    position: relative;
    width: 410px;
    margin: 110px auto 10px auto;
    padding: 15px 32px 0;
    background: @white;
    .box-sizing(border-box);

    &.with-sidebar{
      margin-left: 170px;
    }

    input[type="email"],
    input[type="text"],
    input[type="password"],
    .textarea {
      width: 100%;
      .box-sizing(border-box);
    }

    .textarea {
      height: 95px;
      font-size: 12px;
      resize: none;
    }

    .form-bottom {
      margin: 30px -32px 0;
      padding: 10px 32px 10px;
      font-size: 11px;
      color: @gray;
      background: @grayBg;
      border-top: 1px solid @grayBorder;
    }
  }

  .user-actions {
    margin-bottom: 0;
  }

  .control-group-buttons{
    padding: 0 0 30px;

    .button-cancel{
      .box-sizing(border-box);
      width: 135px;
    }

    .button-next{
      .box-sizing(border-box);
      width: 201px;
    }
  }
}

.page-login__wrapper {
  padding: 0 0 25px;
}

.form-add-account + .page-login__wrapper {
  margin-top: 10px;
}

.side-popup {
  display: none;
  position: absolute;
  right: -260px;
  top: 40px;
  width: 260px;
  padding: 15px 10px 10px 25px;
  background: @white;
  background: rgba(255,255,255,0.9);
  box-shadow: 4px 0 9px -6px rgba(0,0,0,0.4) inset;
  .box-sizing(border-box);
  z-index: 5;

  &.form-popup {
    display: none;
    width: 250px;
    margin: 20px 0 0 195px;
    padding: 15px 15px 0 25px;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(0,0,0,0.15),
                         -6px 5px 20px rgba(0,0,0,0.18),
                         0 15px 15px -10px rgba(0,0,0,0.2);

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      top: 20px;
      border-top: 10px solid transparent;
      border-right: 10px solid @white;
      border-bottom: 10px solid transparent;
    }
  }

  h3 {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 1.45;
    .bold();
  }

  ul {
    font-size: 11px;
  }

  li {
    position: relative;

    &:before {
      content: "";
      display: none;
      position: absolute;
      top: 7px;
      left: -9px;
      width: 5px;
      height: 5px;
      border-radius: 10em;
      background: @gray;
    }
  }

  .success {
    &:before {
      display: block;
      background: @greenDark;
    }

    color: @greenDark;
  }

  .error {
    &:before {
      display: block;
      background: @red;
    }

    color: @red;
  }
}

.auth-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 360px;
  padding: 115px 45px 0;
  font-family: @OpenSans;
  background: #fff;
  background: rgba(255,255,255,0.9);
  .box-sizing(border-box);

  h2 {
    font-size: 21px;
    line-height: 1.4;
    font-weight: 600;
  }

  h3 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
  }

  article {
    margin: 20px 0 0 0;
  }

  p {
    color: #999;
    line-height: 1.6;
  }

  .logo {
    display: inline-block;
    width: 125px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
  }

  .logo[style*="logo-dm.png"] { /* Dunder Mifflin logo position adjustment */
    width: 180px;
    height: 43px;
    margin: 5px 0 0 -50px;
  }
}

.switch-block {
  display: inline-block;
  padding: 2px;
  border: 1px solid @white;
  #gradient > .vertical(@grayBg, darken(@grayBg, 15%));
  .reset-filter();
  border-radius: 3px;
  box-shadow: inset 1px 1px 3px rgba(0,0,0,0.2);

  &.fb-switch {
    float: right;
    margin: 4px -51px 0 0;
  }

  a {
    float: left;
    display: block;
    width: 40px;
    padding: 3px;
    text-align: center;
    .bold();
    color: @gray;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.8);
    background: none;
    box-shadow: none;

    &:hover {
      .opacity(80);
    }

    &.active {
      color: @white;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
      border-radius: 3px;
      #gradient > .vertical(@blue, darken(@blue, 15%));
      .reset-filter();
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.4),
                            0 0 2px rgba(0,0,0,0.4);
    }
  }
}

// disabled-js-placeholder start
.disabled-js-placeholder{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1000;
  #translucent > .background(@black,  0.8);
}
  .djsp-textwrapper{
    position:relative;
    top:30%;

    p{
      text-align:center;
    }

    .djsp-largetext{
      margin:0 0 20px 0;
      color:@white;
      #font > .shorthand(22px, bold, @baseLineHeight);
      text-transform:uppercase;
    }

    .djsp-smalltext{
      color:@white;
      font-size:18px;
    }
  }
// disabled-js-placeholder end

.has-browser-message{
  height: 100%;
  min-width: 660px;
  background-color: #f1f1f1;

  .browser-support-review-container{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -215px 0 0 -330px;
    border: 1px solid #ccc;
  }
}

// browser-support-review start
.browser-support-review-container{
  width: 660px;
  height: 365px;
  text-align: center;
  background:#f7f7f7;
  overflow: hidden;
}

.browser-support-review{
  height: 210px;
  background-color: @white;
  padding-top: 55px;
  .box-shadow(rgba(0, 0, 0, .04) 0 4px 7px );

  .sbrev-largetext{
    margin:0 0 30px 0;
    color:@grayDark;
    #font > .shorthand(36px, normal, @baseLineHeight);
    font-family: @OpenSans;
  }

  .sbrev-smalltext{
    margin:0 0 55px;
    color:@grayLight;
    font-size:19px;
    line-height: 125%;
  }
}

  .old-browsers-review {
    .container-fixed;

    a{
      display:inline-block;
      width:94px;
      padding:105px 0 0 0;
      margin-left:50px;
      text-align:center;
      font-weight: 700;
      text-decoration: none;
      font-size:16px;
      background-image:url(../img/old-browsers-review.png);
      background-repeat:no-repeat;

      &:hover{text-decoration:none;}

      &:first-child{margin-left:0;}

      &.obr1{background-position:0 0;}
      &.obr2{background-position:-167px 0;}
      &.obr3{margin-left: 35px; width: 135px; background-position:-494px 0;}
      &.obr4{padding-top: 113px; background-position:-343px 0;}
    }
  }
// browser-support-review end

.autocomplete-rev {
  .select2-results {
    max-height: 484px;
    margin-right: 0;
    padding: 0;
  }

  ul {
    li {
      position: relative;
      padding: 3px 8px;
      margin: 0 0 1px 0;

      &.select2-highlighted {
        color: @white;

        .title {
          color: @white;
        }
      }

      &.select2-result-unselectable{
        margin: 3px -8px 0 -8px;
      }
    }

    .title{
      position: relative;
      color: @grayDark;
      text-align: left;
      font-size: 13px;
      line-height: 26px;
      padding-left: 33px;
      background: #dddddd;

      &:after{
        content: "";
        display: inline-block;
        position: absolute;
        background-image: url(../img/sprite_main.png);
        background-repeat: no-repeat;
      }
    }

    .value {
      display: inline-block;
      width: auto;
      vertical-align: top;
    }
  }
}

.popover-inner {
  width: 400px;
  background-color: transparent;
  padding: 0;


  h4 {
    .bold();
    margin-bottom: 2px;
    font-size: 13px;
  }

  .popover-content{
    font-size: 11px;
  }
}

// Greater priority button styles

// Button Sizes
// --------------------------------------------------

// Large
.btn-large {
  padding: 9px 14px;
  font-size: @baseFontSize + 2px;
  line-height: normal;
  .border-radius(5px);
}
.btn-large [class^="icon-"] {
  margin-top: 1px;
}

// Small
.btn-small {
  padding: 5px 9px;
  font-size: @baseFontSize - 2px;
  line-height: @baseLineHeight - 2px;
}
.btn-small [class^="icon-"] {
  margin-top: -1px;
}

.btn-small-rev {
  padding: 3px 9px;
  font-size: @baseFontSize - 2px;
  line-height: @baseLineHeight - 2px;
}

// Mini
.btn-mini {
  padding: 2px 6px;
  font-size: @baseFontSize - 2px;
  line-height: @baseLineHeight - 4px;
  font-weight: normal;
}

// GG-15127 for future use
/*
@-webkit-keyframes yellow-highlight {
   0% { background: #f9f100; }
   100% { background: none; }
}

@keyframes yellow-highlight {
   0% { background: #f9f100; }
   100% { background: none; }
}

.fade-highlight {
   -webkit-animation: yellow-highlight 2s ease-in 1;
   animation: yellow-highlight 2s ease-in 1;
}
*/