// Custom forms

.input-block {
  width: 100%;
}

.form-add-account{
  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea{
    width: 293px;
    margin: 0;
  }

  .controls{
    position: relative;

    .btn{
      margin-top: 12px;
    }
  }

  .error-bottom-message{
    margin: 20px 0 0 0 !important;
  }

  .legal-block{
    font-size: 11px;
    text-align: left;
    margin: 25px 0 -25px 0;
  }
}

.form-horizontal {
  .form-block {
    margin-bottom: 50px;
  }

  .control-label {
    width: 190px;
  }

  .control-group{
    margin-bottom: 14px;

    .btn-common{
      width: 149px;
    }

    &.multi-row-wrapper{
      .control-label{
        display: table-cell;
        float: none;
        vertical-align: middle;
        line-height: 16px;
        padding-right: 20px;
        padding-top: 0;
      }

      .controls{
        display: table-cell;
        float: none;
      }
    }
  }

  .controls {
    position: relative;
    margin-left: 210px;

    span {
      .dyn-lnk {
        margin-left: 5px;
        font-size: 11px !important;
      }
    }
  }

  .form-control {
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: 1px dotted @grayBorder;

    .btn {
      margin-right: 20px;
    }
  }
}

.form-horizontal.rev {
  margin: 0;

  .controls {
    margin-left: 195px;

    input {
      margin-right: 5px;
    }
  }

  .control-group {
    height: 31px;
  }

  .control-label {
    .vertical-align();
    width: 185px;
    text-align: left;
    padding: 0;
    margin: -1px 0 0 0;
  }

  .form-action {
    margin: 40px -30px 0;
    padding: 20px 30px;
    border-top: 1px solid @grayBorder;

    .btn {
      margin-right: 10px;
    }
  }
}

.form-horizontal-alt {
  .form-block {
    margin-bottom: 50px;
  }

  .control-label {
    float: left;
    text-align: left;
    color: @grayLight;
    height: 31px;

    .vertical-align{
      display: inline-block;
    }
  }

  .control-group{
    margin-bottom: 14px;

    .btn-common{
      width: 149px;
    }
  }

  .controls {
    position: relative;

    span {
      .dyn-lnk {
        margin-left: 5px;
        font-size: 11px !important;
      }
    }
  }

  .form-control {
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: 1px dotted @grayBorder;

    .btn {
      margin-right: 20px;
    }
  }

  sup {
    display: block;
    top: 10px;
    color: @grayLight;
  }

  hr{
    margin: 10px 0;
  }

  .checkout-control {
    .error-bottom-message {
      margin: 0 0 9.5px;
    }
  }
}

.input-xlarge {
  width: 213px;
}

.input-sm {
  width: 138px;
}

.input-xs {
  width: 107px;
  min-width: 107px;
}

fieldset {
  &.with-background {
    margin-bottom: 20px;
    padding: 14px 0 5px 0;
    background: @grayBg;

    .border-radius(4px);
  }
}

.form-control {
  &.centered {
    text-align: center;

    .error-bottom-message{
      text-align: center;
    }
  }
}

.error-top-message {
  margin-bottom: 15px;
  color: @red;
  font-weight: bold;

  &:empty {
    display: none;
  }
}


// Validation styles

input,
textarea {
  &.validation-error {
    border: 1px solid @red !important;
  }
}

.selectBox {
  &.validation-error {
    border: 1px solid @red !important;
  }
}

.validation-success {
  #gradient > .vertical(#f0f9e1, #fcfef9);
  border: 1px solid @green !important;
}

.validation-indicator {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 5px;
  width: 13px;
  height: 12px;

  &.success {
    background: url(../img/sprite_main.png) -240px -230px;
  }

  &.error {
    background: url(../img/sprite_main.png) -260px -230px;
  }
}


//.select-list-block
.select-list-block {
  position: absolute;
  left: 0;
  margin: 0 0 0 1px;
  width: 550px;
  padding: 10px 0 5px 10px;
  background: @white;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 10;
  border-radius: 3px;

  .block-header {
    .clearfix();
    padding: 0 0 12px 0;

    span {
      float: left;
      display: block;
      width: 270px;
      color: lighten(@grayDark, 20%);
      font-size: 12px;
      .bold();
    }
  }

  ul {
    float: left;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 270px;
  }

  li {
    .border-radius(3px);

    position: relative;
    width: 260px;
    height: 30px;
    margin: 0 10px 2px 0;
    padding: 1px 4px 1px 1px;
    color: #333333;
    cursor: pointer;

    &:hover,
    &.selected {
      background: @blue;

       h3, a {
        .transition(none);
        color: @white;
      }

      .btn {
        display: inline-block;
        color: @grayDark;
      }
    }

    img {
      .border-radius(3px);
      display: block;
      float: left;
    }

    h3 {
      .text-overflow();
      width: 180px;
      margin-top: 1px;
      margin-left: 40px;
      line-height: 28px;
      font-size: 13px;
      .bold();

      .subtitle {
        display: none;
      }

      &.subtitled {
        height: 30px;
        line-height: 15px;

        span {
          .text-overflow();

          display: block;
          margin-top: -1px;
          font-size: 9px;
          font-weight: normal;
        }
      }
    }

    .lnk-edit {
      position: absolute;
      top: 3px;
      right: 3px;
      display: none;
      float: right;
      margin: 4px 5px 0 0;
      font-weight: normal;
      border: none;
    }
  }
}

.form-block-error {
  .border-radius(3px);
  display: inline-block;
  position: absolute;
  top: 0;
  left: -218px;
  color: @white;
  .bold();
  min-width: 200px;
  width: 200px;
  padding: 8px 5px;
  background: @red;
  z-index: 10;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 13px;
    right: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid @red;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  span{
    display: table-cell;
    width: 200px;
    height: 21px;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
    text-align: center;
  }
}

.error{
  .form-block-error{
    display: block;
  }
}


// Custom inputs

.custom-radio,
.custom-checkbox {
  position: relative;
  display: inline-block;
  min-height: 18px;
  padding-left: 21px;
  cursor: pointer;
  background: url(../img/sprite_custom_controls.png) 0 0 no-repeat;
}

.custom-radio {
    background-position: 0 0;
    padding-left: 23px;

  &.selected {
      background-position: 0 -40px;
  }
}

.custom-checkbox {
    background-position: 0 -80px;

  &.selected {
      background-position: 0 -120px;
  }
}


.select-list-dropdown {
  display: none;
  position: absolute;
  padding: 0 10px 10px;
  background: @white;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.3);
  .box-sizing(border-box);

  .row {
    .clearfix();
    margin-top: 10px;
  }

  .column{
    position: relative;
    float: left;
    margin-left: 10px;

    &:first-child{
      margin-left: 0;
    }
  }

  .column-children{
    float: left;

    label{
      margin-left: 10px;
    }
  }

  .custom-select {
    margin-right: 0;
  }

  .section {
    margin: 10px 0;
    border-top: 1px solid @grayBorder;
  }

  &.passengers{
    .row{
      .clearfix();
      width: 180px;
    }

    label{
      float: left;
      line-height: 30px;
      width: 130px;
    }

    .selectbox-size-micro{
      float: right;
    }
  }
}

.input-size-normal {
  width: 224px;
}

.input-size-small {
  width: 140px;
}

.input-size-xsmall {
  width: 98px;
}

.input-size-micro {
  width: 14px;
}

.selectbox-size-xtra {
  width: 326px;
}

.selectbox-size-small {
  width: 242px;
}

.selectbox-size-xsmall {
 width: 158px;
}

.selectbox-size-xmini {
  width: 85px;
}

.selectbox-size-mini {
  width: 74px;
}

.selectbox-size-micro {
  width: 41px;
}

.main-row-label{
  display: inline-block;
  .bold();
  text-align: right;
  margin-top: 29px;

  .close{
    position: relative;
    left: -1px;
    margin: 0 5px;
  }
}

.main-home-form,
.search-results-container {
  .input-selector {
    position: relative;

    &:focus {
      outline: none;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 8px;
      top: 16px;
      width: 0;
      height: 0;
      border-top: 6px solid @gray;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    select {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .input-placeholder {
      color: @grayLight;
    }

    &.input-selector-focus {
      .selector-value {
        border-color: #5FC5FF;
        box-shadow: 0 0 0 1px #5FC5FF;
      }
    }
  }

  .selector-value {
    display: block;
    height: 36px;
    line-height: 35px;
    padding: 0 10px;
    margin: 0;
    background: @white;
    border: 1px solid #ddd;
    border-radius: 3px;
    .box-sizing(border-box);
    .user-select(none);
    cursor: pointer;
  }

  input[type="text"],
  input[type="date"] {
    height: 36px;
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    .box-sizing(border-box);
  }

  .embedded-icon {
    position: relative;

    i {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }

  input[type="text"],
  input[type="date"],
  .selector-value  {
    &:focus,
    &.focus {
      border-color: #5FC5FF;
      box-shadow: 0 0 0 1px #5FC5FF;
    }

    &.error {
      background-color: #ffe6e5;
      border-color: #f14848;
      box-shadow: 0 0 0 1px #f14848;
      .placeholder(#f14848);
    }
  }
}

.error {
  .input-placeholder {
    color: #f14848 !important;
  }
}

.input-group {
  input {
    float: left;
    border-radius: 3px 0 0 3px;
  }

  .btn {
    margin-right: -1px;
    border-radius: 0 3px 3px 0;
  }
}

.common-checkbox-label--icon {
  display: block;
  margin: 0 0 0 1px;
  padding-left: 20px;

  .preference-icon--shifted {
    margin-left: -20px;
    padding-right: 2px;
  }
}

.common-checkbox-label--trail-icon {
  .preference-icon--shifted {
    margin-right: -4px;
    margin-left: 1px;
  }
}

.controls-static {
  padding-top: 5px;
}