// BUTTON STYLES
// -------------


// Base styles
// --------------------------------------------------

// Core
.btn {
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 0; // For input.btn
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @grayDark;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  .buttonBackground(@btnBackground, @btnBackgroundHighlight);
  border: 1px solid @btnBorder;
  .border-radius(3px);
  .bold();
}

// Hover state
.btn:hover {
  color: @grayDark;
  text-decoration: none;
  background-color: darken(@white, 10%);
}

// Focus state for keyboard and accessibility
.btn:focus {
  .tab-focus();
}

// Active state
.btn.active,
.btn:active {
  background-color: darken(@white, 10%);
  background-image: none;
  outline: 0;
  //.box-shadow(~"inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05)");
}

// Disabled state
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-color: darken(@white, 10%);
  background-image: none;
  .opacity(65);
  .box-shadow(none);
  box-shadow: none !important;
}


// Alternate buttons
// --------------------------------------------------

// Set text color
// -------------------------
.btn-primary,
.btn-primary:hover,
.btn-secondary,
.btn-secondary:hover,
.btn-common,
.btn-common:hover
.btn-warning,
.btn-warning:hover,
.btn-danger,
.btn-danger:hover,
.btn-success,
.btn-success:hover,
.btn-info,
.btn-info:hover{
  color: @white;
  //text-shadow: 0 -1px 0 rgba(0,0,0,.15);
}
// Provide *some* extra contrast for those who can get it
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active {
  color: rgba(255,255,255,.75);
}

// Set the backgrounds
// -------------------------

.btn-primary {
  .buttonBackground(@btnPrimaryBackground, @btnPrimaryBackgroundHighlight);
}
// Warning appears are orange
.btn-warning {
  .buttonBackground(@btnWarningBackground, @btnWarningBackgroundHighlight);
}
// Danger and error appear as red
.btn-danger {
  .buttonBackground(@btnDangerBackground, @btnDangerBackgroundHighlight);
}
// Success appears as green
.btn-success {
  .buttonBackground(@btnSuccessBackground, @btnSuccessBackgroundHighlight);
}
// Info appears as a neutral blue
.btn-info {
  .buttonBackground(@btnInfoBackground, @btnInfoBackgroundHighlight);
}


.btn-purple {
  color: @white;
  .buttonBackground(lighten(@purple, 5%), @purple);
  text-shadow: 1px 1px 0 rgba(0,0,0,0.2);

  &:hover {
    color: @white;
  }
}


// Cross-browser Jank
// --------------------------------------------------

button.btn,
input[type="submit"].btn {

  // Firefox 3.6 only I believe
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}


.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background: none;
  border: none;
  .box-shadow(none);
}
.btn-link {
  background: none;
  cursor: pointer;
  color: @linkColor;
  .border-radius(0);

  &.highlight-red:hover {
    color: darken(@red, 15%);
  }
}
.btn-link:hover {
  color: @linkColorHover;
  background: none;
}
.btn-link[disabled]:hover {
  color: @grayDark;
  text-decoration: none;
}

.btn-group {
  .btn {
    float: left;
    margin-right: -1px;
    border-radius: 0;
    font-weight: normal;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.active {
      color: @blue;
      border-color: #d6d6d6 !important;
      .buttonBackground(@btnBackgroundHighlight, @btnBackground);
    }
  }
}

.btn-flat {
  padding: 3px 9px;
  color: lighten(@grayDark, 4%);
  font-size: 12px;
  line-height: 1.5;
  background: darken(@grayBg, 5%);
  border-radius: 4px;

  .glyphicon {
    margin-left: 3px;
  }

  &:hover {
    color: @grayDark;
    background: darken(@grayBg, 10%);
  }
}

.btn__spinner {
  .loading-spinner();

  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-left: -13px;
  margin-top: -13px;
  border-width: 4px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.btn--progress,
.btn--progress-disabled[disabled] {
  position: relative;
  pointer-events: none;

  .btn__text {
    visibility: hidden;
  }

  .btn__spinner {
    display: block;
  }
}

