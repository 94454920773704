// ALERT STYLES
// ------------

// Base alert styles
.alert {
  padding: 10px 35px 10px 14px;
  margin-bottom: @baseLineHeight;
  background-color: @grayBg;
  border: 1px solid darken(@grayBg, 5%);
  .border-radius(2px);
  color: @orange;

  .secondary-link {
    color: @grayDark;
    text-decoration: underline;
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: #207bd9 !important;
}

.alert__title {
  margin: 0 0 4px !important;
  font-size: 18px !important;
  font-weight: normal !important;

  .glyphicons,
  .glyphicon {
    margin-right: 4px;
    font-size: 15px;
  }
}

.alert__text {
  padding: 3px 32px 0 19px;
}

.alert__message {
  display: inline-block;
  margin: 5px 0 6px;
  color: @grayDark;

  p:last-child {
    margin-bottom: 0;
  }
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 18px;
}

// Alternate styles
// ----------------

.alert-success {
  background-color: @successBackground;
  border-color: @successBorder;
  color: @successText;
}
.alert-danger,
.alert-error {
  background-color: @errorBackground;
  border-color: @errorBorder;
  color: @errorText;
}
.alert-info {
  background-color: @infoBackground;
  border-color: @infoBorder;
  color: @infoText;
}
.alert-warning {
  background-color: #fffbf2;
  border-color: #ffe7b5;
  color: #FFAA00;
}

// Block alerts
// ------------------------
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

