// Home Page

.home-bg {
  background: #333;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.compact-selector-selectBox-dropdown-menu {
  max-height: 155px;
  z-index: 100;

  li {
    a {
      line-height: 1.3;
      padding-top: 1px;
      padding-left: 8px;
      padding-bottom: 1px;
    }
  }
}

#ui-datepicker-div {
  margin: 3px 0 0 1px;
}

.form-block-error {
    display: none;
}


// Home page ver.9000

.full-bg-home {
  position: relative;
  min-height: 457px;
  margin-top: -15px;
  background: #b4764b url(../img/bg_agents.jpg) 50% 29% no-repeat;
  background-size: cover;

  .container {
    position: relative;
  }
}

.main-home-form {
  margin-top: 85px;
  margin-bottom: 35px;

  .input-sections {
    position: relative;
    display: table;
    width: 102%;
    margin: 20px 0 0 -5px;
  }

  .label {
    margin-bottom: 2px;
    color: #999;
    font-size: 12px;
    .bold();
  }

  .input-item {
    float: left;
    position: relative;
    margin-left: 5px;
    vertical-align: top;

    &.hide {
      display: none;
    }

    .lnk-trigger {
      position: absolute;
      right: 8px;
      bottom: 8px;

      a {
        display: block;
        padding-right: 14px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 3px;
          top: 5px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid @blue;
        }
      }
    }

    .note {
      position: absolute;
      top: -24px;
    }

    div.transparent-selectbox {
      position: absolute;
      top: -40px;
      left: 5px;
      width: 130px;
      border-radius: 2px;
      background: rgba(0,0,0,0.3);
      .box-sizing(border-box);
    }

    .dyn-lnk {
      position: relative;
      top: 27px;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .callout-block {
    position: absolute;
    bottom: -26px;
    color: @white;
  }

  .micro-size {
    width: 74px;
  }

  .small-size {
    width: 111px;
  }

  .normal-size {
    width: 191px;
  }

  .normal-size-rev {
    width: 285px
  }

  .large-size {
    width: 368px;

    &.expanded {
      width: 484px;
    }
  }

  .large-size-rev {
    width: 423px;
  }

  .extra-large-size {
    width: 375px;

    &.error ~ .lnk-distance {
      .selector-value{
        background: #ffe6e5;
      }
    }
  }

  .controls {
    top: 21px;

    .btn {
      height: 36px;
      width: 117px;
      line-height: 35px;
      padding: 0;
      font-size: 15px;
      .box-sizing(border-box);
    }
  }

  .bottom-control {
    float: right;
    margin-top: 7px;
    margin-right: 17px;
  }

  .section-trigger {
    position: relative;
    float: right;
    .bold();
    color: @white;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -13px;
      top: 8px;
      width: 0;
      height: 0;
      border-top: 6px solid @white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.input-sections--border {
  margin-top: 18px !important;
  padding-bottom: 20px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 925px;
    height: 1px;
    background: #ddd;
  }

  & + .input-sections {
    margin-top: 17px;
  }
}

.transparent-selectbox {
  position: relative;
  height: 31px;
  margin-bottom: 8px;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 8px;
    top: 13px;
    width: 0;
    height: 0;
    border-top: 5px solid @white;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  .value {
    position: relative;
    padding-left: 30px;
    line-height: 31px;
    .bold();
    color: @white;

    &.round-trip {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 6px;
        width: 16px;
        height: 14px;
        background: url(../img/sprite_search_form.png) 0 0 no-repeat;
      }
    }

    &.one-way {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 16px;
        height: 14px;
        background: url(../img/sprite_search_form.png) 0 -20px no-repeat;
      }
    }
  }

  select {
    width: 100%;
    z-index: 3;
  }
}

.error-tooltip {
  position: absolute;
  z-index: 1;
  padding: 3px 5px;
  color: @white;
  font-size: 11px;
  background: #f14848;
  border-radius: 3px;
  .box-sizing(border-box);

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 8px;
    bottom: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid #f14848;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  &.bottom{
    &:after{
      bottom: auto;
      top: -6px;
      border-top: none;
      border-bottom: 6px solid #f14848;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}

.home-destinations-list {
  margin-bottom: -80px;
  padding-top: 40px;
  background: @grayLighter;

  .row{
    .item{
      float: left;
      width: 322px;
      margin: 0 16px 35px 0;
      font-size: 16px;
      color: @grayDark;
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0 1px 1px;

      .thumb{
        width: 322px;
        height: 202px;
      }

      .label{
        display: block;
        padding: 0 15px;
        line-height: 40px;
        background-color: @white;
      }

      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.home-row {
  margin: 0 0 15px -16px;
  .clearfix();
}

.recent-item {
  float: left;
  width: 322px;
  height: 78px;
  margin: 0 0 15px 16px;
  padding: 6px;
  background: @white;
  border: 1px solid @grayBorder;
  .box-sizing(border-box);

  .left-col {
    float: left;
    width: 115px;

    .img {
      display: block;
      width: 110px;
      height: 64px;
      background-position: center;
      background-size: cover;
    }
  }

  .right-col {
    float: left;
    margin-left: 5px;

    .title {
      display: inline-block;
      max-width: 187px;
      margin-top: 3px;
      line-height: 1.1;
      .bold();
      .text-overflow();
    }

    .rating-common {
      margin: 0 0 1px;
    }

    .location {
      .text-overflow();
      width: 187px;
    }
  }
}

.recent-item,
.upcoming-item {
  &.empty {
    background: none;
    border: 1px dashed @grayBorder;
  }
}

.upcoming-item {
  float: left;
  width: 491px;
  height: 107px;
  margin: 0 0 15px 16px;
  padding: 6px;
  background: @white;
  border: 1px solid @grayBorder;
  .box-sizing(border-box);

  .header {
    border-bottom: 1px solid @grayBorder;
    margin: -1px -6px 0;
    padding: 0 6px 4px;
    font-size: 12px;

    .loc {
      float: left;
      width: 315px;
      .text-overflow();
      .bold();
    }
  }

  .content {
    margin: 6px 0 0;

    .left-col {
      float: left;
      width: 115px;

      .img {
        display: block;
        width: 110px;
        height: 64px;
        background-position: center;
        background-size: cover;
      }
    }

    .right-col {
      float: left;
      margin-left: 5px;
    }

    .title {
      display: inline-block;
      max-width: 355px;
      margin-top: 3px;
      line-height: 1.1;
      .bold();
      .text-overflow();
    }

    .details {
      width: 355px;
      font-size: 12px;
    }

    .info {
      float: left;
      width: 275px;

      div {
        .text-overflow();
        width: 275px;
      }
    }

    .price {
      float: right;
      text-align: right;
    }
  }
}


.home-form-wrapper {
  padding: 25px 36px 42px;
  background: @white;
  background: rgba(255,255,255,0.9);
  .box-sizing(border-box);

  > header {
    position: relative;
    padding-bottom: 23px;
    font-family: @OpenSans;
    border-bottom: 1px solid #ddd;

    h2 {
      margin-bottom: 5px;
      font-size: 32px;
    }

    h4 {
      font-size: 16px;
      color: @grayLight;
    }
  }

  .logo {
    position: absolute;
    right: 0;
    top: 1px;
    width: 180px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.home-form-wrapper--travelers {
  margin-top: -36px;
}

.home-message {
  height: 70px;
  line-height: 68px;
  font-size: 18px;
  font-family: @OpenSans;
  color: @white;
  background: #3F175E;

  img {
    margin: -2px 5px 0 0;
  }

  .lang-small {
    font-size: 80%;
  }
}

.home-bottom-content {
  margin: 38px 0 15px;
  font-family: @OpenSans;

  h4 {
    font-size: 19px;
    margin-bottom: 17px;
    font-weight: 600;
  }
}

.adsbygoogle__home {
  display: block;
  width: 728px;
  height: 90px;
  margin: 40px auto 10px;

  &:empty {
    display: none;
  }
}


.home-info-item {
  float: left;
  position: relative;
  width: 326px;
  height: 288px;
  margin: 0 10px 10px 0;
  background: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  .box-sizing(border-box);
  .opacity(100);

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    .photo {
      position: relative;
      width: 326px;
      height: 202px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      z-index: 2;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 40px;
        z-index: 5;
        background: url(../img/sprite_hotels.png) 0 -210px repeat-x;
        .opacity(0);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      .icon-close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        -moz-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px);
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;

        &:hover {
          .opacity(70);
        }
      }

      .price {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 7px 10px 9px 12px;
        color: @white;
        font-size: 21px;
        font-weight: 600;
        background: rgba(0,0,0,0.7);
      }
    }

  > footer {
    padding: 11px 14px 8px;
    border: 1px solid #ddd;
    border-top: none;

    .row {
      margin-bottom: 3px;
    }

    .title {
      width: 295px;
      margin-bottom: 4px;
      .text-overflow();
      font-size: 16px;
    }

    .location {
      float: left;
      display: inline-block;
      width: 295px;
      color: #999;
      .text-overflow();
    }

    .rating {
      float: left;
    }

    .date {
      float: right;
      font-size: 12px;
      color: #999;
    }
  }
}

.home-info-item {
  &:hover {
    .photo:after {
      .opacity(100);
    }

    .icon-close {
      -moz-transform: translateY(0);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

.recent-hotels-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 202px;
  width: 326px;
  background: #eee url(../img/hotel_unavailable.png) 50% 50% no-repeat;
}

.home-quick-links {
  .item {
    float: left;
    display: block;
    position: relative;
    width: 326px;
    height: 275px;
    margin-right: 10px;
    color: #fff;
    text-align: center;
    font-family: @OpenSans;
    background-repeat: no-repeat;
    background-position: 0 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(2) {
      background-position: -326px 0;
    }

    &:nth-child(3) {
      background-position: (-326px * 2) 0;
    }
  }

  a.item {
    &:hover {
      .opacity(80);
    }
  }

  .title {
    display: block;
    margin: 120px 0 0;
    padding: 0 10px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
  }

  .phone {
    display: block;
    margin-top: 15px;
    font-size: 28px;
  }

  .time {
    display: block;
    margin-top: 15px;
    font-size: 14px;
  }

  .bull {
    margin: 0 5px;
  }

  .q-btn {
    display: inline-block;
    height: 42px;
    margin-top: 15px;
    line-height: 38px;
    padding: 0 20px;
    font-size: 16px;
    border: 1px solid #fff;
    border-radius: 10em;
    .box-sizing(border-box);
  }

  .num {
    position: absolute;
    left: 0;
    top: 78px;
    width: 100%;
    font-weight: 700;
    font-size: 42px;
  }
}

.input-item--narrow {
  .normal-size {
    .text-overflow();
    width: 148px;
    padding-right: 13px;
  }
}

.recent-hotels-container{
  .clearfix();
  min-height: 288px;
  background: url(../img/hotel_unavailable-alt.png) repeat 0 0;
}

.pnr-choose-email .email-name {
  display: none;
}

.pnr-choose-email .checked .email-name {
  display: block;
}

.form-warning {
  float: right;
  width: 390px;
  margin-right: 3px;
  padding: 4px 9px;
  line-height: 1.45;
  font-size: 12px;
  color: #9b5b2b;
  background: #ffe9af;
  border: 1px solid #ffcfa0;
  border-radius: 3px;
}

.form-warning--cbt {
  width: 403px;
  margin: 5px 136px -12px 0;
}
