// Custom styles for Internet Explorer
// -------------------------------------------------

.lt-ie9 {

}

.ie-9 {

}

.lt-ie10 {
  .modal-destinations {
    .modal-control {
      .close-btn {
        i {
          margin-top: -3px !important;
        }
      }
    }

     .footer {
      .flight-class {
        i {
          margin-top: 2px !important;
        }
      }
    }
  }


  .inline-label {
    .checkbox {
      padding-left: 0 !important;
    }
  }

  .placeholder {
    color: #999;
  }

  .flight-info-table{
    .flight_filter{
      margin-top: -3px !important;
    }
  }

  input[type="text"] {

    &.input-extra-large2-rev{
      line-height: 41px !important;
    }
  }

  .business {
    .slider-item {
      .ui-slider-handle {
        background-image: none;
      }
    }
  }
}
