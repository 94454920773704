//
// Dropdown menus
// --------------------------------------------------


// The dropdown wrapper (div)
.dropdown {
  position: relative;

  .btn {
    font-weight: normal;
  }
}

.dropdown-toggle {
  text-align: left;
}

.dropdown-toggle--fixed {
  min-width: 160px;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 100px;
  padding: 4px 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: 13px;
  background-color: @white;
  border: 1px solid @grayBorder;
  .box-shadow(0 3px 7px -1px rgba(0, 0, 0, 0.4));
  background-clip: padding-box;

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    color: @grayDark;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

.dropdown-xs{
  .dropdown-menu {
    font-size: 12px;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @white;
    text-decoration: none;
    outline: 0;
    background-color: @blue;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: @gray;
  }
}
// Nuke hover/focus effects
.dropdown-menu > .disabled > a {
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    .reset-filter();
    cursor: not-allowed;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}


// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
