// CUSTOM MODALS


.modal-background {
  .opacity(70);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 999;
}

.with-overlay {
  overflow: hidden;
}

.with-overlay:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: @black;
    z-index: 999;
    .opacity(80);
}

.overlay-light:after {
    background: @white;
    .opacity(90);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 1000;

  &.mute {
    z-index: 100;
  }
}

.modal-wrapper.small-modal {
  .modal-window {
    width: 500px;
    margin-left: -250px;
    padding-bottom: 25px;
  }
}

.modal-window {
  .box-shadow(0 0 20px rgba(0,0,0,0.2));

  position: absolute;
  top: 180px;
  left: 50%;
  width: 820px - 30 * 2;
  margin-left: -820px / 2;
  padding: 25px 30px 40px 30px;
  background: #fff;
  z-index: 1001;

  .modal-close {
    position: absolute;
    right: 31px;
    top: 26px;
    opacity: 0.4;
    cursor: pointer;
    z-index: 10;

    &:hover {
      opacity: 1;
    }

    i {
      position: relative;
      top: 2px;
    }
  }

  .bottom-wrapper {
    padding: 10px 0;
    text-align: center;

    a {
      cursor: pointer;
    }
 }
}

.modal-window {
  .bottom-control {
    float: left;
    padding-top: 20px;
    width: 110%;

    a {
      margin-right: 10px;
    }
  }

  hr + .bottom-control {
    padding-top: 5px;
  }
}

// Modal info

.modal-info {
  position: fixed;
  top: 50%;
  margin-top: -80px;
  padding: 25px 30px;

  .btn{
    .btn.input-large-rev;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 1.4;
  }

  &.horizontal {
    h2, p {
      float: left;
      width: 615px;
    }

    .wrapper {
      display: inline-block;
      width: 360px;
    }

    .modal-control {
      float: right;
      padding-top: 5px;
    }
  }

  &.narrow {
    width: 520px;
    margin-left: -520px / 2;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.32);

    p {
      width: 385px;
      position: relative;

      .btn {
        position: absolute;
        right: -150px;
        top: 50%;
        margin-top: -19px;
      }
    }
  }

  &.mini {
    top: 40%;
    width: 338px;
    margin-left: -338px / 2;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.32);

    p {
      width: 100%;
      margin: 0 0 9.5px;
    }

    .expand-link{
      font-size: 13px;
    }

    .btn-double{
      width: 130px;
    }

    .modal-footer{
      text-align: center;
      padding-top: 7px;
    }
  }
}

.modal-message {
  span {
    display: block;
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
  }
}

//Accout modal
.modal-account{
  top: 100px;
  padding: 0;

  .modal-header{
    color: @white;
    padding: 25px 0;
    background: #1457a0;

    .modal-title{
      display: block;
      text-align: center;
      line-height: 40px;
      font-size: 29px;
      font-family: @OpenSans;
    }
  }

  .modal-body{
    padding: 28px 40px 10px;
  }

  .modal-footer{
    padding: 16px 40px;
    background: #eeeeee;
  }
}

.modal-account-close,
.modal-general-close {
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}

//Promo modal
.modal-promo{
  top: 100px;
  padding: 0;
  margin-left: -466px/2;
  width: 466px;

  .modal-header{
    color: @white;
    padding: 10px 40px;
    background: #3f175e;

    .modal-title{
      display: block;
      line-height: 50px;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .modal-body{
    padding: 28px 40px 10px;
  }

  .modal-close{
    top: 18px;
    color: @white;
    font-weight: 300;
    font-size: 40px;
    .opacity(85);
  }
}

.policy-list{
  margin: 0;

  li{
    margin: 0 0 20px;
  }
}

.modal-narrow{
  width: 390px;
  margin-left: -195px;
}

.modal-medium{
  width: 578px;
  margin-left: -578/2px;

  input[type="text"],
  input[type="password"],
  input[type="email"]{
    width: 480px;
  }

  textarea{
    width: 498px;
    padding: 8px;
  }
}

.modal-destination-select{
  top: 70px;
  width: 630px;
  margin-left: -630/2px;
  padding: 0;

  .modal-header {
    position: relative;
    padding: 13px 0 11px 14px;
    #gradient > .vertical(#f6f6f6, #f1f1f1);
    border-bottom: 1px solid #c8c8c8;
    box-shadow: 0 3px 4px -4px rgba(0,0,0,0.35);

    .modal-title{
      font-size: 16px;
      .bold();
    }
  }

  .sidebar{
    float: left;
    width: 199px;
    height: 429px;
    background: #f8f8f8;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }

  .search-descr{
    color: @grayLight;
    padding: 13px;
  }

  .modal-body-inner{
    padding: 14px;

    h4{
      margin: 0 0 10px 0;
      font-size: 13px;
      .bold();
    }
  }

  .no-title-list{
    margin-top: -22px !important;
  }
}

.modal-success{
  font-size: 14px;
  .bold();
  text-align: center;
  padding: 20px 0 35px 0;

  i{
    margin-right: 5px;
  }
}

.map-mode-switcher{
  .clearfix();
  height: 25px;
  width: 108px;
  border-radius: 3px;
  box-shadow: 0 2px 4px #666;

  a{
    display: inline-block;
    float: left;
    color: @white;
    font-size: 10px;
    font-family: verdana;
    text-align: center;
    padding: 3px 10px;
    background: #333333;
    background: rgba(51,51,51,0.8);

    &:hover,
    &.current{
      background: #333333;
      background: rgba(51,51,51,0.9);
    }

    &.map{
      width: 24px;
      border-radius: 3px 0 0 3px;
      border-right: 1px solid #242424;
    }

    &.satellite{
      width: 42px;
      border-radius: 0 3px 3px 0;
      border-left: 1px solid #5c5c5c;
    }
  }
}

.dest-map-zoom{
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 17px;

  .dmz-wrap{
    margin-bottom: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 4px #666;

    a{
      display: block;
      height: 25px;
      width: 25px;
      border-radius: 3px;
      text-align: center;
      background: #333333;
      background: rgba(51,51,51,0.8);

      &:hover{
        background: #333333;
        background: rgba(51,51,51,0.9);
      }

      &.dmz-top{
        height: 21px;
        border-radius: 3px 3px 0 0;
        border-bottom: 1px solid #242424;
      }

      &.dmz-bottom{
        height: 21px;
        border-radius: 0 0 3px 3px;
        border-top: 1px solid #5c5c5c;
      }

      i[class^="icon"]{
        display: inline-block;
        background-image: url(../img/sprite_dest_browser.png);
        background-repeat: no-repeat;
      }

      .icon-plus{
        height: 10px;
        width: 10px;
        margin: 5px 0 0 0;
        background-position: -500px 0;
      }

      .icon-minus{
        height: 2px;
        width: 10px;
        margin: 9px 0 0 0;
        background-position: -500px -20px;
      }

      .icon-location{
        height: 17px;
        width: 17px;
        margin: 4px 0 0 0;
        background-position: -500px -40px;
      }
    }
  }
}

.modal-selector {
  top: 104px;
  width: 500px;
  margin-bottom: 40px;
  margin-left: -250px;
  padding: 0;
  background: #FBFBFC;

  .modal-header,
  .modal-footer {
    padding: 15px 30px;
    background: @white;
  }

  .modal-footer {
    text-align: right;
  }

  .modal-body {
    padding: 30px;
    border-top: 1px solid @grayBorder;
    border-bottom: 1px solid @grayBorder;
  }

  .modal-title {
    font-size: 18px;
    line-height: 23px;
  }

  .close {
    cursor: pointer;
    float: right;
    font-size: 30px;
    font-weight: 100;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.modal-notification {
  margin: -31px -30px 30px;
  padding: 15px 30px;
  background: #F1F9FF;
  border-top: 1px solid #CBE7FB;
  border-bottom: 1px solid #CBE7FB;

  .glyphicon {
    color: @blue;
  }
}

.modal-scrollbox {
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid @grayBorder;
  background: @white;

  ul {
    list-style: none;
    margin: 0;

    li {
      position: relative;
      cursor: pointer;
      padding: 10px 15px;
      border-top: 1px solid @grayBorder;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background: #FFFBED;
      }

      &.checked {
        background: #FFFBED;

        &:after {
          content: "\e084";
          font-family: 'Glyphicons Halflings';
          display: inline-block;
          position: absolute;
          right: 15px;
          top: 50%;
          color: @greenDark;
          margin-top: -9px;
        }
      }
    }
  }
}


// Modal Destinations
//-----------------------------------

.modal-destinations {
  position: relative;
  width: 956px;
  margin: 5px auto;
  background: #eee;
  z-index: 1005;
  overflow: hidden;

  .modal-header {
    position: relative;
    height: 63px;
    margin: 0 0 9px;
    background: @white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

    .header-title {
      width: 870px;
      margin-left: 20px;
      line-height: 1.02;
      font-size: 24px;
    }

    .dest {
      .bold();
      margin-right: 10px;
    }

    .date {
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      color: @grayDark;
    }

    .table {
      margin-bottom: 0;
      height: 63px;

      td {
        border-top: none;
        padding: 0;
        vertical-align: middle;
      }
    }
  }

  .bottom-line {
    margin: -20px 10px 30px 26px;
  }
}

.modal-window,
.modal-destinations{
  .modal-control{
    &.modal-control-common {
      position: absolute;
      right: 5px;
      top: 3px;
      margin-top: 0;
      height: 25px;
      z-index: 10;

      .close-btn {
        .opacity(65);
        color: @grayDark;
        padding: 3px;
        line-height: 20px;

        i {
          position: relative;
          top: 6px;
          margin-top: -2px;
          margin-left: 1px;
        }

        &:hover {
          .opacity(90);
        }
      }
    }
  }
}

.modal-destinations {
  .sidebar {
    .clearfix();
    float: left;
    width: 240px;
  }
}

.sidebar-wrapper {
  float: left;
  width: 240px;
}

.sidebar-item {
  width: 211px;
  padding: 2px 0 3px 0;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;

  .lnk-all{
    position: relative;
    z-index: 1;
    float: right;
    font-size: 11px;
    line-height: 23px;
    margin-top: 7px;
    margin-left: 4px;
  }

  .item-heading {
    .text-overflow();
    position: relative;
    line-height: 15px;
    font-size: 13px;
    .bold();
    cursor: pointer;
    padding: 0 0 0 15px;
    margin: 10px 0 9px 0;

    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 0px;
      border-top: 4px solid @grayDark;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &.closed {
      &:after{
        top: 3px;
        left: 2px;
        border-left: 4px solid @grayDark;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: none;
      }
    }
  }

  .custom-select{
    margin-right: 0;
  }

  .select2-container{
    width: 100%;
    margin: 0 0 10px 0;

    .select2-choice{
      color: @grayDark;
      height: 29px;
      margin: 0 -20px 0 -8px;
      line-height: 32px;
      border: none;
      background: none;
      z-index: 1;

      .select2-search-choice-close{
        display: none !important;
      }
    }
  }

  .select2-choice{
    border: 1px solid #bbbbbb;
  }

  .select2-input{
    .box-sizing(border-box);
  }

  .step-label {
    margin: -1px 0 -4px 0;
  }

  .subheader {
    .bold();
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 7px;
    margin-top: -2px;

    &.highlighted {
      padding: 2px 6px;
      color: @white;
      background: @purple;
    }
  }

  .filter-items {
    margin: 0 0 8px 0;
    padding: 0;
    list-style: none;
    font-size: 12px;

    input[type="radio"],
    input[type="checkbox"]{
      top: 0px;
    }

    label {
      .clearfix();

      font-size: 12px;
      line-height: 15px;
      min-height: 13px;
      width: 194px;
      margin-bottom: 6px;

      .rating-common{
        margin-top: 0;
      }

      &.disabled{
        color: @grayLight;

        input{
          visibility: hidden;
        }
      }
    }
  }

  .slider-item {
    margin-top: 2px;
    margin-bottom: 10px;

    .slider {
      margin: 5px 0 10px 0;
      height: 20px;
    }
  }

  .advisor-rating-wrap {
    font-size: 12px;
    color: @grayDark;
    padding: 1px 0 3px 0;
    margin-top: -3px;
  }
}

.with-overlay{
  .select2-drop{
    z-index: 1000;
  }
}

.selectbox-wrapper{
  margin: 0 0 10px 0;

  .selectbox{
    width: 120px;
    min-width: 120px;
  }
}

.sidebar-item {
  &.complex {
    .subheader {
      .type {
        font-weight: normal;
      }
    }

    .checkbox {
      i {
        margin-right: 4px;
      }
    }

    .icons-wrapper {
      margin: 10px -14px 0 -26px;
    }

    .icons-set {
      width: 100%;

      td {
        text-align: center;
        width: 25%;
      }
    }
  }
}

.head-block {
  .clearfix();
  line-height: 35px;
  padding: 10px 10px 7px 15px;
  background: @white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

  .flights-counter {
    font-size: 16px;
    margin-right: 15px;
  }
}

.modal-destinations {
  .content {
    position: relative;
    float: left;
    width: 704px;
    margin: 0 0 0 10px;

    .sort-selector {
      position: relative;
      float: right;
      margin-right: 5px;

      .title {
        display: inline;
        font-size: 12px;
        margin-right: 8px;
      }

      .sort-dropdown {
        top: -1px;
      }

      .custom-select {
        line-height: 1;
      }

      .value {
        height: 28px;
        line-height: 27px;

        &:after {
          top: 11px;
          right: 7px;
        }
      }

      select {
        width: 100%;
      }
    }

    .destinations {
      width: 704px;
      margin: 9px 0 30px 0;
      padding-top: 1px;
      background: @white;
      border-top: 1px solid #ddd;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    }

    .destination-item-wrapper {
      margin-bottom: 1px;
    }

    .destination-item {
      width: 699px;
      margin-top: -1px;
      margin-bottom: 0;
      padding: 15px 5px 14px 0;
      border-bottom: 1px solid #ddd;

      &:hover{
        .hidden-total{
          visibility: visible;
        }
      }

      .hidden-total{
        visibility: hidden;
        color: @grayDark;
      }

      .visible-total {
        color: @grayDark;
      }

      .left-col {
        float: left;
        width: 130px;
        text-align: center;

        .price {
          display: block;
          font-size: 24px;
        }

        .btn {
          width: 55px;
          margin-top: 3px;
        }

        .tagline {
          display: block;
          margin: 1px 0 0;
          font-size: 11px;
          color: @grayLight;
        }
      }

      .footer {
        .clearfix();
        clear: both;
        line-height: 29px;
        border-top: 1px dotted #ddd;

        .lnk-details {
          display: none;
          position: relative;
          font-size: 12px;
          .bold();
          padding-right: 10px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            right: -2px;
            top: 13px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-top: 4px solid @blue;
            border-right: 4px solid transparent;
          }
        }
      }
    }
  }
}

.modal-destinations {
  &:hover {
    .modal-header {
      .modal-control {
        display: block;
      }
    }
  }
}

.modal-confirmation{
  overflow: visible;
  margin: 25px auto;
  width: 1000px;

  .container {
    width: auto;

    .grid-details .left-col {
      width: 580px;
    }
  }

  .content-box{
    margin: 0;
    padding: 20px 30px 60px;
    border: none;

    .destination-postcard{
      margin-top: 14px;
    }

    .inner-head-wrapper{
      margin-top: 7px;
    }
  }
}

.modal-cancellation{
  width: 883px;
  margin: 25px auto;
  background: @white;
  border: 1px solid @grayBorder;

  .modal-subheader{
    height: 49px;
    padding: 0 30px;
    border-bottom: 1px solid @grayBorder;

    h2{
      font-size: 18px;
      .bold();
      line-height: 51px;
    }

    .modal-close{
      float: right;
      color: @grayDark;
      font-size: 35px;
      font-weight: 300;
      margin-top: 14px;
    }
  }

  h3{
    font-size: 15px;
    .bold();
    margin: 0 0 18px;
  }

  .modal-content{
    .clearfix();

    .sidebar-hotel-info{
      float: right;
      margin-bottom: 10px;
      background: #f9f9f9;
      border-left: 1px dashed @grayBorder;
      border-bottom: 1px dashed @grayBorder;
    }

    .summary-section:last-child {
      border-bottom: none;
    }
  }

  .cancellation-content{
    font-size: 14px;
    width: 493px;
    padding: 16px 0 30px 30px;

    .cancellation-policy{
      margin: 0 0 40px;
    }

    .cancellation-buttons{
      .clearfix();

      .btn{
        float: left;
        font-size: 14px;
        width: 240px;
        padding: 8px 0;
      }

      .btn-default{
        float: right;
      }
    }
  }

  .cancellation-wait-spinner {
    position: relative;
    padding-left: 40px;

    .spinner {
      top: 9px !important;
      left: 22px !important;
    }
  }
}

.cancellation-terms {
  padding: 17px 14px;
  margin-bottom: 17px;
  background: #f9f9f9;
  border-top: 1px solid @grayBorder;
  border-bottom: 1px solid @grayBorder;
}

.cancellation-terms__checkbox {
  height: 13px;
  margin-right: 4px !important;
}


// Destionation modal on page

.modal-destinations {
  &.on-page {
    .clearfix();
    margin-top: 9px;
    overflow: visible;
    width: 998px;
    background: none;
    z-index: 0;

    .modal-header {
      margin-bottom: 25px;
      box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);

      .dest-name {
        margin-right: 10px;
      }

      .map {
        display: inline-block;
        font-size: 13px;
      }

      .header-title {
        width: 665px;
      }

      .table {
        float: left;
        width: 665px;
      }
    }

    .modal-content {
      margin: 0;
    }

    .sidebar {
      position: relative;
      padding: 0 0 10px 0;
      background: @white;
      border: 1px solid @grayBorder;
      z-index: 1;

      &:after,
      &:before {
        display: none;
      }

      .sidebar-item {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .content {
      width: 746px;
      padding-top: 10px;
      background: @white;
      box-shadow: 0 0 4px rgba(0,0,0,0.2);
      -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);

      .destination-item {
        width: 720px;
        padding: 15px 5px 14px 21px;
      }

      .head-block {
        padding: 0 10px 0 15px;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }

    .destinations {
      width: 746px;
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    .destination-item-wrapper {
      &:last-child {
        margin-bottom: 3px;

        &:hover {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.modal-map-view {
  width: 998px;
  height: 620px;
  margin-left: -998px / 2;
  padding: 0;
  top: 48px;

  .modal-header {
    position: relative;
    height: 44px;
    padding: 0 15px;
    background: #f4f4f4;
    border-bottom: @grayBorder 1px solid;

    &:after{
      position: absolute;
      bottom: -7px;
      right: 0;
      display: block;
      height: 7px;
      width: 756px;
      content: "";
      box-shadow: inset 0 3px 2px rgba(0,0,0,0.2);
      overflow: hidden;
    }

    .header-title {
      padding: 4px 0;
      font-size: 16px;
      .bold();
    }

    .btn{
      margin-top: 8px;
      color: @gray;
      .bold();
      font-size: 12px;
      line-height: 18px;
    }
  }

  .sidebar {
    height: 575px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 2px 0 3px rgba(0,0,0,0.2);

    &:before,
    &:after {
      display: none;
    }

    .rating-common {
      float: left;
    }

    .sidebar-item{
      width: 197px;
    }

    .scroll-hint{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 212px;
      height: 28px;
      padding: 0 15px;
      background: rgba(0,0,0,0.8);
      cursor: pointer;

      &.scroll{
        background: rgba(0,0,0,0.7);
      }

      span{
        color: @white;
        .bold();
        line-height: 27px;
        font-size: 12px;
      }

      .icon-scroll-hint-arr{
        float: right;
        margin: 5px 12px 0 0;
      }
    }
  }

  .search-block {
    .btn-trigger {
      right: 4px;
    }

    input[type="text"]{
      width: 155px;
    }

    .select2-choice {
      width: 199px !important;
      overflow: visible;
    }

    .select2-chosen {
      width: 175px;
    }
  }

  .advisor-rating-wrap {
    padding: 5px 0;

    span {
      font-size: 13px;
      color: @grayDark;
    }
  }

  .modal-content {
    position: relative;
    float: left;
    width: 756px;
    height: 575px;
    border: none;
    margin: 0;
    box-shadow: none;
    z-index: -1;

    &.large{
      width: 100%;

      .dest-map-wrapper{
        width: 100%;

        .dest-map-canvas{
          width: 100%;
        }

        .map-message-box{
          width: 970px;
        }
      }

      .map-legend-partner{
        margin-left: 833px;
      }
    }
  }

  .dest-map-wrapper {
    width: 756px;
    height: 575px;
    margin: 0;
    border: none;
    box-shadow: none;

    .dest-map-canvas {
      width: 756px;
      height: 575px;
    }

    .map-legend-list {
      margin-left: 696px;

      &.opened {
        margin-left: 436px;
      }
    }
  }

  .hotel-description-box {
    position: absolute;
    top: 50px;
    left: 200px;
    width: 320px;
    padding: 10px;
    border: none;
    background: @white;
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.3);

    .destination-list-item {
      .price-wrap {
        position: static;
        top: 0;
        bottom: 0;
        text-align: left;

        .price{
          display: inline;
          font-size: 21px;
          color: @blue;
        }
      }

      .destination-image{
        width: 121px;
        height: 69px;
      }
    }

    .destination-info {
      width: 189px;
      margin: -2px 0 0 10px;

      .title{
        max-width: 168px;
        margin-bottom: 1px;
        font-size: 13px;
      }
    }

    .advisor-rating-wrap {
      display: inline-block;
      height: 16px;
      margin: 5px 0 6px -3px;
      padding: 0 0 0 8px;
      border-left: @grayBorder 1px solid;
    }

    .dest-right-col {
      width: 120px;
      padding-top: 47px;

      .btn {
        width: 100px;
        padding: 5px 0;
        border-radius: 3px;
      }
    }
  }
}

.modal-page-content{
  width: 956px;
  margin-left: -956px/2;
  padding: 0;

  .modal-subheader{
    .clearfix();
    padding: 9px 32px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid #e4e4e4;

    .modal-subheader-rightbox{
      float: right;
      text-align: right;
      width: 277px;
      padding: 9px 0 0;

      .btn{
        float: right;
        margin-left: 26px;
      }

      .price-wrap{
        float: right;
        padding: 4px 0 0;
      }

      .price{
        display: block;
        font-size: 19px;
        color: #424242;
        margin-bottom: -4px;

        strong{
          font-size: 24px;
          margin-left: 4px;
        }
      }

      .descr{
        display: block;
        color: @grayLight;
        font-size: 11px;
      }
    }

    .modal-subheader-leftbox{
      float: left;

      h1{
        font-size: 19px;
        .bold();
        margin-bottom: -4px;
      }

      .rating-common{
        float: left;
      }

      .advisor-rating-wrap {
        float: left;
        border-left: 1px solid #CCCCCC;
        margin-left: 5px;
        padding-left: 10px;
      }
    }
  }

  .modal-content{
    padding: 18px 32px;

    .hotel-overview-gallery{
      margin: 0;
    }

    .hotel-additional-info{
      h3{
        font-size: 16px;
        .bold();
        margin: 21px 0 7px 0;
      }

      ol{
        li{
          line-height: 21px;
        }
      }
    }

    .modal-amenities-list{
      .clearfix();
      list-style: none;
      margin: 0;

      li{
        float: left;
        width: 153px;

        i{
          position: relative;
          top: 4px;
        }
      }
    }

    .hotel-gallery-aside{
      width: 388px;

      .dest-map-wrapper{
        width: 388px;
        height: 471px;
        margin: 0;
        border: none;
        box-shadow: none;

        .dest-map-canvas{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.modal-wrapper {
  &.alternate-loader{
    .modal-window{
      background: none;
      .box-shadow(none);
    }
  }
}


.modal-selector-narrow{
  top: 150px;
  width: 600px;
  height: auto;
  margin-left: -600px / 2;

  .modal-body{
    padding: 24px 24px 5px 24px;

    .control-group{
      margin-bottom: 24px;
    }

    .btn{
      padding: 8px 15px;
    }

    h4{
      font-size: 16px;
      .bold();
      margin: 0 0 15px;
    }
  }
}

.preloader-results-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  min-height: 100px;
  margin-top:-60px;
  margin-left:-250px;
  padding: 7px 20px 10px 40px;
  font-size: 24px;
  border: #1679dc 1px solid;
  background-color: @white;
  line-height: 32px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, .5) 0 0 16px,
                       inset rgba(0, 0, 0, .3) 0 0 10px;

  p{
    margin-top: 18px;
    width: 380px;
  }

  .spinner{
    position: absolute;
    right: 20px;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
  }
}

.modal-window{
  .btn-close-modal{
    position: absolute;
    top: -14px;
    right: -14px;
    display: block;
    width: 28px;
    height: 28px;
    background: url(../img/sprite_main.png) -710px -130px no-repeat;
    text-indent: -999em;
  }
}

.modal-browser-support{
  .modal-window{
    width: 660px;
    margin-left: -330px;
    padding: 0;
  }
}

.modal-advisor{
  top: 40px;
  width: 662px;
  margin-left: -331px;
  padding: 0;
}

.modal-header{
  &.no-decorate{
    box-shadow: none !important;
  }
}

.modal-window {
  .modal-heading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 5px 30px;
    background: @grayBg;
    border-bottom: 1px solid @grayBorder;
    .box-sizing(border-box);

    .header-title {
      font-size: 16px;
      .bold();
    }

    .btn {
      margin-top: 9px;
    }
  }

  &.adv {
    .modal-content {
      margin-top: 24px;
    }
  }
}


// Text modal for short info messages & errors
//

.info-modal {
  position: relative;
  top: 25%;
  width: 600px;
  margin: 0 auto;
  background: @white;
  border-left: 8px solid @red;
  box-shadow: 0 0 15px rgba(0,0,0,0.4),
                      0 10px 10px -6px rgba(0,0,0,0.5);

  &.info-modal--success {
    border-left: 8px solid @green;

    .info-modal__body {
      text-align: center;
    }
  }
}

.info-modal__body {
  padding: 20px 20px 15px;
  max-height: 300px;
  overflow-y: auto;
  font-size: 15px;
  line-height: 1.56;
  background: @grayBg;

  .accent {
    margin: 0;
    padding: 12px 14px;
    font-size: 13px;
    background: #e2e2e2;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.3);
    border-radius: 2px;

    & + p {
      margin-top: 10px;
    }
  }
}

.info-modal__expandable {
  margin-top: 8px;
  background: darken(@grayBg, 5%);
  border-radius: 4px;

  &:first-of-type {
    margin-top: 20px;
  }
}

.info-modal__expandable__title {
  display: block;
  position: relative;
  padding: 3px 23px 3px 10px;
}

.info-modal__icon  {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -6px;
}

.info-modal__expandable__details {
  display: none;
  padding: 3px 23px 3px 20px;
  font-size: 13px;
}

.info-modal__expandable--open {
  .info-modal__expandable__details {
    display: block;
  }

  .info-modal__icon {
    .rotate(180deg);
  }
}

.info-modal__footer {
  padding: 12px 20px;
  text-align: right;
  border-top: 1px solid #e3e3e3;

  .btn {
    min-width: 70px;
    margin-left: 10px;
  }
}

.modal-window--std {
  top: 80px;
  width: 600px;
  padding-bottom: 15px;
  margin-left: -330px;

  .modal-footer {
    margin: 20px 0 -10px;
    padding-top: 15px;
    border-top: 1px solid @grayBorder;
  }
}

.modal-well {
  .clearfix();
  background: @grayBg;
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid @grayBorder;
  font-size: 12px;
  border-radius: 2px;
}

.modal-content > .modal-well {
  margin-top: 50px;
}

.modal-rate-desc {
  padding: 0 0 15px;
}

.modal-rate-desc__section {
  margin: 5px 0;
  padding: 5px 0 10px;
  border-top: 1px solid @grayBorder;

  &:first-child {
    border-top: none;
  }

  p, li {
    color: #444;
  }

  li {
    margin-bottom: 5px;
  }
}

.modal-rate-desc__title {
  margin: 20px 0 10px;
  font-size: 14px;
  line-height: 2;
  font-weight: bold;
  border-bottom: 1px solid @grayBorder;
}

.modal-rate__table {
  width: 743px;
  margin: 6px 0 9px 17px;


  tr:first-child {
    th, td {
      border-top: none;
    }
  }
}

.modal-rate-desc__extra {
  p > b:first-child {
    display: inline-block;
    margin: 12px 0 0 0;
    font-size: 14px;
  }
}

.modal-rate-desc__wrap {
  & + & {
    margin-top: 10px;
  }

  .modal-rate-desc__title {
    &:only-child {
      display: none;
    }
  }
}

.modal-prompt {
  position: absolute;
  left: 50%;
  top: 18%;
  width: 500px;
  margin-left: -250px;
  background: @white;
  z-index: 50;
  border-top: 4px solid @orange;
  .box-sizing(border-box);

  .alert {
    position: relative;
    padding: 12px 18px;
    font-size: 13px;

    .alert__title {
      padding: 0 0 0 20px;
      font-size: 14px !important;

      .glyphicon {
        position: absolute;
        left: 12px;
        top: 14px;
      }
    }

    .alert__text {
      padding: 6px 0 0 20px;
      color: @grayDark;
    }
  }
}

.modal-prompt--danger {
  border-top-color: @red;
}

.modal-prompt__close {
  float: right;
  margin-top: 3px;
  color: darken(@yellow, 35%);

  &:hover {
    color: darken(@yellow, 25%);
  }
}

.modal-prompt__content {
  padding: 20px 15px 15px;

  p {
    margin-bottom: 2px;
  }
}

.modal-prompt__footer {
  padding: 20px 15px;
  background: @grayBg;
  border-top: 1px solid @grayBorder;

  .btn-link {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal-prompt__price-section {
  .clearfix();
  padding: 20px 0 10px 0;
}

.modal-prompt__price-section__item--inactive {
  .opacity(60);
}

.modal-prompt__price-section__item {
  width: 49%;
  padding: 15px 20px;
  text-align: center;
  border: 1px dashed @grayBorder;
  .box-sizing(border-box);

  &:first-child {
    float: left;
  }

  &:last-child {
    float: right;
  }
}

.modal-prompt__price-section__value {
  margin: 8px 0 5px;
  font-size: 20px;
  font-weight: bold;
}

.modal-accent {
  .clearfix();
  width: 735px;
  margin: 110px auto 0;
  background: #ededed;
}

.modal-accent__header {
  position: relative;
  height: 42px;
  margin-bottom: 18px;
  color: #f2fffa;
  background: #d9e2dd;

  h2 {
    float: right;
    width: 585px;
    height: 42px;
    padding: 0 30px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    background: #009f65;
    .box-sizing(border-box);
  }

  .btn {
    position: absolute;
    right: 5px;
    top: 7px;
    color: #91d6bd;

    &:hover,
    &:active {
      color: darken(#91d6bd, 40%);
    }
  }
}

.modal-accent__survey {
  float: right;
  width: 565px;
  margin-right: 20px;
  padding: 25px 40px 20px;
  background: @white;
  .box-sizing(border-box);

  h4 {
    margin: 0 0 20px 0;
    font-weight: bold;
  }

  .control-group {
    margin-bottom: 8px;
  }

  .request__textfield {
    margin-left: 4px;
  }

  .control-group__sub {
    margin: -5px 53px 0 0;
    font-size: 11px;
    font-style: italic;
    text-align: right;
    color: @gray;
  }
}

.comparison-section {
  position: relative;
  padding-bottom: 26px;
  z-index: 5;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 280px;
    position: absolute;
    right: 305px;
    bottom: 0;
    top: 0;
    background: #f9f9f9;
    z-index: -1;
    box-shadow: 0 0 12px rgba(0,0,0,0.05);
  }

  &:after {
    right: 25px;
    background: @white;
  }

  .muted {
    color: #666;
  }

  .small {
    font-size: 10px;
  }
}

.comparison-section__row {
  .clearfix();
  margin: 3px 0;
}

.modal-accent__survey__footer {
  clear: both;
  margin-right: 20px;
  margin-left: 145px;
  padding: 15px 0 !important;
  text-align: right !important;

  .btn {
    width: auto !important;
    min-width: 150px;
  }

  .btn-link {
    font-size: 12px !important;
  }
}

.comparison-section__row--footer,
.modal-accent__survey__footer {
  padding-top: 8px;
  text-align: center;

  .btn {
    width: 100%;
    padding: 10px 5px;
    font-size: 14px;
  }

  .btn-default {
    font-weight: normal;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;

    &:hover {
      background: darken(#f5f5f5, 6%);
      border-color: darken(#e0e0e0, 10%);
    }
  }

  .btn-primary {
    font-weight: bold;
    color: @white;
    background: #009f65;
    border: 1px solid #009f65;

    &:hover {
      background: darken(#009f65, 5%);
      border-color: darken(#009f65, 10%);
    }
  }
}

.comparison-section__col {
  float: left;
  width: 280px;
  min-height: 5px;
  padding: 0 18px;
  font-size: 12px;
  .box-sizing(border-box);

  a {
    font-size: 11px;
  }

  h5 {
    margin: 0 0 5px;
    text-transform: uppercase;
    font-size: 11px;
  }

  li {
    margin-bottom: 3px;
    font-size: 11px;
    line-height: 1.4;
  }
}

.comparison-section__col--label {
  width: 150px;
  text-align: right;
}

.comparison-section__col--header {
  margin-bottom: 20px;
  line-height: 31px;
  color: #444;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #009f65;

  &:last-child {
    border-bottom-width: 2px;
  }
}

.comparison-section__price {
  display: inline-block;
  margin-right: 2px;
  font-size: 16px;
  font-weight: bold;
}

.comparison-section__hr {
  margin: 11px 25px 11px 150px;
  border-bottom: none;
  border-top: 1px solid #dbe8e3;
}

.comparison-section__rate-title {
  margin: 0 0 2px;
  font-size: 12px;

  &:empty {
    display: none;
  }
}

.comparison-section__rate-desc {
  height: 30px;
  margin-bottom: 3px;
  line-height: 1.38;
  font-size: 11px;
  overflow: hidden;
}

.comparison-section__note {
  margin-top: 2px;
  font-size: 11px;
  line-height: 1.38;
  color: #666;
  text-align: center;
}
