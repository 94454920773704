// Scaffolding
// Basic and global styles for generating a grid system, structural layout, and page templates
// -------------------------------------------------------------------------------------------


// Body reset
// ----------

html {
  min-width: 998px;
}

body {
  min-width: 998px;
  margin: 0;
  font-family: @baseFontFamily;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @textColor;
  background-color: @bodyBackground;
  -webkit-font-smoothing: antialiased;
}


// Links
// -----

a {
  color: @linkColor;
  text-decoration: none;
}
a:hover {
  color: @linkColorHover;
  text-decoration: none;
}

// Container

.container {
  .container-fixed();
}