.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  .box-sizing(border-box);
}

.select2-choice {
  .text-overflow();
  position: relative;
  display: block;
  color: @grayDark;
  line-height: 34px;
  padding: 0 8px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: @grayDark;
  }

  &.select2-default {
    color: @grayLight;
  }

  .select2-arrow,
  .select2-search-choice-close {
    display: none;
  }
}

.select2-drop {
  width: 100%;
  margin-top: 3px;
  position: absolute;
  top: 100%;
  background: #ffffff;
  border-top: 0;
  border-radius: 3px;
  z-index: 10;
  .box-sizing(border-box);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.select2-drop-auto-width {
  width: 175px;

  .select2-search {
    min-width: 175px;
    padding: 4px 5px 0 5px;
    .box-sizing(border-box);

    .select2-input {
      width: 100%;
      margin-bottom: 5px;
      .box-sizing(border-box);
    }
  }
}

.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  li {
    display: list-item;
    padding: 2px 2px;
    list-style: none;
    background-image: none;

    em {
      background: #feffde;
      font-style: normal;
    }
  }

  .select2-highlighted {
    background: @blue;
    color: #ffffff;

    em {
      background: transparent;
    }

    ul {
      background: #ffffff;
      color: #000000;
    }

    .secondary {
      color: rgba(255,255,255,0.7);
    }
  }

  .select2-selected {
    display: none;
  }
}

.select2-result-selectable {
  cursor: pointer;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url(../img/select2-spinner.gif) no-repeat 100%;
}

.select2-container-multi .select2-choices,
.select2-choice {
  .clearfix();
  min-height: 36px;
  margin: 0;
  padding: 0 3px 1px 3px;
  border: 1px solid #0008a3;
  border-radius: 3px;
  background: @white;
  .box-sizing(border-box);

  li {
    float: left;
    list-style: none;
  }

  .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    width: auto;

    input {
      height: 32px;
      margin-top: 1px;
      background: transparent !important;
      border: none;
      box-shadow: none;
    }

    &.nano {
      input {
        width: 2px !important;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .select2-input {
    padding-left: 5px;
  }

  .select2-search-choice {
    position: relative;
    margin: 3px 0 0 2px;
    line-height: 28px;
    padding: 0 4px;
    border-radius: 2px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    div:after {
      content: ",";
    }

    &.last {
      div:after {
        content: "" !important;
      }
    }

    .select2-chosen {
      cursor: default;
    }
  }

  .select2-search-choice-focus {
    color: @white;

    .select2-search-choice-close {
      background-position: -39px -3px;
    }
  }
}

.select2-container{
  .select2-search-choice-close {
      display: none;
   }

  &.select2-allowclear,
  .select2-search-choice {
    .select2-search-choice-close {
      display: inline-block !important;
    }
  }
}

.select2-container-active {
  .select2-choice,
  .select2-choices {
    border-color: #5FC5FF !important;
    box-shadow: 0 0 0 1px #5FC5FF;
  }
}

.select2-search-choice {
  padding: 0 19px 0 6px !important;
  color: @white;
  background: #005d9a !important;

  &:first-child {
    margin-left: 0;
  }

  div:after {
    content: "" !important;
  }

  .select2-search-choice-close {
    background-position: -39px -3px;
  }
}

.error {
  .select2-choice,
  .select2-choices {
      color: #f14848;
      background-color: #ffe6e5;
      border-color: #f14848 !important;
      box-shadow: 0 0 0 1px #f14848;
  }

  .select2-input {
    color: #f14848;
    .placeholder(#f14848);
  }
}

.select2-search-choice-close {
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 7px;
  top: 7px;
  background: url(../img/select2.png) -49px -3px no-repeat;
}

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

// PNR retrive dropdown on home page

.user-selector {
  .select2-choice {
    min-height: 31px !important;
    line-height: 29px !important;
    border-color: #bbb !important;

    .select2-search-choice-close {
      top: 10px;
    }
  }
}

.select2-traveler {
  .select2-input {
    position: absolute;
    top: -34px;
    left: 0px;
    height: 29px;
    width: 319px;
  }

  .select2-result {
    padding: 2px 7px;
  }
}

.dropdown-item__secondary {
  display: block;
  margin-top: -1px;
  color: @gray;
  font-size: 11px;
}

.select2-highlighted {
  .dropdown-item__secondary {
    color: @white;
  }
}

.select2--group {
  .select2-container {
    float: left;
    margin-right: 10px;
  }

  .select2-choice {
    width: 228px;
    line-height: 28px;
    border: 1px solid @grayBorder;
  }

  .select2-container-multi .select2-choices,
  .select2-choice {
    min-height: 31px;
  }

  .select2-container-active .select2-choice,
  .select2-container-active .select2-choices {
    box-shadow: none;
    border: 1px solid @blue !important;
  }
}

.select2-searchbox--group {
  margin-top: -31px;
}

.select2--home-tr {
  .select2-container {
    margin-right: auto;
  }

   .select2-choice {
    width: 375px !important;
    line-height: 33px;
    color: @grayDark;
  }

  .select2-focusser {
    border-color: #5FC5FF !important;
    box-shadow: 0 0 0 1px #5FC5FF;
  }

  .select2-container-active .select2-choice,
  .select2-container-active .select2-choices {
    border-color: #ddd !important;
  }

  .select2-search-choice-close {
      top: 12px;
  }

  .select2-default {
    color: @grayLight;
  }
}

.select2-traveler--home {
  .select2-input {
    top: -38px;
    width: 357px;
    height: 33px;
    border-color: #5FC5FF !important;
    box-shadow: 0 0 0 1px #5FC5FF;
  }
}