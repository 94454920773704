// Typography.less
// Headings, body text, lists, code, and more for a versatile and durable typography system
// ----------------------------------------------------------------------------------------


// BODY TEXT
// ---------

p {
  margin: 0 0 @baseLineHeight / 2;
  small {
    font-size: @baseFontSize - 2;
    color: @grayLight;
  }
}


// HEADINGS
// --------

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: @headingsFontFamily;
  font-weight: @headingsFontWeight;
  color: @headingsColor;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  small {
    font-weight: normal;
    color: @grayLight;
  }
}

h1 {
  font-family: @OpenSans;
}

h1 {
  font-size: 30px;
  line-height: @baseLineHeight * 2;
  small {
    font-size: 18px;
  }
}
h2 {
  font-size: 24px;
  line-height: @baseLineHeight * 2;
  small {
    font-size: 18px;
  }
}
h3 {
  font-size: 18px;
  line-height: @baseLineHeight * 1.5;
  small {
    font-size: 14px;
  }
}
h4, h5, h6 {
  line-height: @baseLineHeight;
}
h4 {
  font-size: 14px;
  small {
    font-size: 12px;
  }
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 11px;
  color: @grayLight;
  text-transform: uppercase;
}

// LISTS
// -----

// Unordered and Ordered lists
ul, ol {
  padding: 0;
  margin: 0 0 @baseLineHeight / 2 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li {
  line-height: @baseLineHeight;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}

// Description Lists
dl {
  margin-bottom: @baseLineHeight;
}
dt,
dd {
  line-height: @baseLineHeight;
}
dt {
  .bold();
  line-height: @baseLineHeight - 1; // fix jank Helvetica Neue font bug
}
dd {
  margin-left: @baseLineHeight / 2;
}

// MISC
// ----

// Horizontal rules
hr {
  margin: @baseLineHeight 0;
  border: 0;
  border-top: 1px solid @hrBorder;
  border-bottom: 1px solid @white;
}

// Emphasis
b,
strong{
  .bold();
}
em {
  font-style: italic;
}
.muted {
  color: @grayLight;
}

// Abbreviations and acronyms
abbr[title] {
  cursor: help;
  text-decoration: none;
  border-bottom: 1px dotted @grayLight;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Addresses
address {
  display: block;
  margin-bottom: @baseLineHeight;
  font-style: normal;
  line-height: @baseLineHeight;
}

// Misc
small {
  font-size: 100%;
}
cite {
  font-style: normal;
}