.login-page-bg {
  background: #FAD797 url(../img/bg_agents.jpg) no-repeat 0 0;
  background-size: cover;
}

.main-header {
  .clearfix;

  position: relative;
  height: 45px;
  min-width: 940px;
  margin-bottom: 15px;
  background: @white;
  box-shadow: 0 1px 0 rgba(0,0,0,0.15);
  z-index: 40;

  .user-nav {
    .pull-right;

    position: relative;
    margin: 14px 0 0 0;

    .hide {
      display: none !important;
    }

    .badge {
      margin-left: 3px;
    }

    a {
      color: @gray;

      &.lnk-attn {
        float: left;
        margin: 0 8px;

        &:hover {
          color: @blue;
        }
      }

      &.logged-in {
        padding: 0 14px 0 9px;
      }

      &.lnk{
        color: @purple;
      }
    }

    .nav-link{
      float: left;
      color: @grayDark;
      margin-right: 15px;
    }

    aside {
      display: inline-block;

      &.drop-down-local{
        float: left;
        margin-right: 15px;

        .drop-down{
          right: 50%;
          margin-right: -22px;
        }
      }
    }
  }
}

.header-dropdown-section {
  a {
    position: relative;
    margin: 0;
    color: @grayDark;
  }

  .drop-down-icon {
    display: block;
    position: relative;
    cursor: pointer;
    color: @grayDark;
    margin: 0;
    padding-right: 15px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 8px;
      width: 0;
      height: 0;
      border-right: 4px solid transparent;
      border-top: 4px solid @grayDark;
      border-left: 4px solid transparent;
    }
  }

  .drop-down {
    position: absolute;
    z-index: 30;
    top: 25px;
    right: 0;
    min-width: 136px;
    padding: 10px 10px 5px 10px;
    text-align: left;
    background: @white;
    border-radius: 4px;
    box-shadow:  0 0 2px rgba(0,0,0,0.25); //, 0 1px 1px rgba(0,0,0,0.25);
    border: 1px solid @graySecondary;
    cursor: default;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      right: 23px;
      width: 10px;
      height: 5px;
      background: url(../img/sprite_main.png) -340px -230px no-repeat;
    }

    .drop-down-header {
      display: block;
      margin: 0 0 10px;
      padding-left: 3px;
      .bold();
    }

    .currency {
      .sign {
        top: 1px;
        color: @grayDark;
        width: 32px;
      }

      .label{
        padding-left: 35px;
      }
    }

    .language {
      .sign {
        width: 22px;
      }

      .label{
        padding-left: 25px;
      }
    }

    .currency,
    .language {
      a {
        color: @blue;

        &.disabled {
          color: @gray;
        }
      }
    }

    .sign {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      font-size: 11px;
      .bold();
      text-transform: uppercase;
      padding-left: 3px;
    }

    .label{
      display: block;
    }

    a {
      position: relative;
      display: block;
      margin: 0;
      padding: 0 4px 0 4px;
      border-radius: 3px;

      &:hover,
      &.selected {
        background: @blue;
        color: @white !important;

        .sign {
          color: @white;
        }
      }

      &.disabled:hover {
        background: @white;
        color: @gray !important;

        .sign {
          color: @grayDark;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0 0 5px 0;
      text-align: left !important;
      list-style-position: outside;
      overflow: hidden;
      white-space: nowrap;

      &.divided {
        margin-top: 7px;
        padding-top: 6px;
        border-top: 1px solid @grayBorder;
      }
    }
  }
}

.user-nav .custom-select--transparent {
  float: left;
  margin-top: -1px;
  margin-right: 15px;

  .value {
    width: auto;
    height: auto;
    line-height: 22px;

    &:after {
      top: 9px;
      right: 6px;
    }
  }

  select {
    width: 100%;
  }
}

.user-nav--left {
  float: left !important;
  margin-left: 35px !important;

  .nav-link {
    margin-right: 30px !important;
  }
}

.dropdown--col {
  display: inline-block;
  vertical-align: top;
  margin-right: 9px !important;

  &:last-child {
    margin-right: 0 !important
  }
}

.user-nav--attn {
  font-weight: bold;
}

li.divider {
  height: 1px;
  margin: 10px 0 !important;
  background: @grayBorder;
}

.drop-down-local--compact {
  .drop-down {
    margin-right: -40px !important;

    &:after {
      right: 40px !important;
    }
  }

  li {
    line-height: 17px !important;
    margin-bottom: 0 !important;
  }

  a {
    font-size: 11px !important;
  }

  .label {
    .text-overflow();
    display: inline-block;
    max-width: 110px;
    padding-left: 27px !important;
  }

  .sign {
    font-size: 10px !important;
  }

  .drop-down-header {
    margin: 0 0 3px !important;
    font-size: 12px !important;
  }

  .dropdown--col {
    margin-right: 1px !important;
  }
}

.main-header{
  .section-nav{
    display: inline-block;
    float: left;
    margin: 0 0 0 15px;
    padding-left: 1px;
    #gradient > .vertical(#cccccc, @white);

    a{
      display: inline-block;
      float: left;
      .bold();
      font-size: 14px;
      color: @gray;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 19px;
      height: 19px;
      padding: 8px 11px 18px 11px;
      margin-right: 1px;
      background: @white;

      &.current{
        cursor: default;
        color: @white;
        background: @purple !important;
      }

      i{
        position: relative;
        top: 4px;
        margin-right: 5px;
      }
    }
  }
}

.detached-header {
  .main-header {
    left: 50%;
    top: 125px;
    width: 410px;
    min-width: 410px;
    margin-left: -410px/2;
    padding: 10px 0;
    border-top: none !important;
    border-bottom: 1px solid @grayBorder;
    box-shadow: none;

    .container {
      width: 338px;
    }

    .user-nav aside.drop-down-local {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .currency-selector {
    display: none !important;
  }

  &.with-sidebar{
    .main-header{
      margin-left: -329px;
    }
  }
}

// Common footer

.footer-placeholder{
  height: 337px;
  margin: 80px 0 0 0;

  &.no-content{
    margin: 0;
  }
}

.page-footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  color: @grayLight;
  font-size: 12px;
  line-height: 1.6em;
  width: 100%;
  padding: 45px 0;
  border-top: 1px solid #bababa;
  box-shadow: inset 0 1px 0 @white;
  background: #f2f2f2;

  .copyright {
    span {
      margin-right: 20px;
    }
  }

  .footer-top-wrap{
    .clearfix();

    margin: 0 0 30px 0;
  }

  .footer-tooltip {
    text-decoration: underline;
    cursor: pointer;
  }
}

.footer-nav {
  float: left;

  a {
    color: @gray;
    font-size: 14px;
    text-decoration: none;

    &:hover {
      color: @linkColor;
      text-decoration: underline;
    }
  }

  .footer-nav-colomn{
    float: left;
    width: 160px;

    h4 {
      color: @gray;
      font-size: 16px;
      .bold();
      margin: 0 0 14px 0;
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        margin: 0 0 10px 0;
      }
    }
  }

  .inline-links {
    width: 665px;
    margin-top: 4px;

    li {
      float: left;
      width: 220px;
      margin-bottom: 8px;

      a {
        font-size: 15px;
      }
    }
  }
}

.footer-right-side{
  float: right;
  width: 295px;
  padding: 0 0 24px 20px;
  border-left: 1px solid #b6b6b6;
  box-shadow: inset 1px 0 0 #ffffff;

  .social-services{
    .clearfix();

    margin: 0 0 15px 0;

    a {
      .opacity(80);

      &:hover {
        .opacity(100);
      }
    }
  }

  .footer-side-logos{
    .clearfix();

    .item{
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.footer-copy{
  position: relative;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 1px @white;
  padding: 15px 0 0 0;
  border-top: 1px solid #bababa;
  box-shadow: inset 0 1px 0 @white;

  &:after{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -22px;
    margin-left: -182/2px;
    width: 182px;
    height: 32px;
    background: #F2F2F2 url(../img/sprite_main.png) no-repeat 6px -300px;
  }

  a{
    position: relative;
    left: -15px;
    top: -1px;
  }

  .tag {
    position: relative;
    top: 1px;
  }
}

.copyright {
  float: left;
  display: inline-block;
  padding-top: 11px;
  font-size: 11px;
  text-align: center;
  color: @graySecondary;
}

// Alt footer

.footer-prepend {
  height: 90px;
}

.alt-footer {
  position: absolute;
  bottom: 0;
  color: #BFBFBF;
  width: 100%;
  margin: -40px 0 0 0;
  padding: 5px 0 15px 0;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.2);

  #translucent > .background(#000, 0.64);

  .copyright {
    float: none;
    display: block;
    color: darken(#d9d9d9, 30%);
    text-align: left;
  }

  a {
   color: #d9d9d9;

    &:hover {
      color: #fff;
    }
  }
}

.footer-rev {
  margin: 25px 0 0 0;
  padding: 21px 0 30px;
  line-height: 1.7;
  color: #999;
  font-family: @OpenSans;
  border-top: 1px solid #ddd;

  .pull-right {
    text-align: right;
  }
}

.footer-rev__left-col {
  display: inline-block;
  width: 60%;
}

.header-popup {
  position: absolute;
  right: -30px;
  top: 27px;
  width: 310px;
  padding: 15px 20px;
  background: @white;
  box-shadow: 0 0 14px rgba(0,0,0,0.17);

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 44px;
    top: -8px;
    border-right: 8px solid transparent;
    border-bottom: 8px solid @white;
    border-left: 8px solid transparent;
  }
}

.header-popup__control {
  margin: 13px 0 1px 0;

  a {
    margin: 0 3px 0 0 !important;
    padding: 4px 15px;
    background: lighten(@grayLight, 36%);
    border-radius: 3px;

    &:hover {
      background: lighten(@grayLight, 32%);
    }
  }
}

.header-popup__confirm {
  color: @white !important;
  background: @greenDark !important;

  &:hover {
    background: lighten(@greenDark, 5%) !important;
  }
}

.header-message {
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 12px 0 14px;
  font-size: 14px;
  text-align: center;
  color: @white;
  background: @red;

  a {
    color: @white;
    text-decoration: underline;
  }
}

body:not(.detached-header) .header-message ~ .main-header {
  border-top: 2px solid @white;
  border-bottom: 5px solid @red;
}

.login-page-bg .header-message {
  border-bottom: 4px solid @white;
}

.header-message__title {
  margin-bottom: 1px;
  font-size: 17px;
  font-weight: bold;
}

.navbar__acc-link {
  float: left;
  display: inline-block;
  margin-right: 40px;
  padding: 0 6px;
  color: @grayDark;
  border-radius: 2px;
  background: darken(@grayBg, 3%);

  &:hover {
    color: @grayDark;
    background: @grayBg;
  }
}

.navbar__acc-link--left {
  margin: 13px 0 0 40px;
}

.nav-popover {
  float: left;
  position: relative;
  margin: -2px 22px 0;
}

.nav-popover__dropdown {
  display: none;
  position: absolute;
  left: -260px;
  top: 44px;
  width: 370px;
  height: 350px;
  background: @white;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 260px;
    top: -8px;
    border-right: 8px solid transparent;
    border-bottom: 8px solid @white;
    border-left: 8px solid transparent;
  }
}

.nav-popover__dropdown__header {
  padding: 13px 18px 12px;
  color: #596771;
  border-bottom: 3px solid #596771;

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .btn {
    position: absolute;
    right: 8px;
    top: 10px;
    padding: 5px;
    line-height: 1;
    color: #596771;

    &:hover {
      color: darken(#596771, 10%)
    }
  }
}

.nav-popover__dropdown__content {
  position: absolute;
  top: 48px;
  bottom: 37px;
  width: 370px;
  overflow-y: auto;
}

.nav-popover__dropdown__item {
  .box-sizing(border-box);
  position: relative;
  padding: 10px 40px 10px 18px;
  font-size: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: #feffe8;
  border-bottom: 1px solid #e6e8ea;
}

.nav-popover__dropdown__label {
  display: inline-block;
  position: absolute;
  right: 9px;
  top: 12px;
  padding: 1px 3px;
  font-size: 11px;
  color: @white;
  line-height: 1;
  border-radius: 2px;
  background: #31cba1;
}

.nav-popover__dropdown__footer {
  margin: 7px 0 0;
  font-size: 10px;
  font-weight: bold;
  color: #8a8a8a;
}

.nav-popover__dropdown__control {
  .btn {
    position: absolute;
    bottom: 0;
    padding: 8px 0;
    color: @grayDark;
    background: #e6e6e6;
    border-radius: 0 0 4px 4px;

    &:hover {
      background: darken(#e6e6e6, 5%);
    }
  }
}

.inbox-lnk {
  display: block;
  position: relative;
  width: 25px;
  height: 24px;
  font-size: 19px;
  line-height: 1;
  color: lighten(@grayDark, 10%);

  &:hover {
    color: darken(@grayDark, 7%) !important;
  }
}

.inbox-lnk--new {
  &:after {
    content: "";
    position: absolute;
    left: -6px;
    top: -2px;
    width: 10px;
    height: 10px;
    background: @red;
    background-clip: padding-box;
    border: 2px solid @white;
    border-radius: 10em;
  }
}

.inbox-lnk__counter {
  .box-sizing(border-box);
  display: block;
  position: absolute;
  right: -2px;
  bottom: 0;
  min-width: 12px;
  padding: 0 2px;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: @grayDark;
  background: @white;
  border: 1px solid @grayBorder;
  border-radius: 2px;
}

.ta-warning {
  position: relative;
  margin-bottom: -4px;
  padding: 3px 15px 5px;
  font-size: 13px;
  text-align: center;
  color: darken(@orange, 41%);
  background: lighten(@yellow, 35%);
  border-bottom: 1px dashed darken(@orange, 41%);
  z-index: 50;
}

.ta-warning__text {
  color: darken(@orange, 38%);
  font-size: 11px;
}

.ta-warning__header {
  margin: 1px 0 2px;
  font-weight: bold;
  text-transform: uppercase;
}
