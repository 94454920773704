//
// Tables.less
// Tables for, you guessed it, tabular data
// ----------------------------------------


// BASE TABLES
// -----------------

table {
  max-width: 100%;
  background-color: @tableBackground;
  border-collapse: collapse;
  border-spacing: 0;
}

// BASELINE STYLES
// ---------------

.table {
  width: 100%;
  margin-bottom: @baseLineHeight;
  // Cells
  th,
  td {
    padding: 8px;
    line-height: @baseLineHeight;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid @tableBorder;
  }
  th {
    .bold();
  }
  // Bottom align for column headings
  thead th {
    vertical-align: bottom;
  }
  // Remove top border from thead by default
  caption + thead tr:first-child th,
  caption + thead tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + thead tr:first-child td,
  thead:first-child tr:first-child th,
  thead:first-child tr:first-child td {
    border-top: 0;
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top: 2px solid @tableBorder;
  }
}



// CONDENSED TABLE W/ HALF PADDING
// -------------------------------

.table-condensed {
  th,
  td {
    padding: 4px 5px;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: @tableBackgroundHover;
    }
  }

  tbody > tr > td {
    vertical-align: middle;
  }

  border-bottom: 1px solid @tableBorder;
}


// BORDERED VERSION
// ----------------

.table-bordered {
  border: 1px solid @tableBorder;
  border-collapse: separate; // Done so we can round those corners!
  *border-collapse: collapsed; // IE7 can't round corners anyway
  border-left: 0;
  .border-radius(4px);
  th,
  td {
    border-left: 1px solid @tableBorder;
  }
  // Prevent a double border
  caption + thead tr:first-child th,
  caption + tbody tr:first-child th,
  caption + tbody tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + tbody tr:first-child th,
  colgroup + tbody tr:first-child td,
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    -webkit-border-top-left-radius: 4px;
            border-top-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
  }
  thead:first-child tr:first-child th:last-child,
  tbody:first-child tr:first-child td:last-child {
    -webkit-border-top-right-radius: 4px;
            border-top-right-radius: 4px;
        -moz-border-radius-topright: 4px;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    .border-radius(0 0 0 4px);
    -webkit-border-bottom-left-radius: 4px;
            border-bottom-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
  }
  thead:last-child tr:last-child th:last-child,
  tbody:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 4px;
            border-bottom-right-radius: 4px;
        -moz-border-radius-bottomright: 4px;
  }
}