@media print {
  .no-print,
  .user-nav,
  .page-footer,
  .footer-placeholder,
  .item__confirm-actions,
  .map-view-wrapper .control,
  .confirm-info .reservation-control {
    display: none;
  }

  .main-header {
    display: none;
  }

  .confirm-info .info-sidebar {
    width: 80%;
  }

  .icon-am-free-breakfast,
  .icon-am-wifi-internet,
  .icon-am-internet,
  .icon-am-free-parking {
    background: none;
    width: auto;
    white-space: nowrap;

    &:after {
      content: ","
    }

    &:last-child:after {
      content: "";
    }
  }

  .icon-am-free-breakfast:before,
  .icon-am-wifi-internet:before,
  .icon-am-internet:before,
  .icon-am-free-parking:before {
    content: attr(data-original-title);
  }

  .confirmation-alt {
    .rating-common {
      width: 70px;

      .rating0h:before {
        content: "☆";
      }
      .rating1:before {
        content: "★";
      }
      .rating1h:before {
        content: "★☆";
      }
      .rating2:before {
        content: "★★";
      }
      .rating2h:before {
        content: "★★☆";
      }
      .rating3:before {
        content: "★★★";
      }
      .rating3h:before {
        content: "★★★☆";
      }
      .rating4:before {
        content: "★★★★";
      }
      .rating4h:before {
        content: "★★★★☆";
      }
      .rating5:before {
        content: "★★★★★";
      }
    }
  }
}

.confirmation-page-modal {
  @media print{
    .modal-wrapper {
      position: absolute !important;
      overflow-y: visible !important;
    }

    .modal-destinations{
      width: 100%;
    }
  }
}

