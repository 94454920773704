html {
  min-height: 100%;
  position: relative;
}

.alert-overlay{
  position: fixed;
  top: 199px;
  left: 50%;
  text-align: center;
  .bold();
  width: 300px;
  margin-left: -300px/2;
  z-index: 1060;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.prm-normalization {
  .container,
  .modal-destinations.on-page {
    padding-right: 170px;
  }

  .main-header {
    .container {
      width: 1168px;
      padding-right: 0;
    }
  }
}

.waiting {
  cursor: wait !important;

  a,
  input,
  button {
    cursor: wait !important;
  }
}

.noise-bg {
  background: #f1f1f1 url(../img/bg_noise_pattern.png);
}

.destination-list-item {
  .clearfix();

  margin: 0 0 15px 0;
  border: 1px solid #c8c8c8;
  background: @white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.22);

  .destination-image {
    float: left;
    position: relative;
    width: 170px;
    cursor: pointer;
  }

  .destination-info {
    float: left;
    position: relative;
    width: 658px;
    height: 170px - 25;
    margin: 25px 0 0 21px;

    .left-col {
      float: left;
      width: 458px;
    }

    .flights-info {
      display: inline-block;
      float: left;
      width: 170px;
      font-size: 14px;
      .bold();
      text-transform: uppercase;
      color: @grayLight;
      .text-overflow();
    }

    .item-visibility {
      float: left;
      display: none;
      color: @gray;
      cursor: pointer;

      &:hover {
        color: @grayDark;
      }
    }

    .title {
      width: 488px;
      margin: 22px 0 0 0;
      color: @blue;
      font-size: 28px;
      line-height: 40px;
      .text-overflow();

      span {
        font-size: 28px;
      }

      a {
        color: @blue;
        font-size: 38px;

        &:hover {
          color: darken(@blue, 15%);
        }
      }
    }

    .details {
      float: left;
      margin: 4px 0 0 0;
      color: @grayLight;

      .item {
        padding: 0 9px 0 11px;
        font-size: 11px;
        .bold();

        &:first-child {
          padding-left: 0;
          border-right: 1px solid @grayBorder;
        }

        .origin-code {
          margin-left: 7px;
        }

        i {
          margin: 0 -2px 0 1px;
        }
      }
    }

    .right-col {
      text-align: right;
      padding-right: 20px;

      h5 {
        color: @grayLight;
        font-size: 14px;
        .bold();
        text-transform: uppercase;
      }

      .price {
        display: block;
        margin: 18px 0 15px 0;
        color: @purple;
        font-size: 38px;
      }

      .person {
        color: @grayLight;
        font-size: 11px;
        .bold();
      }
    }
  }
}

.destination-toolbar {
  clear: both;
  position: absolute;
  bottom: 0;
  width: 102%;

  a {
    position: relative;
    display: inline-block;
    margin: 0 11px 0 0;
    padding: 0 16px 10px 0;
    color: @gray;
    .bold();
    font-size: 12px;

    &:hover {
      color: @grayDark;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 28px;
      background: #e0dfdf;
    }

    &.no-friends,
    &.not-ready{
      color: @grayLight;
      cursor: default;
    }

    &.fb-link-searchresults{
      color: @linkColor;

      &:hover{
        color: @linkColorHover;
      }
    }

    .popup-message {
      position: absolute;
      left: -14px;
      bottom: 40px;
      width: 160px;
      padding: 3px 10px;
      font-size: 11px;
      color: @white;
      background: #000;
      background: rgba(0,0,0,0.8);
      border-radius: 3px;
      text-shadow: none;
      cursor: default;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -9px;
        left: 12px;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-top: 9px solid #000;
        border-top: 9px solid rgba(0,0,0,0.8);
        border-right: 9px solid transparent;
      }

      &.rate {
        width: 100px;
        text-align: center;

        .star-rating {
          margin: 2px auto 4px auto;
        }
      }

      &.complete {
        font-weight: normal;
        text-align: center;
      }
    }
  }

  i {
    padding-right: 4px;
    vertical-align: middle;
  }
}

.destinations-list {
  &.in-progress {
    .opacity(45);
    cursor: default;

    a {
      cursor: default;
    }
  }
}

.custom-facebook-mini{
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    a {
      color: darken(@graySecondary, 15%);
    }
  }

  a{
    &:after {
      display: none !important;
    }
  }
}

.custom-facebook-mini-dropdown{
  display: none;
  position: absolute;
  top: 28px;
  left: -13px;
  z-index: 200;
  cursor: default;
  width: 252px;
  background: #fdfdfd;
  box-shadow: 0 3px 20px rgba(0, 0, 20, 0.8);

  &:after{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -16px;
    width: 0px;
    height: 0px;
    border-color: transparent transparent #3a70d0 transparent;
    border-width: 8px;
    border-style: solid;
  }

  .cfmd-head{
    color: @white;
    font-size: 12px;
    .bold();
    text-align: center;
    padding: 10px 0;
    background: #3a70d0;
  }

  .cfmd-body{
    max-height: 200px;
    overflow-y: auto;
    padding: 6px 6px 4px 6px;

    td{
      vertical-align: middle;
      padding: 0 10px 3px 0;
    }

    span{
      display: inline-block;
      font-size: 12px;
      line-height: 1.2em;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.destination-list-item {
  &:hover {
    .item-visibility {
      display: inline-block;
    }
  }
}

.destination-list-item {
  &.selected {
    .destination-info {
      .flights-info {
        width: 215px;
        color: @greenDark;
      }

      .title {

        a {
          color: @greenDark;

          &:hover {
            color: darken(@greenDark, 15%);
          }
        }
      }

      .price{
        color: @greenDark;
      }
    }
  }

  &.filtered {
    height: 60px;

    .destination-image {
      cursor: default;

      &.spinner {
        width: 170px;
        background: #dadada;
      }

      overflow: hidden;
      height: 60px;

      img {
        .opacity(70);

        margin-top: -55px;
      }
    }

    .dest-message {
      display: block;
      margin-top: 19px;
      margin-right: 20px;
      font-size: 14px;
      line-height: 23px;
      .bold();
      color: @grayLight;

      b {
        margin-left: 20px;
      }

      &.alt {
        float: right;
        width: 190px;
        margin-top: 11px;
        line-height: 19px;
        font-size: 12px;
        text-align: right;
      }
    }

    .destination-info {
      height: auto;
      margin-top: 0;

      .title {
        width: 340px;
        margin-top: 9px;
        font-size: 20px;
        font-weight: bold;

        span {
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
  }

  &.excluded{
    .destination-info{
      width: 766px;
    }

    .dest-message{
      width: 167px;
      margin-right: 0;
      text-transform: uppercase;
    }

    .dest-locked{
      display: block;
      text-align: left;
      padding: 0 0 0 17px;

      i{
        margin: -4px 4px 0 0;
      }
    }
  }
}

.hotel-description-box_{
  position: relative;
  width: 966px;
  border: 1px solid @greenDark;
  box-shadow: 0 5px 4px -3px rgba(0,0,0,0.15);

  .destination-list-item{
    margin: 0;
    border: none;
    box-shadow: none;

    .destination-image{
      overflow: hidden;
      height: 90px;

      img{
        margin-top: -21px; /* (132-90)/2 */
      }
    }

    .title{
      a{
        font-size: 13px !important;
      }
    }

    .price-wrap{
      position: absolute;
      right: 0;
      bottom: 3px;
      text-align: center;

      .price{
        display: block;
        font-size: 24px;
      }
    }

    .modal-control{
      font-size: 11px;
    }
  }

  .destination-info {
    height: auto;
    margin: 12px 0 0 18px;
    width: 610px;

    .title {
      line-height: 19px;
      .text-overflow();
      .bold();
      margin: 0 0 4px;
      max-width: 378px;
    }
  }
}


// Default content

.main-content {
  margin: 60px 0 40px 0;

  h1 {
    font-size: 28px;
  }
  h1,h2,h3,h4,h5 {
    margin-bottom: 0.3em;
    margin-top: 2em;
  }
}

// Corporate pages content

.content-box {
  position: relative;
  margin: 0 0 40px 0;
  padding: 20px 41px 60px 41px;
  border: 1px solid #cccccc;
  background: @white;

  h4, h5 {
    font-size: 16px;
    .bold();
    margin-bottom: 1em;
    margin-top: 1.4em;
  }

  h1, .h1 {
    font-size: 29px;
    .bold();
    text-align: center;
    text-transform: uppercase;
    margin-top: 0.7em;
    margin-bottom: 1.2em;
    letter-spacing: 0.15em;
  }

  h2, .h2 {
    font-size: 24px;
    .bold();
    margin-top: 0;
    margin-bottom: 0.6em;
  }

  h3, .h3 {
    font-size: 20px;
    .bold();
    margin-top: 0;
    margin-bottom: 0.8em;
  }
}


/* Hotels - Search result page */

.advisor-rating-wrap{
  font-size: 11px;
  color: @grayLight;
  margin: 0 0 3px 0;

  .rating-advisor{
    position: relative;
    top: 2px;
  }
}

.hotels-list-banner{
  margin: 0 0 9px;
}

.dest-map-preview {
  z-index: 0;
  width: 688px;
  height: 139px;
  margin-bottom: 20px;
  cursor: pointer;
}

.dest-map__message {
  position: absolute;
  bottom: 75px;
  left: 50%;
  width: 300px;
  margin-left: -165px;
  padding: 5px 15px;
  text-align: center;
  background: @white;
  border-bottom: 5px solid @red;
  z-index: 5;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
}


.dest-map-wrapper{
  position: relative;
  z-index: 0;
  margin: 0 0 14px 0;
  border: 1px solid #c8c8c8;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  width: 688px;
  height: 139px;
  overflow: hidden;

  &.expanded {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    width: 744px;
    height: 840px;

    .dest-map-canvas {
      width: 744px;
      height: 840px;
    }
  }

  .dest-map-canvas {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 694px;
    height: 414px;

    img{
      max-width: none;
    }
  }

  .overlay-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    z-index: 10;

    .map-mode-switcher{
      position: static;
      margin: 14px 0 0 14px;
    }

    .dest-map-zoom{
      position: static;
      float: left;
      margin: 8px 0 0 14px;
    }

    .map-legend-list{
      position: static;
      float: left;
      margin: -122px 14px 0 909px;

      &.opened{
        margin: -122px 14px 0 649px;
      }
    }

    .map-message-box{
      position: relative;
      width: 714px;
    }

    .map-message-container {
      position: static;
      min-width: 240px;
      max-width: 280px;
      margin: -25px auto 0 auto;
      padding: 8px 10px;
      text-align: center;
      background: @white;
      border-radius: 3px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);


      .spinner {
        display: inline-block;
        vertical-align: top;
      }

      .map-message {
        padding-left: 30px;
      }
    }
  }
}

.hotel-description-box {
  display: none;
  position: absolute;
  width: 320px;
  padding: 10px;
  border: none;
  background: @white;
  box-shadow: 0 0 4px 1px rgba(0,0,0,0.3);

  .destination-list-item {

    .price-wrap {
      position: static;
      top: 0;
      bottom: 0;
      text-align: left;

      .price{
        display: inline;
        font-size: 21px;
        color: @blue;
      }
    }

    .destination-image{
      overflow: hidden;
      height: 90px;

      img{
        margin-top: -21px; /* (132-90)/2 */
      }
    }
  }

  .destination-info {
    width: 189px;
    margin: -2px 0 0 10px;

    .title{
      max-width: 168px;
      margin-bottom: 1px;
      font-size: 13px;
    }
  }

  .advisor-rating-wrap {
    display: inline-block;
    height: 16px;
    margin: 5px 0 6px -3px;
    padding: 0 0 0 8px;
    border-left: @grayBorder 1px solid;
  }
}

.hotel-description-box--top {
  .hotel-description-box__tip {
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -7px;
    border-top: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
}

.hotel-description-box--bottom {
  .hotel-description-box__tip {
    position: absolute;
    left: 50%;
    top: -7px;
    width: 0;
    height: 0;
    margin-left: -7px;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
}

.hotel-description-box--left {
  .hotel-description-box__tip {
    position: absolute;
    right: -7px;
    top: 50%;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-top: 7px solid transparent;
    border-left: 7px solid #fff;
    border-bottom: 7px solid transparent;
  }
}

.hotel-description-box--right {
  .hotel-description-box__tip {
    position: absolute;
    left: -7px;
    top: 50%;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #fff;
  }
}

.map-legend-list{
  position: absolute;
  right: 14px;
  top: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  cursor: pointer;

  .legend-head{
    font-size: 14px;
    .bold();
    color: @white;
    line-height: 28px;
    cursor: pointer;
    padding: 0 17px;
    background: @blue;
    border-radius: 4px 4px 0 0;

    span{
      margin-left: 15px;
    }

    i{
      vertical-align: middle;
    }
  }

  .legend-body{
    background: @white;
    border-radius: 0 0 4px 4px;

    .legend-list{
      list-style: none;
      margin: 0;
      padding: 7px 0;
      border-top: 1px solid #eeeeee;

      &:first-child{
        border-top: none;
      }

      li{
        position: relative;
        line-height: 21px;
        min-height: 21px;
        padding: 7px 0;

        .legend-marker{
          position: absolute;
          top: 7px;
          left: 17px;
        }

        label{
          padding: 0 10px 0 45px;
          width: 250px;

          span{
            display: inline-block;
            width: 225px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .toggle-part{
    display: none;
  }

  &.opened{
    cursor: default;
    .toggle-part{
      display: inline-block;
    }

    .legend-head{
      .icon-legend-darr{
        .rotate(180deg);
      }
    }
  }
}

div[class^="map-marker"]{
  cursor: pointer;
}

.marker-tooltip {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 16px;
  z-index: 100;
  width: 160px;
  padding: 6px 10px 8px;
  margin-left: -90px;
  line-height: 1.3;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  background: @black;
  background: rgba(0,0,0,0.8);
  text-shadow: none;
  cursor: default;
  .mac-smooth();

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border-top: 5px solid @black;
    border-top: 5px solid rgba(0,0,0,0.8);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.hide-more-link {
  display: none;
}

.show-more-link,
.hide-more-link{
  i{
    position: relative;
    top: 1px;
  }
}

.hotel-reviews-wrapper{
  padding: 0 0 40px 0;
}

.search-block {
  .clearfix();
  position: relative;

  & + .select-list-block {
    margin-top: -9px;
  }

  input[type="text"]{
    float: left;
    width: 170px;
    line-height: normal;
    padding-right: 32px;
  }

  .btn-trigger {
    .opacity(90);
    .reset-opacity();
    display: block;
    position: absolute;
    right: 4px;
    top: 4px;

    &:hover {
      .opacity(100);
      .reset-opacity();
    }
  }

  .select-list-block {
    top: 27px;
  }
}

.select-list-block {
  .chain,
  .keyword {
    display: block;
    margin: -1px 0 -2px 33px;
    font-size: 11px;
    color: #333;

    .rvalue {
       color: #999;
    }
  }
  .dates {
    display: block;
    margin: -1px 0 -2px 33px;
    font-size: 11px;
    color: #999;
  }

  .expired {
    color: #ddd;
    text-decoration: line-through;
  }
}

ul.tagit{
  li{
    &.tagit-choice{
      margin-top: 6px;
    }
  }
}

.search-form {
  position: relative;
  margin: 20px 0 0 0;
  z-index: 30;

  .error{
    margin: 0 0 15px 10px;
    color: @red;
  }

  .row {
    .clearfix();
    margin: 12px 0 12px -10px;
    position: relative;

    // iOS dirty fix
    &.top-level {
      z-index: 4;
    }

    &.bottom-level {
      z-index: 2;
    }

    .form-item {
      float: left;
      margin-left: 10px;

      label{
        margin-bottom: 2px;
        .bold();
      }
    }

    .bottom-line {
      .clearfix();
      margin-top: -2px;
      margin-bottom: 3px;

      .checkbox {
        float: left;
        color: #ececec;
      }

      .light-selector {
        position: relative;
        float: right;
      }

      .selector-label {
        position: relative;
        padding-right: 15px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 7px;
          right: 0;
          border-top: 5px solid @linkColor;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }

        &:hover {
           &:after {
            border-top-color: @linkColorHover;
           }
        }
      }
    }
  }

  .input-block {
    position: relative;

    &.prepended {
      > i {
        position: absolute;
        right: 8px;
        top: 8px;
        .opacity(80);
        .reset-opacity();
      }

      .input-size-default {
        padding-right: 35px;
        width: 191px;
      }
    }

    &.appended {
      > i {
        position: absolute;
        right: 8px;
        top: 9px;
        .opacity(80);
        .reset-opacity();
      }

      &.appended-low{
        > i {
          right: 6px;
          top: 4px;
        }
      }
    }

    .icon-search-globe {
      &:hover,
      &.active {
        .opacity(100);
      }
    }
  }

  .radio-group {
    margin-top: 0;
    margin-bottom: 5px;

    span {
      line-height: 19px;
      margin-right: 15px;
    }
  }

  .custom-checkbox {
    margin-bottom: 6px;
  }

  .section-header {
    font-size: 16px;
    margin: 0 0 6px 20px;
    padding: 16px 0 0 0;
    .bold();
    border-top: 1px solid #D3D8DB;
  }

   .select-list-block {
     top: 37px;

     a {
      color: @blue;
     }
   }

   .input-list {
    .input-size-micro {
      margin-right: 6px;
    }
   }

  .input-size-default {
    width: 218px;
  }

  .input-size-micro {
    width: 20px;
  }

  .input-size-short {
    width: 95px;
  }

  .input-size-medium {
    width: 140px;
  }

  .input-size-huge {
    width: 464px;
  }

  .inline-box {
    margin-top: 29px;
  }

  .selectBox {
    width: 111px;
    min-width: 111px;

    &.input-size-default {
      width: 234px;

      .selectBox-label{
        .text-overflow();
        width: 200px;
      }
    }

    &.input-size-medium {
      width: 140px;
    }
  }

  .room-item {
    position: relative;
    margin: 0 0 0 -20px !important;
    padding: 0 0 21px 0;

    + .room-item {
      margin-top: 5px !important;
    }

    .wrapper {
      margin-left: 10px;
    }

    .block-control {
      position: absolute;
      right: 10px;
      top: 14px;

      a {
        display: block;
        padding: 5px;
        font-size: 24px;
        .bold();
      }
    }
  }

  .sub-control {
    margin: -6px 0 10px -10px !important;

    .lnk-add {
      .ico {
        .bold();
        font-size: 16px;
      }
    }
  }

  .form-control {
    position: relative;
    margin-top: -5px;
    padding: 17px 0 3px 0;
    border-top: 1px solid #c8cfd3;

    &:after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0px;
      width: 100%;
      height: 1px;
      background: @white;
    }

    .btn {
      min-width: 140px;
      padding-left: 0;
      padding-right: 0;
    }

    .right-col {
      float: right;
      width: 326px;
      margin-top: 2px;
      line-height: 1.5;
    }

    &.w-comment{
      .right-col {
        margin-top: -1px;
      }
    }
  }

  .input-selector {
    position: relative;

    &:focus {
      outline: none;
    }


    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 8px;
      top: 16px;
      width: 0;
      height: 0;
      border-top: 6px solid @gray;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    select {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .input-placeholder {
      color: @grayLight;
    }
  }

  .selector-value {
    display: block;
    height: 37px;
    line-height: 36px;
    padding: 0 25px 0 10px;
    margin: 0;
    background: @white;
    border: 1px solid #bbbbbb;
    border-radius: 3px;
    .box-sizing(border-box);
    .user-select(none);
    cursor: pointer;
  }

  input[type="text"],
  input[type="date"],
  .selector-value  {
    &:focus,
    &.focus {
      border-color: #5FC5FF;
      box-shadow: 0 0 0 1px #5FC5FF;
    }

    &.error {
      background-color: #ffe6e5;
      border-color: #f14848;
      box-shadow: 0 0 0 1px #f14848;
      .placeholder(#f14848);
    }
  }
}

.input-block {
  .popup-selector {
    position: absolute;
    top: 50%;
    right: -397px;
    margin-top: -208px;
    width: 380px;
    background: @white;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.4);
    z-index: 15;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: -12px;
      margin-top: -12px;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid @white;
    }

    h3 {
      margin: 15px 0 10px 15px;
      padding: 0;
      font-size: 16px;
      .bold();
      color: @grayDark;
    }

    a {
      display: block;
      color: @blue;
      padding: 2px 5px;
      border-radius: 3px;

      &:hover {
        color: @white;
        background: @blue;
      }

      &.lnk-default {
        &:hover {
          color: darken(@blue, 10%);
          background: transparent;
        }
      }
    }

    .dest-list {
      display: inline-block;
      width: 172px;
      margin: 0 0 12px 10px;
      padding: 0;
      vertical-align: top;
      list-style: none;
    }

    .title {
      color: @grayDark;
      .bold();
      padding: 0 0 8px 5px;
    }
  }
}

.search-form {
  &.compact {
    padding-top: 23px;

    .row {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

   .error {
      label {
        color: @red;
      }

      .checkbox {
        color: #ececec;
      }
    }

  .row {
    &.errors-block {
      color: @red;
      left: 10px;

      .icon-small-exclamation {
        margin-right: 4px;
      }

      ul {
        margin: 10px 0 15px 15px;
      }

      li {
        margin-bottom: 3px;
      }
    }
  }
}

.float-tooltip {
  position: absolute;
  background: @white;
  top: 47px;
  right: 0;
  width: 310px;
  padding: 20px 20px 15px 20px;
  border-radius: 6px;
  z-index: 10;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6) !important;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    right: 46px;
    border-bottom: 12px solid @white;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }

  .errors-block {
    color: #ff1818;

    .icon-small-exclamation {
      margin-right: 4px;
    }

    ul {
      margin: 10px 0 0 15px;
    }

    li {
      margin-bottom: 3px;
    }
  }
}

.slider-item,
.filter-slider {
  .slider-wrapper {
    margin: 0 0 5px 0;
    padding: 7px;
  }

  .ui-widget-content {
    height: 4px;
    background: @grayBorder;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.15);
    border-radius: 6px;
  }

  .ui-slider-range {
    #gradient > .vertical(#4e9dde, #4086d6);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.3);
  }

  .ui-slider-handle {
    top: -9px;
    width: 21px;
    height: 22px;
    box-shadow: none;
    background: url(../img/sprite_handlers.png) 0 0 no-repeat;
    border: none;
    cursor: pointer;

    &.ui-state-hover {
      background-position: 0 -30px;
    }

    &.ui-state-active {
      background-position: 0 -60px;
    }

    &.second-handle {
      background-position: -41px 0;

      &.ui-state-hover {
        background-position: -41px -30px;
      }

      &.ui-state-active {
        background-position: -41px -60px;
      }
    }
  }
}

.step-label {
  color: @gray;
  font-size: 12px;

  td {
    width: 24%;

    &:last-child {
      text-align: right;
    }
  }
}

.search-form-inline {
  position: relative;
  height: 46px;
  background: #444;
  border: 1px solid rgba(0,0,0,0.3);

  .search-form {
    margin: 0;
    padding-top: 9px;
  }

  .row {
    float: left;
    margin: 0;
  }

  .input-block {
    &.appended {
      > i {
        top: 4px;
        right: 6px;
      }
   }
 }

  .form-control {
    float: left;
    padding: 0;
    margin: -1px 0 0 10px;
    border-top: none;

    &:after {
      display: none;
    }

    .btn {
      min-width: 105px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .input-size-huge {
    width: 444px;
  }

  .input-size-short {
    width: 91px;
  }

  .type-selector {
    position: relative;
    width: 132px;
    height: 26px;
    min-width: 132px;
    padding: 0 9px;
    line-height: 26px;
    font-size: 12px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 9px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid @grayDark;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .float-tooltip {
    position: absolute;
    top: 57px;
    left: auto !important;
  }
}

.sidebar-promo-separator{
  display: block;
  height: 1px;
  margin: 0 9px;
  background: #dddddd;
}

.search-results-container{
  .clearfix();
  background: #444;
  padding: 8px 0 8px 9px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  &.minified {
    label {
      display: none;
    }
  }

  form{
    margin: 0;
  }

  .input-item{
    float: left;
    position: relative;
    margin-left: 5px;

    &.input-item-first{
      margin: 0;
    }

    input[type="text"],
    input[type="date"]{
      height: 28px;
      border: 1px solid @grayDark;
    }

    .selector-value {
      line-height: 27px;
      height: 28px;
      border: 1px solid @grayDark;
    }

    &.passengers{
      .selector-value{
        &:before{
          top: 7px !important;
          left: 7px !important;
        }
      }
    }

    .input-selector{
      &:after{
        top: 12px;
      }
    }

    .embedded-icon{
      i{
        right: 7px;
        top: 5px;
      }
    }

    .select2-choices{
      min-height: 28px;
      border: 1px solid @grayDark;
    }

    .select2-container-multi{
      .select2-choices{
        input{
          height: 24px;
        }
      }

      .select2-search-choice{
        line-height: 20px;
      }
    }

    .select2-choice{
      line-height: 26px;
      min-height: 28px;
      border: 1px solid @grayDark;
    }
  }

  .select2-default {
    color: @grayLight;
  }

  .select2-search-choice-close{
    top: 4px;
  }

  .input-item-date{
    input{
      width: 111px;
    }
  }

  .passengers{
    .selector-value{
      width: 74px;
    }
  }

  .input-item-options{
    .selector-value{
      width: 85px;
    }
  }

  .btn-secondary{
    width: 75px;
    padding: 4px 0 2px 0;
  }

  .input-item-destination-hotels{
    input{
      width: 482px;

      &.error ~ .lnk-distance {
        .selector-value{
          background: #ffe6e5;
        }
      }
    }
  }

  .input-item-passengers-hotels{
    .selector-value{
      width: 156px;
    }
  }
}

.search-results-container {
  .input-item {
    .lnk-distance {
      .selector-value {
        height: 26px;
      }
    }
  }
}

.search-results-container__row {
  float: left;
  margin: 5px 0 0 -5px;

  .input-item label {
    display: block;
    margin-bottom: 0;
    color: @white;
    font-size: 11px;
  }

  .input-selector {
    display: block !important;
  }
}

.input-item__ico-wrapper {
  position: relative;
  top: 2px;
  padding: 3px;
  margin: 0 0 0 3px;

  &:hover {
    .opacity(70);
  }
}

.input-item__ico {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../img/icon_expand.png) 0 0 no-repeat;
  background-size: cover;
}

.input-item__ico--expanded {
  .input-item__ico {
    .rotate(180deg);
  }
}

.select-list-block {
  &.autocomplete {
    font-family: @baseFontFamily;
    color: @grayDark;
    height: auto;
    padding-bottom: 10px;

    ul {
      width: auto;
      margin-left: -10px;
      margin-right: -10px;

      li {
        position: relative;
        vertical-align: bottom;
        width: 385px;
        height: auto;
        margin: 0 0 1px 0;
        padding-top: 3px;
        padding-bottom: 2px;
        border-radius: 0;

        &:hover,
        &.selected {
          color: @white;

          .title {
            color: @white;
          }
        }
      }

      .title {
        .text-overflow();

        position: absolute;
        top: 3px;
        left: 0;
        width: 64px;
        padding-left: 3px;
        color: @grayLight;
        font-size: 11px;
        text-align: right;
      }

      .value,
      .value-alt {
        .text-overflow();

        display: inline-block;
        width: 308px;
        margin-left: 78px;
        vertical-align: top;
        -webkit-font-smoothing: antialiased;
      }

      .value-alt {
        margin-left: 8px;
      }
    }

    &.autocomplete-vertical{
      ul{
        width: 100%;
        margin: 0 0 9px;
        margin-bottom: 10px;
        padding-top: 26px;

        li{
          width: auto;
          padding: 3px 0;

          &:hover,
          &.selected{
            background: @blue;

            .title,
            .value,
            .chain,
            .keyword,
            .rvalue,
            .dates {
              color: @white;
            }

            .value{
              &:after{
                background-image: url(../img/sprite_autocomplete_white.png);
              }
            }
          }
        }

        .title{
          .box-sizing(border-box);
          top: -28px;
          color: @grayDark !important;
          text-align: left;
          line-height: 27px;
          font-size: 13px;
          .bold();
          width: 100%;
          padding-left: 9px;
          background: #dddddd
        }

        .value{
          display: block;
          cursor: pointer;
          color: @grayDark;
          width: auto;
          margin-left: 0;
          padding-left: 33px;

          &:after{
            content: "";
            display: inline-block;
            position: absolute;
            left: 8px;
            top: 4px;
            width: 16px;
            height: 16px;
            background-image: url(../img/sprite_autocomplete.png);
            background-repeat: no-repeat;
            background-position: 20px 0;
            background-size: 160px 16px;
          }

          &.title-ar{
            &:after{
              background-position: -64px 0;
            }
          }

          &.title-c{
            &:after{
              background-position: -80px 0;
            }
          }

          &.title-a{
            &:after{
              background-position: -112px 0;
            }
          }

          &.title-cl{
            &:after{
              background-position: -96px 0;
            }
          }

          &.title-t{
            &:after{
              background-position: -32px 0;
            }
          }

          &.title-b{
            &:after{
              background-position: -48px 0;
            }
          }

          &.title-p{
            &:after{
              background-position: 0 0;
            }
          }

          &.title-h{
            &:after{
              background-position: -128px 0;
            }
          }

          &.title-l{
            &:after{
              background-position: -16px 0;
            }
          }

          &.title-pt{
            &:after{
              background-position: -144px 0;
            }
          }
        }
      }
    }
  }
}

.autocomplete-huge{
  width: 474px !important;
}

.select-list-block {
  &.select-dropdown-narrow {
    width: 135px;
    height: auto;
    padding-left: 4px;
    padding-top: 5px;

    ul {
      width: 88px;
    }

    li {
      width: 122px;
      height: 25px;
      line-height: 25px;
      padding-left: 5px;

      &.muted {
        background: lighten(@grayLight, 30%);

        a {
          color: @gray;
        }
      }
    }
  }
}

.dest-table-wrapper{
  max-height: 170px;
  overflow-y: scroll;
  margin: -10px;
}

.dest-table-item{
  .clearfix();
  font-size: 13px;
  padding: 4px 8px 4px 8px;
  border-top: 1px solid #ddd;

  &:first-child{
    border-top: 0;
  }

  .title{
    float: left;
    padding: 0 5px 0 0;

    a {
      display: block;
      width: 198px;
      .text-overflow();
    }
  }

  .preference{
    float: left;

    i {
      margin-right: 1px;
    }
  }

  .price{
    float: right;
    text-align: right;
    width: 65px;
    color: @gray;
  }
}

.head-block--alert{
  text-align: center;
  .bold();
  background: #fffede;

  a{
    color: @textColor;
    text-decoration: underline;
  }
}

.expand-search {
  margin: 9px 0 0;
  padding: 10px 12px;
  border: 1px solid @grayBorder;
  background: @white;
}

.expand-search__text {
  float: left;
  width: 430px;
  margin-top: 6px;
  font-style: italic;
  color: @gray;
}

.expand-search__loader{
  float: left;
  width: 430px;
  margin-top: 6px;
  color: @gray;
}

.gg-spinner{
  padding-left: 22px;

  .spinner{
    top: 8px;
    left: 0;
  }
}

.spinner-wrap{
  position: relative;
  top: 9px;
  left: -18px;
}

.spinner-centered{
  top: 50% !important;
  left: 50% !important;
}

.float-content-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e1e1e1;
  z-index: 30;
}

.float-content {
  width: 700px;
  margin: 150px auto 20px;
  padding: 20px 20px 25px;
  background: @white;
  .box-sizing(border-box);
}

.float-content__title {
  .bold();
  margin: 2px 0 23px;
  font-size: 26px;
}

.float-content__secondary-title {
  margin: 0 0 15px 0;
  color: @gray;
  font-size: 20px;
}

.float-content__spinner {
  margin: 30px 0 25px;
}

.float-content__footer {
  font-size: 14px;
  line-height: 1.55;
}

.float-content__footer--large {
  margin: 30px 0 0;
  font-size: 16px;
}

.float-content__control {
  margin: 40px 0 20px;

  .btn {
    margin-right: 25px;

    &:last-child {
      margin: 0;
    }
  }
}

.float-content__note {
  margin: 20px 0 0;
  font-size: 14px;
  line-height: 1.5;
  color: @gray;
}

.preference__out-of-policy{
  float: right;
  text-align: right;
  margin-top: 4px;
  width: 100%;

  .icon-excl-circle-orange{
    position: relative;
    top: 2px;
  }

  .tooltip {
    text-align: left;
    min-width: 140px;
  }
}

.out-of-policy__text{
  display: inline-block;
  text-transform: uppercase;
  color: #760f11;
  font-size: 9px;
}

.booking-control{
  float: right;
  width: 89px;
}

.page-map-wrapper {
  position: relative;
  width: 744px;
  margin: -7px -12px -12px;
  overflow: hidden;
}

.flat-switcher {
  .clearfix();
}


.flat-switcher-item {
  float: left;
  height: 30px;
  width: 103px;
  margin: 10px 0;
  font-weight: bold;
  line-height: 30px;
  background: darken(@grayBg, 4%);
  text-align: center;

  &:hover {
    background: darken(@grayBg, 6%);
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.flat-switcher-item--active {
  color: @white;
  background: @blue;
  border-color: @blue;

  &:hover {
    color: @white;
    background: @blue;
  }
}

.preference-icon--shifted{
  position: relative;
  top: -2px;
}

.your-trips-page{
  h1{
    font-size: 24px;
    .bold();
    text-align: left;
    text-transform: capitalize;
    padding: 0 0 3px;
    margin: 0 0 13px;
    border-bottom: 1px solid #eeeeee;
  }

  .your-trips-subheader{
    font-size: 16px;
    .bold();
    margin: 28px 0 3px 0;
  }

  .form-control{
    .btn{
      padding-left: 0;
      padding-right: 0;
      margin-left: 210px;
    }

    a.btn{
      width: 114px;
    }

    button.btn{
      width: 116px;
    }
  }

  .highlight-error{
    display: block !important;
    margin: 15px 0 -15px 0;
  }
}

.page-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #f8f8f8;
}

.error-page {
  .box-sizing(border-box);
  position: absolute;
  left: 50%;
  top: 26%;
  z-index: 1000;
  width: 590px;
  padding: 33px 50px 23px 50px;
  margin: 0 0 0 -295px;
  background: @white;
  border: 1px solid #ebebeb;

  hr{
    margin: 25px 0;
    border-top: 1px solid #ebebeb;
  }

  h3{
    font-size: 28px;
    line-height: 30px;
    height: 30px;
    margin: 0 0 15px;

    i{
      position: relative;
      top: 3px;
    }
  }

  p{
    font-size: 18px;
    color: @grayLight;
    line-height: 30px;
  }
}

.page-box {
  margin-top: 42px;
  padding: 30px;
  border: 1px solid @grayBorder;
  background: @white;

  .btn-secondary {
    color: @white;
    text-decoration: none;

    &:hover {
      color: @white;
    }
  }
}

.map-popup {
  position: relative;
  width: 310px;
  margin-bottom: 9px;
}

.map-popup__destination-image {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.map-popup__img {
  display: block;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: 50% 50%;
}

.map-popup__info {
  float: left;
  width: 220px;
}

.map-popup__title {
  margin-bottom: 9px;
  font-size: 14px;
  color: @blue;
  line-height: 1.3;
  .text-overflow();
}

.map-popup__price {
  margin-top: 5px;
  font-size: 11px;
  color: @gray;
}

.map-popup__price-value {
  font-size: 16px;
  color: @grayDark;
}

.spinner--left {
  margin: -1px 5px 0 0;
}

.clearall-btn{
  margin-right: 10px;
}

.results-panel-item{
  float: left;
  margin-right: 10px;
}

.tab-group {
  margin: 0 0 5px 0;
  padding: 0 10px;
  border-bottom: 1px solid @grayBorder;
}

.ag-row {
  .tab-group {
    margin: 0 23px 0 10px;
  }
}

.tab-group__item {
  margin: 0 3px -1px 0;
  font-weight: normal;
  border-radius: 3px 3px 0 0;
  border: 1px solid @grayBorder !important;
  background: darken(@grayBg, 3%);

  &.active {
    font-weight: bold;
    border-bottom: 1px solid @white !important;
    background: @white;
  }
}

.content-block__header {
  margin: 0 0 25px 0;
  padding: 0 0 15px 0;
  font-size: 22px !important;
  font-weight: normal !important;
  border-bottom: 1px solid @grayBorder;
}

.content-block__subheader {
  display: block;
  color: @gray;
  font-size: 16px;
  line-height: 18px;
}

.row-wrapper {
  .clearfix();
  padding: 20px 0;
}

.row-wrapper--condensed {
  padding: 12px 0;
}

.row-col {
  float: left;
  margin-right: 15px;
  vertical-align: middle;
}

.row-col-narrow {
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table.table-common{
  margin-bottom: 0;

  > thead > tr > th,
  > tbody > tr > td{
    border: 1px solid @grayBorder !important;
  }

  > thead > tr > th{
    color: @grayDark;
    background: @grayBg;

    &:hover {
      background: darken(@grayBg, 2%);
    }

    &.sorted{
      background: darken(@grayBg, 5%);
    }

    .caret{
      float: right;
      margin-top: 8px;
    }

    .asc,
    .desc{
      position: relative;

      &:after{
        content: '';
        position: absolute;
        right: -13px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }

    .asc:after {
      top: 7px;
      border-bottom: 4px solid @grayDark;
    }

    .desc:after {
      top: 6px;
      border-top: 4px solid @grayDark;
    }
  }

  > tbody > tr > td{
    &.table-empty-msg{
      text-align: center;
      padding: 50px 0;
      background: @white;

      p{
        margin: 0;
      }
    }
  }
}

.table-common{
  th {
    vertical-align: top !important;
  }

  tr{
    &:hover{
      td{
        background: #F9FBFE;

        .table-common__name{
          padding: 1px 2px;
          margin: -2px 26px -2px -3px;
          border: 1px solid @grayBorder;
        }

        .table-common__name-wrapper{
          .glyphicon-pencil{
            display: inline-block;
          }
        }
      }
    }

    &.selected{
      td{
        background: #E8F2FD;
      }
    }

    &.past{
      td{
        color: grey;
      }
    }

    &.error{
      td{
        background: #FDF5F4;
      }
    }

    &.empty-message{
      span{
        display: block;
        padding: 30px 0;
        font-size: 20px;
        line-height: 19px;
        text-align: center;
        color: grey;
      }
    }
  }

  tr.clickable:hover {
    td {
      color: @blue;
    }
  }
}

.table-common-footer {
  background: darken(@grayBg, 1%);
}

.table-common__spinner-fix {
  .spinner-centered {
    margin-top: 25px;
  }
}

.table-common-footer__pagination {
  padding: 7px 10px 6px 8px;
  text-align: right;
  color: @gray;

  &:empty {
    padding: 0;
  }

  .dropdown {
    display: inline-block;

    .btn {
      padding: 3px 5px 3px 9px;
      font-size: 12px;
    }
  }

  .dropdown-toggle {
    color: @grayDark;
  }

  .dropdown-menu {
    min-width: 50px;

    a {
      color: @gray;

      &:hover {
        background: @grayLighter;
      }
    }
  }

  .separator-vertical{
    margin: -6px 12px -6px 5px;
  }
}

.table-common-footer__pages{
  .bold();
  display: inline-block;
  height: 22px;
  padding-left: 12px;
  margin: 0 4px 0 5px;
  border-left: 1px solid @grayBorder;
}

.table-common-footer__lnk {
  color: @gray;

  & + .table-common-footer__lnk {
    margin-left: 5px;
  }

  & + .table-common-footer__page-num {
    margin-left: 4px;
  }

  &.disabled,
  &.disabled:hover {
    color: lighten(@gray, 20%);
    cursor: default;
  }
}

.table-common-footer__page-num {
  padding: 0 2px;

  & + .table-common-footer__lnk {
    margin-left: 4px;
  }

  &.active {
    color: lighten(@gray, 20%);
    cursor: default;
  }
}

.td-control {
  float: right;

  a + a {
    margin-left: 5px;
  }

  &.small {
    position: relative;
    top: 1px;
  }
}

.td-fix {
  position: relative;
  padding-right: 17px !important;

  .td-control {
    position: absolute;
    top: 1px;
    right: 0;

    .tooltip {
      white-space: nowrap;
    }
  }
}

.half-block {
  width: 49%;
  margin: 0 7px 7px 0;
  min-height: 70px;
  line-height: 18px;
  font-size: 12px;
  border: 1px solid @grayBorder;

  .user-avatar {
    margin: 5px 0 0 5px;
  }
}

.half-block--dashed {
  border: 1px dashed @grayBorder;
}

.half-block__control-lnk {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 70px;
  font-size: 13px;
  text-align: center;
}

.half-block__user-info {
  float: left;
  margin: 8px 10px 0 12px;
  width: 40%;
}

.half-block__control {
  float: right;
  padding: 8px 7px 0 0;
  max-width: 37%;
}

.half-block .half-block__selector { // .half-block needed here
  margin: 20px 0 0 21px;
}

.user-avatar {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 28px;
  color: @white;
  cursor: default;
  background: #fd7b07;
}

.search-simple {
  margin: 0;
  white-space: nowrap;

  .btn {
    font-weight: normal;
  }

  .input-group-btn {
    margin-left: -1px;
  }
}

.content-prm {
  float: right;
  margin: -55px -170px 0 0;
}

.horizontal-prm {
  padding: 40px 0 10px;
  text-align: center;
}

.content-prm__slot {
  margin-bottom: 10px;
}

.sidebar-prm {
  float: right;
  width: 240px;
  margin: 10px 0 0 0;
  text-align: center;
}

.sidebar-overlay {
  display: none;
  position: absolute;
  top: 55px;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 180px 10px 0;
  font-size: 14px;
  text-align: center;
  background: rgba(255,255,255,0.92);
  z-index: 11;
}

.head-block--prm {
  text-align: center;
  padding: 6px 0 !important;
}

.spinner-wrapper__settings {
  position: relative;

  .spinner {
    top: 11px !important;
    left: 11px !important;
  }
}

.row-wrapper {
  padding: 8px 0;
}

.rate-error-tab{
  float: left;
  width: 550px;
}

.rate-error-tab__details {
  margin: 10px 0;
  padding: 10px 12px;
  border-radius: 3px;
  background: darken(@grayBg, 3%);
}

.rate-error-tab__details__folded {
  margin: 0 0 20px 16px;
  font-size: 12px;
}

.sidebar-block {
  position: relative;
  width: 211px;
  margin-bottom: 8px;
  padding: 7px 14px 0 15px;
  border: 1px solid #d2d2d2;
  background: @white;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    background: #444;
  }
}

.header-notification{
  font-size: 12px;
  background: #FFFDE6;
}

.notification__content{
  display: block;
  position: relative;
  padding: 10px 35px 10px 0;
}

.notification__close{
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  font-size: 21px;
  font-weight: 600;
  color: @gray;
  margin-top: -12px;
  line-height: 19px;
}

.header-std {
  margin: 0 0 18px 0;
}

.list-std {
  margin-bottom: 25px;
  margin-left: 13px;

  li {
    margin-bottom: 7px
  }

  ul {
    margin-top: 10px;
    margin-bottom: 10px;
    list-style-type: circle !important;
  }
}

.staff-policy-img {
  width: 30px;
  height: 30px;
  margin: -6px 5px 0 0;
}

.info-box {
  margin: 0 0 15px;
  padding: 10px;
  background: #F1F9FF;
  border: 1px solid #CBE7FB;
  border-radius: 3px;

  .glyphicon {
    color: @blue;
  }
}

.select2-icon-folder {
  position: relative;
  padding-left: 26px !important;

  &:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 5px;
    display: inline-block;
    width: 14px;
    height: 13px;
    background: url('../img/folders.png') 0 0 no-repeat;
    background-size: 24px 27px;
  }
}

.select2-icon {
  position: relative;
  padding-left: 26px !important;

  &:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 5px;
    display: inline-block;
    width: 10px;
    height: 12px;
    background: url('../img/folders.png') -17px 0 no-repeat;
    background-size: 27px 30px;
  }
}

.pagination {
  .clearfix();
  margin: -23px 0 0;
  padding: 0 15px;
  line-height: 58px;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 3px;
      .bold();
      font-size: 16px;

      a {
        display: inline-block;
        padding: 4px;
      }

      &.active {
        a {
          color: @grayLight;
          cursor: default;
        }
      }
    }
  }

  .page-info {
    float: right;
    display: block;
    .bold();
    font-size: 16px;
  }
}

.multi-selector-body {
  width: 365px;
  max-height: 86px;
  min-height: 29px;
  padding: 5px 4px 0;
  overflow-y: auto;
  background: @white;
  border: 1px solid @grayBorder;
  border-radius: 3px;

  .checkbox-rev {
    position: relative;
    padding-left: 0;
  }

  label {
    position: relative;
    display: block;
    margin-bottom: 2px;
    padding: 2px 3px 2px 23px;
    border-radius: 3px;
    z-index: 0;

    &:hover {
      background: darken(@grayBg, 3%);
    }
  }

  input[type="checkbox"]:checked {
    ~ .checkbox__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: @blue;
      border-radius: 3px;
      z-index: -1;
    }

    ~ .checkbox__label {
      color: @white;
    }
  }
}

.search-results-container__row {
  .multi-selector-body {
    width: 282px;
    padding: 2px 2px 0;
    max-height: 62px;
    min-height: 23px;

    .checkbox__label {
      font-size: 13px;
      color: @grayDark;
    }
  }
}