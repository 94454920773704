
[class^="icon-large-"],
[class*=" icon-large"]  {
  display: inline-block;
  vertical-align: text-top;
  background: url(../img/sprite_main.png) 0 0 no-repeat;
  cursor: pointer;
}

[class^="icon-hl-"]{
  width: 36px;
  background-image: url(../img/sprite_hotels_dgray.png);
}

[class^="icon-hm-"]{
  width: 24px;
  background-image: url(../img/sprite_hotels_dgray.png);
}

[class^="icon-hs-"]{
  width: 16px;
  background-image: url(../img/sprite_hotels_dgray.png);
}

.color-h-gray{
  background-image: url(../img/sprite_hotels_gray.png);
}

.color-h-light-gray{
  background-image: url(../img/sprite_hotels_lgray.png);
}

.color-h-green{
  background-image: url(../img/sprite_hotels_green.png);
}

.icon-user{
  position: relative;
  top: 2px;
}

.icon-custom-checkmark {
  width: 13px;
  height: 13px;
  background: url(../img/sprite_main.png) -110px -270px no-repeat;
}

.icon-custom-facebook {
  width: 42px;
  height: 36px;
  background: url(../img/sprite_main.png) -490px -180px no-repeat;
}

.icon-custom-airplane {
  width: 16px;
  height: 17px;
  background: url(../img/sprite_main.png) -544px -340px no-repeat;
}

.icon-custom-suitcase {
  width: 15px;
  height: 14px;
  background: url(../img/sprite_main.png) -560px -340px no-repeat;
}

.icon-custom-arrow-down {
  width: 21px;
  height: 21px;
  background: url(../img/sprite_main.png) -420px -270px no-repeat;
}

.icon-custom-filter {
  width: 14px;
  height: 14px;
  background: url(../img/sprite_search.png) -70px -11px no-repeat;
}

.icon-custom-price {
  width: 11px;
  height: 17px;
  margin-top: -2px;
  margin-left: 2px;
  background: url(../img/sprite_search.png) -110px -11px no-repeat;
}

.icon-custom-map-pin {
  width: 7px;
  height: 14px;
  margin: -2px 2px 0 0;
  background: url(../img/sprite_search.png) -80px -60px no-repeat;
}

.icon-custom-checkmark-circle {
  width: 13px;
  height: 14px;
  margin: -2px 2px 0 0;
  background: url(../img/sprite_search.png) -100px -60px no-repeat;

  &.active{
    background-position: -100px -80px;
  }
}

.icon-custom-favorite {
  width: 14px;
  height: 14px;
  margin: -2px 2px 0 0;
  background: url(../img/sprite_search.png) -120px -60px no-repeat;

  &.active{
    background-position: -120px -80px;
  }
}

.icon-custom-facebook-mini {
  width: 13px;
  height: 14px;
  margin: -2px 2px 0 0;
  background: url(../img/sprite_search.png) -140px -60px no-repeat;

  &.inactive{
    background-position: -140px -80px;
  }
  &.spinner{
    background: none !important;
  }
}

.icon-detail-map-pin {
  width: 12px;
  height: 19px;
  margin: -2px 0 0;
  background: url(../img/sprite_search.png) -220px -20px no-repeat;
}

.icon-detail-checkmark-circle {
  width: 14px;
  height: 19px;
  background: url(../img/sprite_search.png) -300px -20px no-repeat;

  &.active{
    background-position: -300px -50px;
  }
}

.icon-arrow-custom{
  width: 19px;
  height: 11px;
  background: url(../img/sprite_search.png) -20px -210px no-repeat;
}

.no-friends,
.not-ready{
  .icon-custom-facebook-mini {
    background-position: -140px -80px;
  }
}

.friends-found {
  color: #666;

  &:hover {
    color: darken(#666, 15%);
  }
}

.icon-checkmark-green {
  width: 13px;
  height: 11px;
  background: url(../img/sprite_main.png) -240px -230px no-repeat;
}

.icon-checkmark-green-small {
  width: 9px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -360px -60px no-repeat;
}

.icon-minus-small {
  width: 9px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -370px -60px no-repeat;
}

.icon-close-outlined {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../img/sprite_main.png) -520px -270px no-repeat;
}

.icon-close-border {
  display: inline-block;
  width: 20px;
  height: 21px;
  background: url(../img/sprite_main.png) -550px -269px no-repeat;
}

.icon-close-left {
  display: inline-block;
  width: 35px;
  height: 30px;
  background: url(../img/sprite_main.png) -490px -300px no-repeat;
}

.icon-close-round-left {
  display: inline-block;
  width: 26px;
  height: 25px;
  background: url(../img/sprite_main.png) -608px -268px no-repeat;
}

.icon-close-right {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: url(../img/sprite_main.png) -530px -300px no-repeat;
}

.icon-custom-plus {
  display: inline-block;
  width: 16px;
  height: 17px;
  background: url(../img/sprite_search.png) -100px -120px no-repeat;
}

.icon-contact-mail {
  display: inline-block;
  width: 16px;
  height: 12px;
  background: url(../img/sprite_main.png) -527px -340px no-repeat;
}

.icon-email-light{
  display: inline-block;
  width: 14px;
  height: 11px;
  background: url(../img/sprite_main.png) -588px -340px no-repeat;
}

.icon-darr-down {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../img/sprite_main.png) -520px -140px no-repeat;
}

.icon-darr-up {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../img/sprite_main.png) -530px -140px no-repeat;
}

.icon-button-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 2px 0 0;
  background: url(../img/sprite_search.png) -220px -80px no-repeat;
}

.icon-button-clear {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  background: url(../img/sprite_hotels.png) -190px -90px no-repeat;
}

.icon-date {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: url(../img/sprite_main.png) -640px -300px no-repeat;
}

.icon-small-exclamation {
  display: inline-block;
  width: 15px;
  height: 13px;
  background: url(../img/sprite_main.png) -420px -190px no-repeat;
}

.icon-advisor-owl{
  display: inline-block;
  position: relative;
  top: 1px;
  width: 21px;
  height: 13px;
  background: url(../img/sprite_hotels.png) 0px -40px no-repeat;
}

.icon-advisor-owl-small{
  display: inline-block;
  position: relative;
  top: -1px;
  width: 18px;
  height: 11px;
  background: url(../img/sprite_hotels.png) -40px -60px no-repeat;
}

.icon-hotel-pin{
  display: inline-block;
  width: 11px;
  height: 14px;
  background: url(../img/sprite_hotels.png) 0px -10px no-repeat;
}

.icon-view-loop{
  display: inline-block;
  width: 12px;
  height: 14px;
  background: url(../img/sprite_hotels.png) -90px -40px no-repeat;
}

.icon-legend-darr{
  display: inline-block;
  width: 11px;
  height: 10px;
  background: url(../img/sprite_hotels.png) -110px -40px no-repeat;
}

.icon-magnifying-glass {
    display: inline-block;
    height: 26px;
    width: 26px;
    background: url(../img/sprite_hotels.png) -220px -90px transparent;
}


.icon-magnifying-glass-small {
    display: inline-block;
    vertical-align: top;
    height: 20px;
    width: 20px;
    background: url(../img/sprite_hotels.png) -250px -90px transparent;
}

.map-marker {
  &:hover,
  &.active{
    z-index: 10000;
  }
}

.map-marker-match,
.map-marker-notmatch,
.map-marker-unavailable,
.map-marker-raw {
  position: absolute;
  top: -21px;
  left: -7px;
}

.map-marker-train,
.map-marker-airport,
.map-marker-landmark {
  position: absolute;
  top: -18px;
  left: -9px;
}

.legend-marker-match,
.map-marker-match{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -82px -8px no-repeat;
}

.map-marker-raw{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -310px -60px no-repeat;
}

.map-marker-active{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -200px -8px no-repeat;
}

.map-marker-booked{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -230px -8px no-repeat;
}

.legend-marker-notmatch,
.map-marker-notmatch{
  display: inline-block;
  top: -31px;
  left: -11px;
  width: 22px;
  height: 31px;
  background: url(../img/sprite_map_pins.png) -52px -8px no-repeat;
}

.legend-marker-street,
.map-marker-searched{
  display: inline-block;
  width: 28px;
  height: 40px;
  margin-bottom: -35px;
  background: url(../img/sprite_map_pins.png) -112px 0 no-repeat;
}

.map-marker-location {
  display: inline-block;
  width: 28px;
  height: 40px;
  margin-bottom: -35px;
  background: url(../img/sprite_map_pins.png) -112px 0 no-repeat;
}

.map-marker-street{
  display: inline-block;
  width: 28px;
  height: 40px;
  margin-bottom: -35px;
  background: url(../img/sprite_map_pins.png) -260px 0px no-repeat;
}

.legend-marker-unavailable,
.map-marker-unavailable{
  display: inline-block;
  width: 13px;
  height: 20px;
  background: url(../img/sprite_hotels.png) -240px 0px no-repeat;
}

.legend-list{
  .legend-marker-street{
    margin-left: -3px;
  }
}

.legend-marker-pinned{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -260px 0px no-repeat;
}

.legend-marker-train{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -280px 0px no-repeat;
}

.legend-marker-airport{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -300px 0px no-repeat;
}

.legend-marker-landmark{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -320px 0px no-repeat;
}

.map-marker-train{
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../img/sprite_hotels.png) -280px -30px no-repeat;
}

.map-marker-airport{
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(../img/sprite_hotels.png) -300px -30px no-repeat;
}

.map-marker-landmark{
  display: inline-block;
  top: -10px;
  left: -7px;
  width: 14px;
  height: 10px;
  background: url(../img/sprite_map_pins.png) -180px -10px no-repeat;
}

.map-marker-pin{
  position: absolute;
  top: -27px;
  left: -9px;
  display: inline-block;
  width: 17px;
  height: 27px;
  background: url(../img/sprite_hotels.png) -260px -30px no-repeat;
}

.map-marker-pin-large{
  position: absolute;
  top: -36px;
  left: -12px;
  display: inline-block;
  width: 23px;
  height: 36px;
  background: url(../img/sprite_hotels.png) -340px 0px no-repeat;
}

.legend-marker-custom{
  display: inline-block;
  width: 13px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -250px -60px no-repeat;
}

.map-marker-pin-custom{
  display: inline-block;
  width: 28px;
  height: 40px;
  margin-bottom: -35px;
  background: url(../img/sprite_map_pins.png) -147px 0px no-repeat;
}


.map-marker-preferred {
  position: absolute;
  top: -31px;
  left: -11px;
  display: inline-block;
  width: 22px;
  height: 32px;
}


.map-marker-in-policy,
.map-marker-client-preferred,
.map-marker-bcd-preferred,
.map-marker-out-of-policy,
.map-marker-suitcase,
.map-marker-other {
  position: absolute;
  top: -31px;
  left: -11px;
}

.map-marker-multiple,
.map-marker-cluster{
  display: inline-block;
  position: absolute;
  top: -22px;
  left: -22px;
  width: 44px;
  height: 44px;
  line-height: 42px;
  color: @white;
  font-size: 16px;
  text-align: center;
  .bold();
  background: rgba(70, 173, 38, 0.85);
  border-radius: 50em;
  border: 3px solid rgba(169, 216, 154, 0.84);
  background-clip: padding-box;
  transition: all 0.15s ease;

  &:hover {
    transform: scale(1.3);
  }
}

.map-marker-cluster--small {
  left: -13px;
  top: -13px;
  width: 26px;
  height: 26px;
  font-size: 11px;
  line-height: 25px;
  font-weight: normal;
}

.map-marker-cluster--medium {
  left: -18px;
  top: -18px;
  width: 36px;
  height: 36px;
  font-size: 13px;
  line-height: 35px;
  font-weight: normal;
}

.map-marker-other {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -250px -40px no-repeat;
}

.map-marker-in-policy {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -100px -40px no-repeat;
}

.map-marker-client-preferred {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -130px -40px no-repeat;
}

.map-marker-bcd-preferred {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -160px -40px no-repeat;
}

.map-marker-out-of-policy {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -190px -40px no-repeat;
}

.map-marker-suitcase {
  display: inline-block;
  width: 22px;
  height: 32px;
  background: url(../img/sprite_map_pins.png) -220px -40px no-repeat;
}

.pin-hotel-map{
  min-width: 20px;
}

.rating-common{
  display: inline-block;
  //width: 77px;
  height: 13px;
  margin: 2px 5px 0 0;
  //background: url(../img/sprite_hotels.png) -20px -20px no-repeat;

  span{
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    height: 13px;
    background: url(../img/sprite_hotels.png) -20px 0px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 6px;
    }
    &.rating1{
      width: 16px;
    }
    &.rating1h{
      width: 22px;
    }
    &.rating2{
      width: 32px;
    }
    &.rating2h{
      width: 38px;
    }
    &.rating3{
      width: 48px;
    }
    &.rating3h{
      width: 54px;
    }
    &.rating4{
      width: 64px;
    }
    &.rating4h{
      width: 70px;
    }
    &.rating5{
      width: 77px;
    }

    &.rating0h,
    &.rating1h,
    &.rating2h,
    &.rating3h,
    &.rating4h{
      margin-right: 6px;

      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 6px;
        height: 13px;
        background: url(../img/sprite_hotels.png) -26px -20px no-repeat;
      }
    }
  }
}

.rating-common-small{
  display: inline-block;
  width: 69px;
  height: 11px;
  margin: 2px 5px 0 0;
  background: url(../img/sprite_hotels.png) -70px -180px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 100%;
    height: 11px;
    background: url(../img/sprite_hotels.png) -70px -160px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 5px;
    }
    &.rating1{
      width: 14px;
    }
    &.rating1h{
      width: 20px;
    }
    &.rating2{
      width: 28px;
    }
    &.rating2h{
      width: 35px;
    }
    &.rating3{
      width: 42px;
    }
    &.rating3h{
      width: 49px;
    }
    &.rating4{
      width: 56px;
    }
    &.rating4h{
      width: 64px;
    }
    &.rating5{
      width: 69px;
    }
  }
}

.rating-advisor{
  display: inline-block;
  width: 70px;
  height: 13px;
  background: url(../img/sprite_hotels.png) -110px -20px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 0;
    height: 13px;
    background: url(../img/sprite_hotels.png) -110px 0px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 7px;
    }
    &.rating1{
      width: 14px;
    }
    &.rating1h{
      width: 21px;
    }
    &.rating2{
      width: 28px;
    }
    &.rating2h{
      width: 35px;
    }
    &.rating3{
      width: 42px;
    }
    &.rating3h{
      width: 49px;
    }
    &.rating4{
      width: 56px;
    }
    &.rating4h{
      width: 63px;
    }
    &.rating5{
      width: 70px;
    }
  }
}

.rating-advisor-small{
  display: inline-block;
  width: 59px;
  height: 11px;
  margin: 0 5px 0 0;
  background: url(../img/sprite_hotels.png) 0 -180px no-repeat;

  span{
    display: inline-block;
    float: left;
    width: 0;
    height: 11px;
    background: url(../img/sprite_hotels.png) 0 -160px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 6px;
    }
    &.rating1{
      width: 12px;
    }
    &.rating1h{
      width: 18px;
    }
    &.rating2{
      width: 24px;
    }
    &.rating2h{
      width: 30px;
    }
    &.rating3{
      width: 36px;
    }
    &.rating3h{
      width: 42px;
    }
    &.rating4{
      width: 48px;
    }
    &.rating4h{
      width: 54px;
    }
    &.rating5{
      width: 60px;
    }
  }
}

.icon-lock{
  display: inline-block;
  width: 14px;
  height: 19px;
  background: url(../img/sprite_main.png) -620px -370px no-repeat;
}

.icon-facebook {
  display: inline-block;
  width: 15px;
  height: 19px;
  background: url(../img/sprite_main.png) -30px -200px no-repeat;
}

.icon-twitter {
  width: 15px;
  height: 19px;
  background: url(../img/sprite_main.png) -50px -200px no-repeat;
}

.icon-trip-flight{
  width: 27px;
  height: 27px;
  margin: 7px 0 0;
  background: url(../img/sprite_main.png) -620px -130px no-repeat;
}

.icon-trip-hotel{
  width: 27px;
  height: 33px;
  margin: 7px 0 0;
  background: url(../img/sprite_main.png) -660px -130px no-repeat;
}

.icon-checkmark-dark-green {
  width: 14px;
  height: 11px;
  background: url(../img/sprite_hotels.png) -290px -60px no-repeat;
}

.icon-checkmark-dark-green-large {
  width: 26px;
  height: 21px;
  background: url(../img/sprite_hotels.png) -280px -110px no-repeat;
}

.icon-go-rewards {
  vertical-align: top;
  width: 83px;
  height: 10px;
  background: url(../img/sprite_hotels.png) -280px -90px no-repeat;
}

.icon-modal-close-dark{
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url(../img/sprite_main.png) -710px -130px no-repeat;
}

.icon-arrow-long {
  width: 296px;
  height: 9px;
  background: url(../img/sprite_business.png) 0 0 no-repeat;
}

.icon-chevron-right {
  width: 15px;
  height: 22px;
  background: url(../img/sprite_main.png) -650px -370px no-repeat;
 }

 .icon-chevron-left {
  width: 15px;
  height: 22px;
  background: url(../img/sprite_main.png) -670px -370px no-repeat;
 }


.icon-prod-flights{
  width: 16px;
  height: 20px;
  background: url(../img/icons_prod_sections.png) no-repeat 0 -40px;
}

.icon-prod-ptgo{
  width: 16px;
  height: 20px;
  background: url(../img/icons_prod_sections.png) no-repeat -20px -40px;
}

.icon-prod-hotels{
  width: 20px;
  height: 20px;
  background: url(../img/icons_prod_sections.png) no-repeat -40px -40px;
}

.current{
  .icon-prod-flights{
    background-position: 0 -20px;
  }

  .icon-prod-ptgo{
    background-position: -20px -20px;
  }

  .icon-prod-hotels{
    background-position: -40px -20px;
  }
}

.icon-white-checkmark {
  width: 13px;
  height: 13px;
  background: url(../img/sprite_search_form.png) -40px 0 no-repeat;
}

.icon-checkmark-circle {
  width: 23px;
  height: 23px;
  background: url(../img/sprite_hotels.png) -260px -160px no-repeat;
}

.icon-minus-circle {
  width: 23px;
  height: 23px;
  background: url(../img/sprite_hotels.png) -260px -185px no-repeat;
}

.icon-pending {
  width: 23px;
  height: 23px;
  background: url(../img/sprite_hotels.png) -190px -58px no-repeat;
}

.icon-darr-bottom-small {
  width: 5px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -180px -40px no-repeat;
}

.icon-darr-up-small {
  width: 5px;
  height: 7px;
  background: url(../img/sprite_hotels.png) -180px -50px no-repeat;
}

.icon-free-upgrade-option {
  width: 27px;
  height: 15px;
  background: url(../img/sprite_hotels.png) -250px -120px no-repeat;
}

.icon-sm-close {
  width: 9px;
  height: 9px;
  background: url(../img/sprite_main.png) -740px -240px no-repeat;
}

.icon-hl-wifi-internet{
  height: 26px;
  background-position: -50px -210px;
}

.icon-hm-wifi-internet{
  height: 17px;
  background-position: -20px -210px;
}

.icon-hs-wifi-internet{
  height: 14px;
  width: 18px;
  background-position: 0 -210px;
}

.icon-hs-wifi-internet-available{
  height: 14px;
  width: 18px;
  background-position: -269px -291px;
}

.icon-hl-free-parking{
  height: 36px;
  background-position: -50px -40px;
}

.icon-hm-free-parking{
  height: 24px;
  background-position: -20px -40px;
}

.icon-hs-free-parking{
  height: 15px;
  width: 15px;
  background-position: 0 -40px;
}

.icon-hl-flatscreen{
  height: 26px;
  background-position: -50px -280px;
}

.icon-hm-flatscreen{
  height: 17px;
  background-position: -20px -280px;
}

.icon-hs-flatscreen{
  height: 16px;
  background-position: 0 -278px;
}

.icon-hl-getgoing-rate{
  height: 35px;
  background-position: -140px -60px;
}

.icon-hm-getgoing-rate{
  height: 22px;
  background-position: -110px -60px;
}

.icon-hs-getgoing-rate{
  height: 16px;
  background-position: -90px -60px;
}

.icon-hl-fees{
  width: 28px;
  height: 36px;
  background-position: -230px -110px;
}

.icon-hm-fees{
  width: 18px;
  height: 24px;
  background-position: -200px -110px;
}

.icon-hs-fees{
  width: 12px;
  height: 16px;
  background-position: -180px -110px;
}

.icon-hl-media-and-tech{
  height: 34px;
  background-position: -140px -220px;
}

.icon-hm-media-and-tech{
  height: 22px;
  background-position: -110px -220px;
}

.icon-hs-media-and-tech{
  height: 16px;
  background-position: -90px -219px;
}

.icon-hl-check-in{
  height: 31px;
  background-position: -230px -270px;
}

.icon-hm-check-in{
  height: 21px;
  background-position: -200px -270px;
}

.icon-hs-check-in{
  height: 16px;
  background-position: -180px -269px;
}

.icon-hl-pets{
  height: 31px;
  background-position: -140px -100px;
}

.icon-hm-pets{
  height: 21px;
  background-position: -110px -100px;
}

.icon-hs-pets{
  height: 16px;
  background-position: -90px -99px;
}

.icon-hl-spa-jet-tub{
  height: 31px;
  background-position: -50px -240px;
}

.icon-hm-spa-jet-tub{
  height: 21px;
  background-position: -20px -240px;
}

.icon-hs-spa-jet-tub{
  height: 16px;
  background-position: 0 -239px;
}

.icon-hl-free-breakfast{
  width: 34px;
  height: 36px;
  background-position: -50px -140px;
}

.icon-hm-free-breakfast{
  width: 22px;
  height: 24px;
  margin-left: 2px;
  background-position: -20px -140px;
}

.icon-hs-free-breakfast{
  height: 15px;
  background-position: 2px -139px;
}

.icon-hl-swimming-pool{
  height: 26px;
  background-position: -50px -80px;
}

.icon-hm-swimming-pool{
  height: 17px;
  margin-left: -1px;
  background-position: -20px -80px;
}

.icon-hs-swimming-pool{
  height: 16px;
  background-position: 0 -78px;
}

.icon-hl-balcony-seaview{
  height: 24px;
  background-position: -50px -180px;
}

.icon-hm-balcony-seaview{
  height: 16px;
  background-position: -20px -180px;
}

.icon-hs-balcony-seaview{
  height: 16px;
  background-position: 0 -178px;
}

.icon-hl-transportation{
  height: 22px;
  background-position: -320px -120px;
}

.icon-hm-transportation{
  height: 15px;
  background-position: -290px -120px;
}

.icon-hs-transportation{
  height: 16px;
  background-position: -270px -117px;
}

.icon-hl-food-and-drink{
  width: 31px;
  height: 36px;
  background-position: -320px 0;
}

.icon-hm-food-and-drink{
  width: 21px;
  height: 24px;
  background-position: -290px 0;
}

.icon-hs-food-and-drink{
  height: 16px;
  background-position: -269px 0;
}

.icon-hl-services{
  width: 34px;
  height: 36px;
  background-position: -140px -20px;
}

.icon-hm-services{
  width: 22px;
  height: 24px;
  background-position: -110px -20px;
}

.icon-hs-services{
  height: 16px;
  background-position: -89px -20px;
}

.icon-hl-check-out{
  height: 32px;
  background-position: -230px -190px;
}

.icon-hm-check-out{
  height: 21px;
  background-position: -200px -190px;
}

.icon-hs-check-out{
  height: 16px;
  background-position: -180px -189px;
}

.icon-hl-accepted-cc{
  width: 24px;
  height: 36px;
  background-position: -320px -80px;
}

.icon-hm-accepted-cc{
  width: 16px;
  height: 24px;
  background-position: -290px -80px;
}

.icon-hs-accepted-cc{
  width: 10px;
  height: 16px;
  background-position: -270px -80px;
}

.icon-hl-fireplace{
  height: 33px;
  background-position: -230px 0;
}

.icon-hm-fireplace{
  height: 22px;
  background-position: -200px 0;
}

.icon-hs-fireplace{
  height: 16px;
  background-position: -180px 1px;
}

.icon-hl-rooms{
  height: 20px;
  margin-top: 5px;
  background-position: -140px 0;
}

.icon-hm-rooms{
  height: 13px;
  background-position: -110px 0;
}

.icon-hs-rooms{
  height: 16px;
  background-position: -90px 3px;
}

.icon-hl-activities{
  width: 34px;
  height: 36px;
  background-position: -320px -40px;
}

.icon-hm-activities{
  width: 22px;
  height: 24px;
  background-position: -290px -40px;
}

.icon-hs-activities{
  height: 16px;
  background-position: -269px -40px;
}

.icon-hl-know-before-you-go{
  width: 34px;
  height: 36px;
  background-position: -320px -190px;
}

.icon-hm-know-before-you-go{
  width: 23px;
  height: 24px;
  background-position: -290px -190px;
}

.icon-hs-know-before-you-go{
  width: 15px;
  height: 17px;
  background-position: -270px -190px;
}

.icon-hl-cancellation{
  height: 36px;
  background-position: -230px -230px;
}

.icon-hm-cancellation{
  height: 24px;
  background-position: -200px -230px;
}

.icon-hs-cancellation{
  height: 16px;
  background-position: -180px -230px;
}

.icon-hl-entertainment{
  height: 29px;
  background-position: -50px -110px;
}

.icon-hm-entertainment{
  height: 20px;
  background-position: -20px -110px;
}

.icon-hs-entertainment{
  height: 16px;
  background-position: 0 -109px;
}

.icon-hl-kitchen{
  width: 32px;
  height: 36px;
  background-position: -140px -180px;
}

.icon-hm-kitchen{
  width: 22px;
  height: 24px;
  background-position: -110px -180px;
}

.icon-hs-kitchen{
  height: 15px;
  width: 14px;
  background-position: -90px -180px;
}

.icon-hl-general{
  height: 27px;
  background-position: -230px -80px;
}

.icon-hm-general{
  height: 18px;
  background-position: -200px -80px;
}

.icon-hs-general{
  height: 16px;
  background-position: -180px -78px;
}

.icon-hl-outdoors{
  height: 33px;
  background-position: -140px -140px;
}

.icon-hm-outdoors{
  height: 22px;
  background-position: -110px -140px;
}

.icon-hs-outdoors{
  height: 16px;
  background-position: -90px -139px;
}

.icon-hl-terms-and-conditions{
  width: 30px;
  height: 36px;
  background-position: -320px -150px;
}

.icon-hm-terms-and-conditions{
  width: 18px;
  height: 24px;
  background-position: -290px -150px;
}

.icon-hs-terms-and-conditions{
  width: 12px;
  height: 16px;
  background-position: -270px -150px;
}

.icon-hl-children-and-extra-bed{
  height: 31px;
  background-position: -230px -150px;
}

.icon-hm-children-and-extra-bed{
  height: 21px;
  background-position: -200px -150px;
}

.icon-hs-children-and-extra-bed{
  height: 16px;
  background-position: -180px -149px;
}

.icon-hl-important{
  height: 36px;
  background-position: -140px -260px;
}

.icon-hm-important{
  height: 24px;
  background-position: -110px -260px;
}

.icon-hs-important{
  height: 16px;
  background-position: -90px -260px;
}

.icon-hl-hotels{
  width: 18px;
  height: 36px;
  background-position: -220px -40px;
}

.icon-hm-hotels{
  width: 12px;
  height: 24px;
  background-position: -200px -40px;
}

.icon-hs-hotels{
  width: 8px;
  height: 16px;
  background-position: -180px -40px;
}

.icon-hl-air-conditioning{
  width: 32px;
  height: 36px;
  background-position: -50px 0;
}

.icon-hm-air-conditioning{
  width: 22px;
  height: 24px;
  background-position: -20px 0;
}

.icon-hs-air-conditioning{
  height: 15px;
  background-position: 1px 0;
}

.icon-hl-gym{
  width: 36px;
  height: 36px;
  background-position: -320px -230px;
}

.icon-hm-gym{
  width: 24px;
  height: 24px;
  background-position: -290px -230px;
}

.icon-hs-gym{
  width: 16px;
  height: 16px;
  background-position: -270px -230px;
}

.icon-small-wifi-internet{
  width: 12px;
  height: 9px;
  background-position: -270px -270px;
}

.icon-small-free-parking{
  width: 11px;
  height: 11px;
  background-position: -290px -270px;
}

.icon-small-free-breakfast{
  width: 11px;
  height: 12px;
  background-position: -310px -270px;
}

.icon-small-gym{
  width: 11px;
  height: 11px;
  background-position: -330px -270px;
}

.icon-book{
  background-position: -48px -49px;
  opacity: .7;
}

.icon-photos{
  background-position: -120px -49px;
  opacity: .7;
  transform: scale(1.15, 1.1);
}

.icon-scroll-hint-arr{
  width: 14px;
  height: 18px;
  background: url(../img/sprite_main.png) no-repeat -710px -270px;
}

.icon-scroll-hint-arr-dark{
  width: 14px;
  height: 18px;
  background: url(../img/sprite_main.png) no-repeat -710px -310px;
}

.icon-cc-vi{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat 0 -20px;

  &.active{
    background-position: 0 0;
  }
}

.icon-cc-ca{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -34px -20px;

  &.active{
    background-position: -34px 0;
  }
}

.icon-cc-ds{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -68px -20px;

  &.active{
    background-position: -68px 0;
  }
}

.icon-cc-ax{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_small.png) no-repeat -102px -20px;

  &.active{
    background-position: -102px 0;
  }
}

.icon-cc-e{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat 0 -20px;

  &.active{
    background-position: 0 0;
  }
}

.icon-cc-to{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -34px -20px;

  &.active{
    background-position: -34px 0;
  }
}

.icon-cc-jc{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -68px -20px;

  &.active{
    background-position: -68px 0;
  }
}

.icon-cc-dc{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -102px -20px;

  &.active{
    background-position: -102px 0;
  }
}

.icon-cc-cb{
  width: 32px;
  height: 20px;
  background: url(../img/cc_cards_additional_small.png) no-repeat -136px -20px;

  &.active{
    background-position: -136px 0;
  }
}


.icon-rewards-go{
  width: 21px;
  height: 9px;
  background: url(../img/sprite_main.png) no-repeat -740px -270px;
}

.icon-hs-other{
  width: 15px;
  height: 16px;
  background: url(../img/sprite_main.png) no-repeat -300px -269px;
}

[class^="icon-flag-"]{
  width: 16px;
  height: 11px;
  margin-top: 4px;
  background-image: url(../img/sprite_flags.png);
}

.icon-flag-en{
  background-position: 0 0;
}

.icon-flag-en-gb{
  background-position: 0 -15px;
}

.icon-flag-fr-ca{
  background-position: 0 -45px;
}

.icon-flag-fr{
  background-position: 0 -30px;
}

.icon-flag-es{
  background-position: 0 -75px;
}

.icon-flag-de{
  background-position: 0 -60px;
}

.icon-flag-pt{
  background-position: 0 -90px;
}

.icon-flag-it{
  background-position: 0 -105px;
}

.icon-flag-ja{
  background-position: 0 -120px;
}

.icon-flag-ko{
  background-position: 0 -135px;
}

.icon-thumbs-up {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -310px -120px no-repeat;
}

.icon-check-circle {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -330px -120px no-repeat;
}

.icon-excl-circle {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -350px -120px no-repeat;
}

.icon-excl-circle-orange {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -370px -120px no-repeat;
}

.icon-question-circle-blue {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -310px -140px no-repeat;
}

.icon-in-policy {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -310px -160px no-repeat;
}

.icon-bcd-preferred {
  width: 16px;
  height: 16px;
  margin: -2px 1px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -330px -160px no-repeat;
}

.icon-client-preferred {
  width: 9px;
  height: 11px;
  background: url(../img/sprite_hotels.png) -350px -160px no-repeat;
}

.icon-client-preferred-grey {
  width: 9px;
  height: 11px;
  background: url(../img/sprite_hotels.png) -350px -180px no-repeat;
}

.icon-out-of-policy {
  width: 16px;
  height: 16px;
  margin: -2px 3px 0 0;
  vertical-align: middle;
  background: url(../img/sprite_hotels.png) -370px -160px no-repeat;
}

.icon-policy-available {
  width: 16px;
  height: 16px;
  background: url(../img/sprite_hotels.png) -310px -180px no-repeat;
}

.icon-policy-non-available {
  width: 16px;
  height: 16px;
  background: url(../img/sprite_hotels.png) -330px -180px no-repeat;
}

.icon-price-average {
  width: 16px;
  height: 9px;
  background: url(../img/sprite_hotels.png) -360px -180px no-repeat;
}

.icon-ribbon-checkmark {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -330px -140px no-repeat;
}

.icon-ribbon-client {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -350px -140px no-repeat;
}

.icon-ribbon-exclamation {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -370px -140px no-repeat;
}

.icon-ribbon-briefcase {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_hotels.png) -390px -140px no-repeat;
}

.icon-close-ext {
  width: 14px;
  height: 14px;
  background: url(../img/sprite_main.png) -560px -240px no-repeat;
}

.icon-custom-plus-sign {
  width: 11px;
  height: 11px;
  background: url(../img/sprite_main.png) -760px -240px no-repeat;
}

.icon-info {
  width: 12px;
  height: 12px;
  background: url(../img/sprite_main.png) -450px -190px no-repeat;
}

.room-info-icon{
  display: inline-block;
  position: relative;
  top: -1px;
  padding: 2px 3px;
  color: @white;
  font-size: 11px;
  line-height: 1;
  vertical-align: middle;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  background: @gray;
  border-radius: 2px;
}

.icon-mondelez {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: -1px;
  margin-right: 6px;
  vertical-align: text-top;
  background: url(../img/sprite_hotels.png) -190px -160px no-repeat;
}

.icon-m-special-rates {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-top: 2px;
  margin-right: 3px;
  vertical-align: text-top;
  background: url(../img/sprite_hotels.png) -110px -90px no-repeat;
}

.icon-m-policy {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-top: 2px;
  margin-right: 3px;
  vertical-align: text-top;
  background: url(../img/sprite_hotels.png) -110px -110px no-repeat;
}

.icon-m-amenities {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 3px;
  vertical-align: text-top;
  background: url(../img/sprite_hotels.png) -110px -130px no-repeat;
}

.icon-close {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: text-top;
  background: url(../img/sprite_hotels.png) -110px -70px no-repeat;
}

.icon-error-404{
  width: 29px;
  height: 29px;
  background: url(../img/sprite_main.png) -0px -350px no-repeat;
}

.icon-error-500{
  width: 30px;
  height: 29px;
  background: url(../img/sprite_main.png) -40px -350px no-repeat;
}

.icon-error-maintenance{
  width: 29px;
  height: 29px;
  background: url(../img/sprite_main.png) -80px -350px no-repeat;
}

.icon-download {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -600px -238px no-repeat;
}

.icon-print {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -620px -240px no-repeat;
}

.icon-calendar {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -640px -240px no-repeat;
}

.icon-spinner{
  width: 31px;
  height: 31px;
  vertical-align: middle;
  border: 3px solid #000;
  background: #000 url(../img/spinner_black.gif) 0 0 no-repeat;
  background-size: cover;
  border-radius: 6px;
}

.icon-spinner--large {
  width: 43px;
  height: 43px;
}

.icon-spinner--small {
  width: 24px;
  height: 24px;
}

.icon-spinner--white {
  border: 3px solid #fff;
  background: #fff url(../img/spinner_white.gif) 0 0 no-repeat;
  background-size: cover;
}

.map-pin {
  position: absolute;
  top: -32px;
  left: -13px;
  width: 26px;
  height: 32px;
  background: url(../img/sprite_map_pins_rev.png) 0 0 no-repeat;
  z-index: 20;
  cursor: pointer;

  .marker-tooltip {
    bottom: 37px;
  }
}

.map-pin--corporate-preferred {
  background-position: -30px 0;
}

.map-pin--corporate-agency {
  background-position: -60px 0;
}

.map-pin--agency-preferred {
  background-position: -90px 0;
}

.map-pin--standard {
  background-position: -120px 0;
}

.map-pin--out-of-policy {
  background-position: -150px 0;
}

.map-pin--home {
  width: 24px;
  height: 24px;
  background-position: -180px 0;
}

.map-pin--home-rev {
  width: 24px;
  height: 24px;
  background-position: -210px 0;
}

.map-pin--home,
.map-pin--home-rev {
  position: absolute;
  top: -24px;
  left: -12px;

  .marker-tooltip {
    bottom: 30px;
  }
}

.icon-guest-info {
  width: 13px;
  height: 13px;
  margin: 0 -1px 3px 0;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -470px -190px no-repeat;
}

.icon-payment {
  width: 13px;
  height: 13px;
  margin: 0 -1px 3px 0;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -490px -190px no-repeat;
}

.icon-trip-details {
  width: 13px;
  height: 13px;
  margin: 0 -1px 3px 0;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -510px -190px no-repeat;
}

.icon-review {
  width: 13px;
  height: 13px;
  margin: 0 -1px 3px 0;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -530px -190px no-repeat;
}

.tier {
  float: left;
  position: relative;
  display: inline-block;
  float: left;
  width: 35px;
  height: 17px;
  margin: 0 5px 0 0;
}

.tier__icon {
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  z-index: 4;

  img {
    vertical-align: top;
  }
}

.tier__level {
  float: left;
  display: block;
  position: absolute;
  top: 0;
  height: 17px;
  margin-left: 7px;
  padding: 0 8px 0 15px;
  line-height: 17px;
  color: @white;
  font-size: 11px;
  background: #008315;
  z-index: 2;
  border-radius: 0 10em 10em 0;
}

.tier--tmc-preferred {
  .tier__icon img {
    top: 0;
    width: auto;
    height: auto;
  }

  .tier__level {
    background: #0e44a2;
  }
}

.item-list-bg__tag {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/partners/preferred-partner.png) 0 0 no-repeat;
}

.ico-iata {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 30px;
  height: 8px;
  background: url(../img/icon-iata.png) 0 0 no-repeat;
  background-size: cover;
}

.ico-vpa_guarantee {
  display: inline-block;
  position: relative;
  top: 4px;
  height: 12px;
  width: 28px;
  cursor: default;

  &:after {
    content: "VPA Guarantee";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 3px;
    line-height: 14px;
    font-weight: bold;
    font-size: 9px;
    color: @white;
    border-radius: 3px;
    background: #500d21;
  }
 }

.ico-vpa_payment {
  display: inline-block;
  position: relative;
  top: 4px;
  height: 12px;
  width: 28px;
  cursor: default;

  &:after {
    content: "VPA Payment";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 3px;
    line-height: 14px;
    font-weight: bold;
    font-size: 9px;
    color: @white;
    border-radius: 3px;
    background: #500d21;
  }
 }

.ico-bb {
  display: inline-block;
  width: 110px;
  height: 15px;
  background: url(../img/bb-logo.png) 0 0 no-repeat;
  background-size: cover;
}

[class^="icon-am"], [class*=" icon-am"] {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-right: 1px;
  background-image: url(../img/sprite-amenities.png);
  background-size: 200px;
  background-repeat: no-repeat;
}

.icon-am-internet {
  background-position: 0 0;
}

.icon-am-free-parking {
  background-position: -28px 0;
}

.icon-am-wifi-internet {
  background-position: -56px 0;
}

.icon-am-free-breakfast {
  background-position: -85px 0;
}
