// Localization related LESS
// ----------------------------------------------------------------------------------------


.lang-en {
  .footer-rev__left-col {
    width: auto;
  }
}

.lang-de{
  .item-list-bg {
    .sort-header .preference a {
      left: -10px;
    }
  }

  .hotel-table-wrap {
    .booking-control{
      width: 115px;
    }
  }

  .main-home-form .label {
    font-size: 11px;
  }

  .select2--group .select2-choice {
    width: 210px;
  }

  .select2-searchbox--group .select2-input {
    width: 192px;
  }

  .section-control__tag {
    max-width: 220px;
  }

  .de--cvv {
    .control-label {
      white-space: nowrap;
    }

    .input-size-small {
      .checkout-alt .inner-page-block .input-size-normal;
    }
  }

  .grid-details article.top-level {
    .list-header {
      width: 190px;
    }

    .desc {
      margin-left: 190px;

      &:after {
        left: -190px;
        width: 190px;
      }
    }
  }
}

.lang-fr{
  .item-list-bg .info {
    width: 310px;
  }

  .item-list-bg .rating {
    width: 66px;
  }

  .sidebar-item .filter-items label {
    width: 210px;
  }

  .section-control__tag {
    margin-right: 12px;
    max-width: 222px;
  }

  .summary-section-total {
    .sum-section-item {
      strong {
        font-size: 11px;
      }
    }
  }

  .room-details-box .btn-small-rev {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.lang-es {
  .search-results-container {
    .input-item-destination-hotels {
      input {
        width: 462px;
      }
    }
  }

  .input-item-passengers-hotels {
    .selector-value {
      width: 178px;
    }
  }

  .item-list-bg {
    .rating {
      width: 60px;
    }

    .info {
      width: 311px;
    }
  }

  .btn-tabs {
    a {
      padding: 0 5px;
    }
  }

  .item-list-bg {
    .amenities-list-simple {
      td {
        padding: 2px 1px;
      }
    }
  }
}


[class^="lang-fr"],
[class*=" lang-fr"] {
  .search-results-container {
    .input-item-destination-hotels {
      input {
        width: 461px;
      }
    }
  }

  .input-item-passengers-hotels {
    .selector-value {
      width: 178px;
    }
  }

  .checkout-alt {
    .control-label {
      width: 170px;
    }

    .controls {
      margin-left: 180px;
    }
  }

  .inner-head-wrapper {
    .confirmation-info-box {
      h2 {
        font-size: 26px;
      }
    }
  }

  .ag-form {
    .input-ex {
      width: 218px;
    }
  }

  .cc-table__form {
    .input-size-small {
      width: 106px!important;

      &:first-child {
        width: 75px !important;
      }
    }
  }

  .checkout-alt {
    .cc-table__form {
      .control-label {
        width: 153px;
      }

      .controls {
        margin-left: 163px;
      }

      .validation-indicator {
        left: 0;
      }
    }
  }

  .cc-list__col--cc {
    width: 52.5%;
  }

  .cc-list__col--name {
    width: 29%;
  }
}

.lang-pt {
  .search-results-container {
    .input-item-destination-hotels {
      input {
        width: 485px;
      }
    }
  }

  .item-list-bg .info {
    width: 297px;
  }

  .item-list-bg .distance {
    width: 75px;
  }

  .item-list-bg .rating {
    width: 77px;
  }


  .cc-list__col--cc {
    width: 52.5%;
  }

  .cc-list__col--name {
    width: 29%;
  }
}

.lang-it {
  .map-mode-switcher {
    width: 120px;

    a.map {
      width: 36px;
    }
  }

  .checkout-alt .control-label {
    width: 160px;
  }

  .item-list-bg {
    .rating {
      width: 90px;
    }

    .distance {
      width: 63px;
    }

    .info {
      width: 297px;
    }
  }
}
